import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  CheckBox,
  FieldGroup,
  FieldRow,
  FieldSet,
  Input,
  SelectBox,
} from "react-corner-ds";
import { Controller, FieldError, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { definePatternByName } from "../../utils/constants";
import { SeminarFormInfo } from "../../models/request/data/data.model";
import seminarLogo from "../../assets/img/seminar/icon-vhand.svg";
import { CountryItem, DataAttribute } from "../../models/utils.model";
import { cantonItems } from "../../assets/locales/utils";
import { countriesDe } from "../../assets/countries/de";
import { countriesEn } from "../../assets/countries/en";
import { countriesFr } from "../../assets/countries/fr";
import { countriesIt } from "../../assets/countries/it";
import { countriesCom } from "../../assets/countries/com";
import { Request } from "../../models/request/request.model";
import { useFlow } from "../../hooks/useFlow/useFlow";
import style from "./CtWidgetSeminar.module.css";
import CtWidgetSeminarSuccess from "../successComponents/ctWidgetSeminar/CtWidgetSeminarSuccess";
import GooglCaptchaDisclaimer from "../googleCaptchaDisclaimer/googleCaptchaDisclaimer";
import { EmailValidator } from "commons-validator-js";

interface ICtWidgetSeminar {
  dataAttribute: DataAttribute;
}

const CtWidgetSeminar: React.FC<ICtWidgetSeminar> = ({ dataAttribute }) => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [isChCountry, setIsChCountry] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [stepForm, setStepForm] = useState<string>("seminarForm");
  const [isError, setIsError] = useState<boolean>(false);
  const [isChSite, setIsChSite] = useState<boolean>(false);

  const { callSubscribe } = useFlow();

  const selectedLang = useMemo(
    () => i18n.resolvedLanguage,
    [i18n.resolvedLanguage]
  );

  // set trk Adobe Analytics
  const buttonSubmit = document.getElementsByClassName("trkEvents");
  buttonSubmit[0]?.setAttribute("data-trk-btn-click", "submit-events");

  const countries = useMemo(() => {
    switch (selectedLang) {
      case "de":
        return countriesDe;

      case "fr":
        return countriesFr;

      case "it":
        return countriesIt;

      default:
        return isChSite ? countriesEn : countriesCom;
    }
  }, [isChSite, selectedLang]);

  const validity = useCallback(
    (error: FieldError | undefined, isTouched: boolean) =>
      !error && isTouched ? true : undefined,
    []
  );

  const countryItems = useMemo(() => {
    return countries.map((item: CountryItem) => ({
      label: [item.name],
      value: item.alpha_2,
      icon: item.alpha_2,
    }));
  }, [countries]);

  const prefixItems = useMemo(() => {
    return countries.map((item: CountryItem) => ({
      label: [item.name, "+" + item.phone_code],
      value: "+" + item.phone_code,
      icon: item.alpha_2,
    }));
  }, [countries]);

  const { handleSubmit, formState, control, setValue } =
    useForm<SeminarFormInfo>({
      mode: "all",
      reValidateMode: "onChange",
      defaultValues: {
        FirstName: "",
        LastName: "",
        Email: "",
        Phones: [
          {
            CountryCode: "",
            LocalNumber: "",
            Type: "Mobile",
          },
        ],
        Addresses: [
          {
            Type: "Home",
            Country: "",
            Canton: "",
          },
        ],
      },
    });

  useEffect(() => {
    const hostName = window.location.host;
    if (hostName.includes("cornertrader.ch")) {
      setIsChSite(true);
      setValue("Phones.0.CountryCode", "+41");
    } else {
      setIsChSite(false);
      setValue("Phones.0.CountryCode", "+1242");
    }
  }, [setValue]);

  const buildEventDate = useCallback((eventDate: string) => {
    if (
      eventDate.length > 4 &&
      eventDate.substring(eventDate.length - 3, eventDate.length) === "000"
    )
      eventDate = eventDate.substring(0, eventDate.length - 3);

    return eventDate;
  }, []);

  const submitHandler = async (data: SeminarFormInfo) => {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth =
      currentDate.getMonth() + 1 < 10
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1;
    let currentDay =
      currentDate.getDate() < 10
        ? "0" + currentDate.getDate()
        : currentDate.getDate();

    let body: Request = {
      Data: {
        CID: dataAttribute.dataWidgetCid,
        Promotion: dataAttribute.dataWidgetPromo,
        Event: {
          Start: buildEventDate(dataAttribute.dataWidgetSeminarStartDate || ""),
          End: buildEventDate(dataAttribute.dataWidgetSeminarEndDate || ""),
          Code: dataAttribute.dataWidgetSeminarCode || "",
          Title: dataAttribute.dataWidgetSeminarTitle || "",
          Location: dataAttribute.dataWidgetSeminarLocation || "",
          Webinar: dataAttribute.dataWidgetSeminarWebinarLink || "",
        },
        Trader: {
          Account: {
            TCAccepted: data.TCAccepted,
            TCDate: `${currentYear}${currentMonth}${currentDay}`,
            TCCode: "1",
          },
          Customer: {
            Language: i18n.resolvedLanguage || "en",
            Email: data.Email,
            FirstName: data.FirstName,
            LastName: data.LastName,
            Phones: [
              {
                CountryCode: data.Phones[0].CountryCode,
                LocalNumber: data.Phones[0].LocalNumber,
                Type: "Mobile",
              },
            ],
            Addresses: [
              {
                Canton: data.Addresses[0].Canton,
                Country: data.Addresses[0].Country,
                Type: "Home",
              },
            ],
          },
        },
      },
      Extra: {
        caseType: "Seminar",
      },
    };

    setLoading(true);
    let resp = await callSubscribe(body);

    if (resp?.data.status.success === true) {
      setStepForm(resp.data.status.step || "seminarForm");
    } else {
      setIsError(true);
    }
    setLoading(false);
  };

  const firstNameInput = useMemo(
    () => (
      <Controller
        control={control}
        name="FirstName"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("FirstName"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            name={name}
            label={t`screens.seminar.firstName` || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={60}
            value={value}
          />
        )}
      />
    ),
    [control, t, validity]
  );

  const lastNameInput = useMemo(
    () => (
      <Controller
        control={control}
        name="LastName"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("LastName"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            name={name}
            label={t`screens.seminar.lastName` || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={60}
            value={value}
          />
        )}
      />
    ),
    [control, t, validity]
  );

  const validator = useMemo(() => new EmailValidator(), []);

  const emailInput = useMemo(
    () => (
      <Controller
        control={control}
        name="Email"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          validate: (value) => validator.isValid(value),
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            name={name}
            label={t`screens.seminar.email` || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            type="email"
            onBlur={onBlur}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={
              error?.message ||
              (!validator.isValid(value) && t`screens.seminar.emailRequired`) ||
              ""
            }
            maxLength={60}
            value={value}
          />
        )}
      />
    ),
    [control, t, validator, validity]
  );

  const prefixSelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Phones.0.CountryCode"
        defaultValue={"+41"}
        render={({ field: { onChange, name, value } }) => (
          <SelectBox
            cssClass="prefix"
            value={value}
            previewIndex="1"
            items={prefixItems}
            name={name}
            onChange={(item) => {
              onChange(item.value);
            }}
          />
        )}
      />
    ),
    [control, prefixItems]
  );

  const phoneInput = useMemo(
    () => (
      <Controller
        control={control}
        name="Phones.0.LocalNumber"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`screens.seminar.phoneRequired`,
          },
          pattern: {
            value: definePatternByName("Phone"),
            message: t`errors.input.invalidPattern`,
          },
          minLength: {
            value: 7,
            message: t(`errors.input.minPhoneLength`, {
              min: "7",
            }),
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            type="tel"
            name={name}
            customCssClass={style.customPhoneInput}
            label={t`screens.seminar.phoneNr` || ""}
            placeholder="00 000 00 00"
            cssClass="prefixed"
            onBlur={onBlur}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={11}
            value={value}
          >
            {prefixSelectBox}
          </Input>
        )}
      />
    ),
    [control, prefixSelectBox, t, validity]
  );

  const countrySelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Addresses.0.Country"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({
          field: { onChange, name, value, onBlur },
          fieldState: { error },
        }) => (
          <SelectBox
            label={t`screens.seminar.country` || ""}
            items={countryItems}
            name={name}
            value={value}
            onChange={(item) => {
              onChange(item.value);
              setIsChCountry(item.value === "CH");
            }}
            onBlur={onBlur}
            invalid={error?.message ? true : false}
            errorMsg={error?.message}
          />
        )}
      />
    ),
    [control, countryItems, t]
  );

  const cantonSelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Addresses.0.Canton"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({
          field: { onChange, name, value, onBlur },
          fieldState: { error },
        }) => (
          <SelectBox
            label={t`screens.seminar.canton` || ""}
            items={cantonItems(t)}
            name={name}
            value={value}
            onChange={(item) => {
              onChange(item.value);
            }}
            onBlur={onBlur}
            invalid={error?.message ? true : false}
            errorMsg={error?.message}
          />
        )}
      />
    ),
    [control, t]
  );

  const toggleContent = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const disclaimerInput = useMemo(
    () => (
      <Controller
        control={control}
        name="TCAccepted"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({ field: { onChange, name } }) => (
          <FieldRow customCssClass={style.disclaimerRow}>
            <FieldGroup customCssClass={style.disclaimerCheckBox}>
              <CheckBox
                name={name}
                onChange={(ev) => {
                  onChange(ev.target.checked);
                }}
              />
            </FieldGroup>
            <FieldGroup customCssClass={style.disclaimer}>
              <Trans
                i18nKey={t`screens.seminar.gtcAgree`}
                components={{
                  Disclaimer: (
                    <button
                      type="button"
                      className={style.disclaimerButton}
                      onClick={toggleContent}
                    />
                  ),
                }}
              />
              <i
                className={
                  isExpanded
                    ? "cticonapp-collapse-arrow"
                    : "cticonapp-expand-arrow"
                }
                onClick={toggleContent}
              />
              {isExpanded && (
                <div className={style.expContent}>
                  <Trans
                    i18nKey={
                      isChSite
                        ? t`screens.seminar.gtcAgreeDescCh`
                        : t`screens.seminar.gtcAgreeDescCom`
                    }
                    components={{
                      B: <ul />,
                      S: <li />,
                      Here: (
                        // eslint-disable-next-line
                        <a
                          href={`/${
                            i18n.resolvedLanguage || "en"
                          }/webinar-disclaimer/`}
                          rel="noreferrer"
                          target="_blank"
                        />
                      ),
                    }}
                  />
                </div>
              )}
            </FieldGroup>
          </FieldRow>
        )}
      />
    ),
    [control, i18n.resolvedLanguage, isChSite, isExpanded, t, toggleContent]
  );

  const seats = useMemo(() => {
    switch (dataAttribute.dataWidgetSeminarSeats) {
      case "no":
        return t`screens.seminar.seats.No`;

      case "few":
        return t`screens.seminar.seats.Few`;

      case "available":
        return t`screens.seminar.seats.Available`;

      default:
        return "";
    }
  }, [dataAttribute.dataWidgetSeminarSeats, t]);

  return stepForm === "FormSubmitted" ? (
    <CtWidgetSeminarSuccess halfContainer={dataAttribute.halfContainer} />
  ) : (
    <div className={style.action}>
      <div className={style.seminarForm}>
        {!dataAttribute.dataWidgetHideTitle ? (
          <h3 className={style.seminarTitle}>
            <img src={seminarLogo} alt="" />
            {t`screens.seminar.formTitle`}
          </h3>
        ) : (
          <></>
        )}

        <form autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
          <FieldSet>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{firstNameInput}</FieldGroup>
              <FieldGroup>{lastNameInput}</FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{emailInput}</FieldGroup>
              <FieldGroup>{phoneInput}</FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{countrySelectBox}</FieldGroup>
              {isChCountry ? (
                <FieldGroup>{cantonSelectBox}</FieldGroup>
              ) : (
                <FieldGroup cssClass="void"> </FieldGroup>
              )}
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{disclaimerInput}</FieldGroup>
            </FieldRow>
          </FieldSet>
          <div className={style.cta}>
            <Button
              text={t`screens.seminar.submit`}
              disabled={!formState.isValid || loading}
              type="submit"
              icon={
                loading
                  ? { icon: "loader", position: "right", spin: true }
                  : undefined
              }
              // set trk Adobe Analytics
              customCssClass="trkEvents"
            />
            <strong>{seats}</strong>
            {isError ? (
              <span className={style.errorSubmit}>{t`errors.submit`}</span>
            ) : (
              <></>
            )}
          </div>
          <GooglCaptchaDisclaimer></GooglCaptchaDisclaimer>
        </form>
      </div>
    </div>
  );
};

export default CtWidgetSeminar;
