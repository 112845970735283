export const TRANSLATIONS_EN = {
  screens: {
    seminar: {
      formTitle: "Join for free",
      firstName: "First name",
      lastName: "Surname",
      email: "Email address",
      country: "Country of residence",
      canton: "Canton",
      phoneNr: "Phone number",
      emailRequired: "Please enter a valid email address",
      phoneRequired: "Only number format please, e.g.: 7812234567",
      gtcAgree:
        "By submitting an event registration, I accept the <Disclaimer>disclaimer</Disclaimer>.",
      gtcAgreeDescCh:
        "By submitting an event registration request, I accept the following: <B><S>Cornèr Bank Ltd. is a fully Swiss licensed bank that is not authorized by regulators in other countries;</S><S>The sign-up form is not to be qualified as an active solicitation of banking and/or financial services and/or financial products towards residents of foreign countries;</S><S>I accept to be contacted by Cornèr Bank Ltd. by phone or email in order to receive further information regarding the trading platforms, their functionalities, products and services connected with the operationality of the platforms;</S><S>For webinar registrations, find the full disclaimer <Here>here</Here>.</S></B>",
      gtcAgreeDescCom:
        "By submitting an event registration request, I accept the following: <B><S>Cornèr Bank (Overseas) Ltd. is a licensed bank in The Bahamas that is not authorized by regulators in other countries;</S><S>The sign-up form is not to be qualified as an active solicitation of banking and/or financial services and/or financial products towards residents of foreign countries;</S><S>I accept to be contacted by Cornèr Bank (Overseas) Ltd. by phone or email in order to receive further information regarding the trading platforms, their functionalities, products and services connected with the operationality of the platforms;</S><S>For webinar registrations, find the full disclaimer <Here>here</Here>.</S></B>",
      submit: "Sign up now",
      seats: {
        No: "NO seats",
        Few: "Few seats left!",
        Available: "Available seats",
      },
    },
    demo: {
      pageTitle: "Sign up for a free demo",
      firstName: "First name",
      surName: "Surname",
      email: "Email address",
      country: "Country of residence",
      canton: "Canton",
      phoneNr: "Phone number",
      emailRequired: "Please enter a valid email address",
      phoneRequired: "Only number format please, e.g.: 7812234567",
      gtcAgree:
        "By signin up for a free demo account, I accept the <Disclaimer>disclaimer</Disclaimer>.",
      gtcAgreeDescCh:
        "By submitting my request for the demo account I recognize that I’m informed and that I accept the following: <B><S>Cornèr Bank Ltd. is a fully licensed bank in Switzerland that is not authorized by regulators in other countries;</S><S>The demo account form is not an active solicitation of banking and/or financial services and/or financial products toward residents of foreign countries;</S><S>Therefore, I access to this demo account on my own and sole initiative;</S><S>I accept to be contacted by Cornèr Bank Ltd. by phone or email in order to receive further information regarding the trading platforms, their functionalities, products and services connected with the operativity of the platforms.</S></B>",
      gtcAgreeDescCom:
        "By submitting my request for the demo account I recognize that I’m informed and that I accept the following: <B><S>Cornèr Bank (Overseas) Ltd. is a licensed bank in The Bahamas that is not authorized by regulators in other countries;</S><S>The demo account form is not an active solicitation of banking and/or financial services and/or financial products toward residents of foreign countries;</S><S>Therefore, I access to this demo account on my own and sole initiative. I accept to be contacted by Cornèr Bank (Overseas) Ltd. by phone or email in order to receive further information regarding the trading platforms, their functionalities, products and services connected with the operativity of the platforms;</S><S>I/We hereby authorize Cornèr Bank (Overseas) Ltd. to communicate our email address solely to Cornèr Bank Ltd. Zurch, Switzerland for the purpose of receiving Cornèrtrader daily investment newsletter and other communications. The authorization remains in force until it is revoked in writing.</S></B>",
      submit: "Sign up",
    },
    contactB2B: {
      formTitle: "Contact us",
      formSubTitle:
        "Our Relationship Managers are happy to answer your questions.",
      firstName: "First name",
      lastName: "Surname",
      company: "Company",
      position: "Role/Position",
      email: "Email address",
      country: "Country of residence",
      canton: "Canton",
      phoneNr: "Phone number",
      emailRequired: "Please enter a valid email address",
      phoneRequired: "Only number format please, e.g.: 7812234567",
      notes: "Message",
      disclaimerCh:
        "By submitting my contact request – without any further obligation – to a representative of Cornèr Bank Ltd., I accept the following: <B><S>Cornèr Bank Ltd. is a fully licensed Swiss bank that is not authorized by regulators in other countries;</S><S>The contact form is not to be qualified as an active solicitation of banking and/or financial services and/or financial products towards residents of foreign countries;</S><S>I accept to be contacted by Cornèr Bank Ltd. by phone or email in order to obtain further information regarding the investment platforms, their functionalities, products and services connected with the operationality of the platforms.</S></B>",
      disclaimerCom:
        "By submitting my contact request – without any further obligation – to a representative of Cornèr Bank (Overseas) Ltd., I accept the following: <B><S>Cornèr Bank (Overseas) Ltd. is a licensed bank in The Bahamas that is not authorized by regulators in other countries;</S><S>The contact form is not to be qualified as an active solicitation of banking and/or financial services and/or financial products towards residents of foreign countries;</S><S>I accept to be contacted by Cornèr Bank (Overseas) Ltd. by phone or email in order to obtain further information regarding the investment platforms, their functionalities, products and services connected with the operationality of the platforms.</S></B>",
      submit: "Send",
    },
    contactB2C: {
      formTitle: "Contact us",
      formSubTitle:
        "Our Relationship Managers are happy to answer your questions.",
      alreadyAccount: "I already have an account",
      newAccount: "I don't have an account yet",
      firstName: "First name",
      lastName: "Surname",
      email: "Email address",
      country: "Country of residence",
      canton: "Canton",
      phoneNr: "Phone number",
      emailRequired: "Please enter a valid email address",
      phoneRequired: "Only number format please, e.g.: 7812234567",
      notes: "Message",
      topic: "Choose topic",
      disclaimerCh:
        "By submitting my contact request – without any further obligation – to a representative of Cornèr Bank Ltd., I accept the following: <B><S>Cornèr Bank Ltd. is a fully licensed bank in Switzerland that is not authorized by regulators in other countries;</S><S>The contact form is not to be qualified as an active solicitation of banking and/or financial services and/or financial products towards residents of foreign countries;</S><S>I accept to be contacted by Cornèr Bank Ltd. by phone or email to obtain further information regarding the investment platforms, their functionalities, products and services connected with the operationality of the platforms.</S></B>",
      disclaimerCom:
        "By submitting my contact request – without any further obligation – to a representative of Cornèr Bank (Overseas) Ltd., I accept the following: <B><S>Cornèr Bank (Overseas) Ltd. is a licensed bank in The Bahamas that is not authorized by regulators in other countries;</S><S>The contact form is not to be qualified as an active solicitation of banking and/or financial services and/or financial products towards residents of foreign countries;</S><S>I accept to be contacted by Cornèr Bank (Overseas) Ltd. by phone or email to obtain further information regarding the investment platforms, their functionalities, products and services connected with the operationality of the platforms.</S></B>",
      submit: "Send",
      message: "Message",
    },
    meeting: {
      formTitle: "Book a <B>one-on-one</B> meeting",
      formSubTitle:
        "Our Relationship Managers will be happy to <B>assist</B> you in person and online.",
      phone: "On the phone",
      face: "Face to face",
      location: "Location",
      date: "Date",
      errorDate: "Error Date",
      time: "Choose a time slot",
      allTime: "All time slots",
      firstName: "First name",
      lastName: "Surname",
      email: "Email address",
      country: "Country of residence",
      canton: "Canton",
      phoneNr: "Phone number",
      emailRequired: "Please enter a valid email address",
      phoneRequired: "Only number format please, e.g.: 7812234567",
      noteLanguage:
        "Leave us a message if you’d like to request another language.",
      notes:
        "Do you have any questions or suggestions? Leave us a message here!",
      gtcAgree:
        "By requesting to be contacted, I explicitly agree to the <Disclaimer>disclaimer</Disclaimer>.",
      gtcAgreeDescCh:
        "By submitting my contact request - without any further obligation - by a representative of Cornèr Bank Ltd., I accept the following: <B><S>Cornèr Bank Ltd. is a fully Swiss licensed bank that is not authorized by regulators in other countries;</S><S>The contact form is not to be qualified as an active solicitation of banking and/or financial services and/or financial products towards residents of foreign countries;</S><S>I accept to be contacted by Cornèr Bank Ltd. by phone or email in order to receive further information regarding the trading platforms, their functionalities, products and services connected with the operationality of the platforms.</S></B>",
      gtcAgreeDescCom:
        "By submitting my contact request - without any further obligation - by a representative of Cornèr Bank (Overseas) Ltd., I accept the following: <B><S>Cornèr Bank (Overseas) Ltd. is a licensed bank in The Bahamas that is not authorized by regulators in other countries;</S><S>The contact form is not to be qualified as an active solicitation of banking and/or financial services and/or financial products towards residents of foreign countries;</S><S>I accept to be contacted by Cornèr Bank (Overseas) Ltd. by phone or email in order to receive further information regarding the trading platforms, their functionalities, products and services connected with the operationality of the platforms.</S></B>",
      submit: "Book a meeting",
      preferences: "Set your preferences",
      contact: "Your contact details",
      language: "Language",
      message: "Message",
      messageSubmit: "Please fill in all fields to proceed",
    },
    signals: {
      pageTitle: "Daily market news",
      firstName: "First name",
      surName: "Surname",
      email: "Email address",
      country: "Country of residence",
      canton: "Canton",
      phoneNr: "Phone number",
      emailRequired: "Please enter a valid email address",
      phoneRequired: "Only number format please, e.g.: 7812234567",
      language: "Preferred language",
      gtcAgree:
        "By subscribing to the newsletter, I accept the <Disclaimer>disclaimer</Disclaimer>.",
      gtcAgreeDescCh:
        "By signing up for our market research and trading signals service, you explicitly agree to receive emails from Cornèrtrader. Such emails do not contain any tax, financial or investment advice. They are only intended to provide general information, and shall not be considered providing any sort of advice, solicitation or incentive in pursuing investments of any kind. Therefore, Cornèrtrader will not be liable for any losses that you may sustain as a result of investment decisions made in relation to information available in such emails. Click <Click>here</Click> for the full disclaimer.",
      gtcAgreeDescCom:
        "By signing up for our market research and trading signals service, you explicitly agree to receive emails from Cornèrtrader. Such emails do not contain any tax, financial or investment advice. They are only intended to provide general information, and shall not be considered providing any sort of advice, solicitation or incentive in pursuing investments of any kind. Therefore, Cornèrtrader will not be liable for any losses that you may sustain as a result of investment decisions made in relation to information available in such emails. Click <Click>here</Click> for the full disclaimer.",
      submit: "Subscribe now",
    },
  },
  success: {
    seminar: {
      pageTitle: "Explore the free demo of our platform",
      pageSubtitle:
        "You are signed up! <br>We’ll send you all the details via email.",
      tryButton: "Try the demo",
    },
    demo: {
      pageTitleFormSubmitted: "Thanks for signing up!",
      pageTitleCountryNotAllowed: "We’re sorry",
      pageTitleCountryAlmostAllowed: "We’re sorry",
      pageSubtitleFormSubmitted:
        "You’ll receive daily insights about market research and trading signals.",
      pageSubtitleCountryNotAllowed:
        "We’re unable to provide this service at present in your country of residence",
      pageSubtitleCountryAlmostAllowed:
        "We’re currently unable to provide this type of service in your country of residence. <br><br>We’ll contact you as soon as possible.",
      pageTitleCredential: "Thanks for signing up!",
      pageSubtitleCredential:
        "We’ve sent the access credentials to your free demo <Value>{{contactMail}}</Value>",
      credential: "Use this credential",
      userId: "Here is your User ID: <Value>{{userId}}</Value>",
      password: "Here is your Password: <Value>{{password}}</Value>",
      closeButton: "Close",
    },
    contactB2B: {
      pageTitle: "Talk to you soon!",
      pageSubtitle:
        "Thank you for your request. Your Relationship Manager will get in touch with you shortly.",
      checkPlatform: "Check our platforms",
      platforms: "platforms",
    },
    contactB2C: {
      pageTitle: "Talk to you soon!",
      pageSubtitle:
        "Thank you for your request. Your Relationship Manager will get in touch with you shortly.",
      checkPlatform: "Check our platforms",
      platforms: "platforms",
    },
    meeting: {
      pageTitle: "Talk to you soon!",
      pageSubtitle:
        "Thank you for your request. Your Relationship Manager will get in touch with you shortly.",
      checkPlatform: "Check our platforms",
      platforms: "platforms",
    },
    signals: {
      pageTitleFormSubmitted: "Thank you for subscribing",
      pageTitleCountryNotAllowed: "We’re sorry",
      pageTitleCountryAlmostAllowed: "We’re sorry",
      pageSubtitleFormSubmitted:
        "You’ll receive daily insights about market research and investment signals.",
      pageSubtitleCountryNotAllowed:
        "We’re unable to provide this service at present in your country of residence",
      pageSubtitleCountryAlmostAllowed:
        "We’re currently unable to provide this type of service in your country of residence. We’ll contact you as soon as possible.",
      closeButton: "Close",
      ocmsButton: "You are subscribed",
    },
  },
  errors: {
    input: {
      invalidPattern: "Invalid format",
      minLength: "At least {{min}} characters needed",
      maxLength: "A maximum of {{max}} characters are allowed",
      minPhoneLength: "At least {{min}} digits needed",
      required: "Required field",
      mismatch: "{{fieldName}} mismatch",
    },
    submit: "Unfortunately, a technical problem has occurred.",
    contactFormSuggestion:
      "We are happy to help. Please contact us via the <contactLink><strong>online form</strong></contactLink>",
  },
  district: {
    AG: "Aargau",
    AI: "Appenzell Innerrhoden",
    AR: "Appenzell Ausserrhoden",
    BS: "Basel-Stadt",
    BL: "Basel-Landschaft",
    BE: "Bern",
    FR: "Fribourg",
    GE: "Geneva",
    GL: "Glarus",
    GR: "Graub\u00fcnden",
    JU: "Jura",
    LU: "Luzern",
    NE: "Neuch\u00e2tel",
    NW: "Nidwalden",
    OW: "Obwalden",
    SH: "Schaffhausen",
    SZ: "Schwyz",
    SO: "Solothurn",
    SG: "St. Gallen",
    TG: "Thurgau",
    TI: "Ticino",
    UR: "Uri",
    VS: "Valais",
    VD: "Vaud",
    ZG: "Zug",
    ZH: "Z\u00fcrich",
  },
  location: {
    online: "All locations",
    zurich: "Zurich",
    geneva: "Geneva",
    lugano: "Lugano",
  },
  language: {
    en: "English",
    de: "German",
    it: "Italian",
    fr: "French",
  },
  topic: {
    a: "Orders or positions",
    b: "Technical or back office",
    c: "Account opening",
    d: "Conditions and fees",
    e: "Educational",
    f: "Others",
  },
  captchaDisclaimer:
    "This site is protected by reCAPTCHA and the<br/>Google <0>Privacy Policy</0> and <1> Terms of Service</1> apply.",
};
