import React, { useCallback, useEffect } from "react";
import { Button, FieldGroup, FieldRow, FieldSet } from "react-corner-ds";
import { Trans, useTranslation } from "react-i18next";
import seminarImgSuccess from "../../../assets/img/seminar/seminarSuccess.png";
import style from "./CtWidgetSeminarSuccess.module.css";

interface ICtWidgetSeminarSuccess {
  halfContainer?: boolean;
}

const CtWidgetSeminarSuccess: React.FC<ICtWidgetSeminarSuccess> = ({
  halfContainer,
}) => {
  const { t } = useTranslation();

  // set trk Adobe Analytics
  const buttonDemo = document.getElementsByClassName("trkDemo");
  buttonDemo[0]?.setAttribute("data-trk-btn-click", "openmodal-demo");

  const redirectDemoForm = useCallback(() => {
    const ocmsFormDemo = document.querySelector(
      '[data-modal="form-demo"]'
    ) as HTMLElement;

    ocmsFormDemo && ocmsFormDemo.click();
  }, []);

  const scrollDataWidget = document.querySelector(
    '[data-widget="ct-widget-seminar"]'
  )?.scrollTop;

  window.scrollTo({ top: scrollDataWidget, behavior: "smooth" });

  useEffect(() => {
    window.buildSuccessFormPageName("events");
  }, []);

  return (
    <div className={style.action}>
      <div className={style.seminarSuccess}>
        <FieldSet>
          <FieldRow customCssClass={halfContainer ? style.customFieldRow : ""}>
            <FieldGroup customCssClass={style.seminarSuccessInfo}>
              <FieldSet>
                <FieldRow>
                  <FieldGroup>
                    <h3>
                      <i className="cticonapp-ok-2" />
                    </h3>
                    <h3>
                      <Trans
                        i18nKey={t`success.seminar.pageSubtitle`}
                        components={{
                          B: <ul />,
                          S: <li />,
                        }}
                      />
                    </h3>
                  </FieldGroup>
                </FieldRow>
                <FieldRow>
                  <FieldGroup>
                    <h2
                      className={style.seminarSuccessTitle}
                    >{t`success.seminar.pageTitle`}</h2>
                  </FieldGroup>
                </FieldRow>
                <FieldRow>
                  <FieldGroup>
                    <Button
                      text={t`success.seminar.tryButton`}
                      onClick={redirectDemoForm}
                      cssClass={["secondary"]}
                      type="button"
                      // set trk Adobe Analytics
                      customCssClass="trkDemo close-modal"
                    />
                  </FieldGroup>
                </FieldRow>
              </FieldSet>
            </FieldGroup>
            <FieldGroup customCssClass={style.seminarSuccessImg}>
              <img alt="img" src={seminarImgSuccess} />
            </FieldGroup>
          </FieldRow>
        </FieldSet>
      </div>
    </div>
  );
};

export default CtWidgetSeminarSuccess;
