.contactB2BSuccess {
  --primary-color: #154dd1;
  --primary-border-color: #154dd1;
  --primary-text-color: #fff;
  --primary-hover-color: #193f99;
  --primary-hover-border-color: #193f99;
  --primary-hover-text-color: #fff;

  --secondary-color: #39d09e;
  --secondary-border-color: #39d09e;
  --secondary-text-color: #fff;
  --secondary-hover-color: #009966;
  --secondary-hover-border-color: #009966;
  --secondary-hover-text-color: #fff;

  padding: 0 32px 40px 32px;
  background-color: var(--primary-text-color);
  border-radius: 8px;
  opacity: 1;
  transition: all 0.2s linear;

  font-size: 18px;
  font-weight: 200;
  text-align: center;
  line-height: 1.56;
}

.action {
  flex: 1 1 50%;
  position: relative;

  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  font-family: 'Lexend', sans-serif;
  color: var(--form-text-color);
}

.contactB2BTitle {
  font-size: 40px;
  font-weight: 500;
  word-wrap: break-word;
  line-height: 1.1;
  margin-top: 32px;
  text-align: center;
}

.contactB2BSubTitle {
  color: var(--form-text-color);
  font-size: 20px;
  font-weight: 200;
  line-height: 1.27;
  margin: 16px auto 24px;
  text-align: center;
}

.contactB2BSuccessTitle h3 {
  color: var(--form-text-success-color);
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  text-transform: none;
}

.contactB2BSuccessTitle h3 i {
  font-size: 80px;
  display: block;
}

.contactB2BSuccessSubTitle {
  text-align: center;
  color: var(--form-text-color);
  margin-top: 0;
  margin-bottom: 20px;
}

.contactB2BSuccessButton {
  text-align: center;
  color: var(--primary-color);
  text-decoration: none;
  outline: none;
  font-weight: 600;
  line-height: 1.33;
}

.contactB2BSuccessButton button {
  min-width: 150px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  padding: 6px 16px;
  font-family: 'Lexend', sans-serif;
}

@media screen and (min-width: 769px) {
  .contactB2BTitle {
    justify-content: center;
    font-size: 52px;
  }

  .contactB2BSuccessButton button {
    min-width: 0;
  }
}

@media screen and (min-width: 993px) {
  .contactB2BTitle {
    font-size: 64px;
  }

  .contactB2BSubTitle {
    font-size: 24px;
  }
}

@media screen and (min-width: 1280px) {
  .action {
    flex: 1 0 600px;
  }
}