.demoSuccess {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding: 20px;
  overflow: hidden;
  font-family: 'Lexend', sans-serif;
  font-size: 18px;
  font-weight: 200;
  text-align: center;
  line-height: 1.56;
}

.demoSuccessCustomRow {
  flex-direction: column!important;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px!important;
}

.demoSuccessCustomRow h4 {
  font-weight: 500;
}

.demoSuccessTitle h2 {
  font-size: 40px;
  color: var(--form-text-title-color);
}

.demoSuccessTitle h2 i {
  font-size: 80px;
  display: block;
  margin-bottom: 24px;
}

.demoSuccessSubTitle {
  display: flex;
  align-items: center;
  max-width: fit-content;
  gap: 12px;
  color: var(--form-text-color);
  font-weight: 300;
  border: 2px solid var(--form-background-box-color);
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  margin-top: 0!important;
}

.demoSuccessLabel {
  min-width: 122px;
  text-align: end;
}

.demoSuccessCredentials {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.demoSuccessClipboardBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  height: 40px;
  margin-right: 38px;
  padding: 0 12px;
  background-color: var(--form-background-box-color);
  border-radius: 8px;
  cursor: pointer;
}

.demoSuccessValue {
  color: var(--form-text-title-color);
  font-weight: 500;
}

.demoSuccessTooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
}

.demoSuccessTooltip > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  position: relative;
  height: 27px;
  width: 83px;
  background-color: #23CD9429;
  color: black;
  padding: 6px 10px 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  opacity: 1;
}

.demoSuccessTooltip > span > .customIcon {
  color: green;
  font-size: 18px;
  font-weight: 500;
}

.demoSuccessButton,
.demoSuccessButton:hover,
.demoSuccessButton:hover:not([disabled]),
.demoSuccessButton:active:not([disabled]) {
  min-width: 222px;
  height: 60px;
  margin-top: 24px;
  text-transform: uppercase;
  background-color: var(--form-text-title-color);
  border: transparent;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
}

.customButton button {
  font-family: 'Lexend', sans-serif;
}

.customButton button.closeButton,
.customButton button.closeButton:hover {
  background-color: transparent !important;
  color: var(--form-text-success-color) !important;
  border: none !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.33;
  opacity: 1 !important;
  cursor: pointer;
}

.customButton button.closeButton:hover {
  text-decoration: underline !important;
}


