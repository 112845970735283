export const TRANSLATIONS_DE = {
  screens: {
    seminar: {
      formTitle: "Kostenlos anmelden",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail-Adresse",
      country: "Land des Wohnsitzes",
      canton: "Kanton",
      phoneNr: "Telefonnummer",
      emailRequired: "Bitte gültige E-Mail-Adresse erfassen",
      phoneRequired:
        "Mobiltelefon (reine Zahlenangaben, keine Sonderzeichen, z. B.: 7812234567)",
      gtcAgree:
        "Mit der Anmeldung zu einer Veranstaltung akzeptiere ich den <Disclaimer>Haftungsausschluss</Disclaimer>.",
      gtcAgreeDescCh:
        "Durch das Einreichen des Eventantrages, akzeptiere ich hiermit folgendes: <B><S>Cornèr Bank AG ist eine in der Schweiz und keinem anderen Land lizenzierte Bank;</S><S>Die Seminarregistrierung ist keine Werbung für Banking und/oder Finanzdienstleistungen und/oder Finanzprodukte für Personen mit Wohnsitz im Ausland;</S><S>Ich stimme zu, von Cornèr Bank AG kontaktiert zu werden, per Telefon oder E-Mail um weitere Informationen über die Handels-Plattform zu erhalten, die Funktionalität, die Produkte und Services die mit dem Betrieb der Plattform verbunden sind;</S><S><Here>Hier</Here> finden Sie den vollständigen Disclaimer für die Anmeldung zum Webinar.</S></B>",
      gtcAgreeDescCom:
        "Durch das Einreichen des Eventantrages, akzeptiere ich hiermit folgendes: <B><S>Cornèr Bank AG ist eine in der Schweiz und keinem anderen Land lizenzierte Bank;</S><S>Die Seminarregistrierung ist keine Werbung für Banking und/oder Finanzdienstleistungen und/oder Finanzprodukte für Personen mit Wohnsitz im Ausland;</S><S>Ich stimme zu, von Cornèr Bank AG kontaktiert zu werden, per Telefon oder E-Mail um weitere Informationen über die Handels-Plattform zu erhalten, die Funktionalität, die Produkte und Services die mit dem Betrieb der Plattform verbunden sind;</S><S><Here>Hier</Here> finden Sie den vollständigen Disclaimer für die Anmeldung zum Webinar.</S></B>",
      submit: "Jetzt anmelden",
      seats: {
        No: "Keine Sitzplätze",
        Few: "Nur noch wenige Plätze frei!",
        Available: "Plätze zur Verfügung",
      },
    },
    demo: {
      pageTitle: "Melden Sie sich für eine kostenlose Demo an",
      firstName: "Vorname",
      surName: "Nachname",
      email: "E-Mail-Adresse",
      country: "Land des Wohnsitzes",
      canton: "Kanton",
      phoneNr: "Telefonnummer",
      emailRequired: "Bitte gültige E-Mail-Adresse erfassen",
      phoneRequired:
        "Mobiltelefon (reine Zahlenangaben, keine Sonderzeichen, z. B.: 7812234567)",
      gtcAgree:
        "Mit der Anmeldung für ein kostenloses Demo-Konto akzeptiere ich den <Disclaimer>Haftungsausschluss</Disclaimer>.",
      gtcAgreeDescCh:
        "Mit dem Absenden meines Antrags für ein Demo-Konto bestätige ich, dass ich Folgendes zur Kenntnis genommen habe und akzeptiere: <B><S>Cornèr Bank AG ist eine in der Schweiz und keinem anderen Land lizenzierte Bank;</S><S>Das Demo-Konto-Antragsformular ist kein Angebot von Bank- und/oder Finanzdienstleistungen für Personen mit Wohnsitz im Ausland;</S><S>Der Zugriff auf dieses Demo-Konto erfolgt einzig und allein auf meine Initiative hin.</S><S>Ich stimme zu, von Cornèr Bank AG per Telefon oder E-Mail kontaktiert zu werden, um weitere Informationen über die Handels-Plattformen zu erhalten sowie über die Funktionalität, die Produkte und Services, die mit dem Betrieb der Plattformen verbunden sind.</S></B>",
      gtcAgreeDescCom:
        "Mit dem Absenden meines Antrags für ein Demo-Konto bestätige ich, dass ich Folgendes zur Kenntnis genommen habe und akzeptiere: <B><S>Cornèr Bank AG ist eine in der Schweiz und keinem anderen Land lizenzierte Bank;</S><S>Das Demo-Konto-Antragsformular ist kein Angebot von Bank- und/oder Finanzdienstleistungen für Personen mit Wohnsitz im Ausland;</S><S>Der Zugriff auf dieses Demo-Konto erfolgt einzig und allein auf meine Initiative hin.</S><S>Ich stimme zu, von Cornèr Bank AG per Telefon oder E-Mail kontaktiert zu werden, um weitere Informationen über die Handels-Plattformen zu erhalten sowie über die Funktionalität, die Produkte und Services, die mit dem Betrieb der Plattformen verbunden sind.</S></B>",
      submit: "Anmelden",
    },
    contactB2B: {
      formTitle: "Kontaktieren Sie uns",
      formSubTitle:
        "Unsere Relationship Manager beantworten gerne Ihre Fragen.",
      firstName: "Vorname",
      lastName: "Nachname",
      company: "Unternehmen",
      position: "Rolle/Position",
      email: "E-Mail-Adresse",
      country: "Land des Wohnsitzes",
      canton: "Kanton",
      phoneNr: "Telefonnummer",
      emailRequired: "Bitte gültige E-Mail-Adresse erfassen",
      phoneRequired:
        "Mobiltelefon (reine Zahlenangaben, keine Sonderzeichen, z. B.: 7812234567)",
      notes: "Nachricht",
      disclaimerCh:
        "Durch das Einreichen der Anfrage für die Kontaktaufnahme − ohne weitere Verpflichtung ­− durch einen Repräsentanten der Cornèr Bank AG, akzeptiere ich hiermit folgendes: <B><S>Cornèr Bank AG ist eine in der Schweiz und keinem anderen Land lizenzierte Bank;</S><S>Das Kontaktformular ist nicht als aktive Kundenwerbung für Bank- und/oder Finanzdienstleistungen und/oder Finanzprodukte für Personen mit Wohnsitz im Ausland zu verstehen;</S><S>Ich bin damit einverstanden, dass die Cornèr Bank AG mich telefonisch oder per E-Mail kontaktiert, um mir weitere Informationen über die Handelsplattformen, ihre Funktionalitäten, Produkte und Dienstleistungen im Zusammenhang mit dem Service der Plattformen zukommen zu lassen.</S></B>",
      disclaimerCom:
        "Durch das Einreichen der Anfrage für die Kontaktaufnahme − ohne weitere Verpflichtung ­− durch einen Repräsentanten der Cornèr Bank AG, akzeptiere ich hiermit folgendes: <B><S>Cornèr Bank AG ist eine in der Schweiz und keinem anderen Land lizenzierte Bank;</S><S>Das Kontaktformular ist nicht als aktive Kundenwerbung für Bank- und/oder Finanzdienstleistungen und/oder Finanzprodukte für Personen mit Wohnsitz im Ausland zu verstehen;</S><S>Ich bin damit einverstanden, dass die Cornèr Bank AG mich telefonisch oder per E-Mail kontaktiert, um mir weitere Informationen über die Handelsplattformen, ihre Funktionalitäten, Produkte und Dienstleistungen im Zusammenhang mit dem Service der Plattformen zukommen zu lassen.</S></B>",
      submit: "Senden",
    },
    contactB2C: {
      formTitle: "Kontaktieren Sie uns",
      formSubTitle:
        "Unsere Relationship Manager beantworten gerne Ihre Fragen.",
      alreadyAccount: "Ich habe bereits ein Konto",
      newAccount: "Ich habe noch kein Konto",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail-Adresse",
      country: "Land des Wohnsitzes",
      canton: "Kanton",
      phoneNr: "Telefonnummer",
      emailRequired: "Bitte gültige E-Mail-Adresse erfassen",
      phoneRequired:
        "Mobiltelefon (reine Zahlenangaben, keine Sonderzeichen, z. B.: 7812234567)",
      notes: "Nachricht",
      topic: "Thema wählen",
      disclaimerCh:
        "Durch das Einreichen der Anfrage für die Kontaktaufnahme − ohne weitere Verpflichtung ­− durch einen Repräsentanten der Cornèr Bank AG, akzeptiere ich hiermit folgendes: <B><S>Cornèr Bank AG ist eine in der Schweiz und keinem anderen Land lizenzierte Bank;</S><S>Das Kontaktformular ist nicht als aktive Kundenwerbung für Bank- und/oder Finanzdienstleistungen und/oder Finanzprodukte für Personen mit Wohnsitz im Ausland zu verstehen;</S><S>Ich bin damit einverstanden, dass die Cornèr Bank AG mich telefonisch oder per E-Mail kontaktiert, um mir weitere Informationen über die Handelsplattformen, ihre Funktionalitäten, Produkte und Dienstleistungen im Zusammenhang mit dem Service der Plattformen zukommen zu lassen.</S></B>",
      disclaimerCom:
        "Durch das Einreichen der Anfrage für die Kontaktaufnahme − ohne weitere Verpflichtung ­− durch einen Repräsentanten der Cornèr Bank AG, akzeptiere ich hiermit folgendes: <B><S>Cornèr Bank AG ist eine in der Schweiz und keinem anderen Land lizenzierte Bank;</S><S>Das Kontaktformular ist nicht als aktive Kundenwerbung für Bank- und/oder Finanzdienstleistungen und/oder Finanzprodukte für Personen mit Wohnsitz im Ausland zu verstehen;</S><S>Ich bin damit einverstanden, dass die Cornèr Bank AG mich telefonisch oder per E-Mail kontaktiert, um mir weitere Informationen über die Handelsplattformen, ihre Funktionalitäten, Produkte und Dienstleistungen im Zusammenhang mit dem Service der Plattformen zukommen zu lassen.</S></B>",
      submit: "Senden",
      message: "Nachricht",
    },
    meeting: {
      formTitle: "Vereinbaren Sie ein <B>Einzelgespräch</B>",
      formSubTitle:
        "Sprechen wir über Ihr Anliegen – unsere Relationship Manager <B>sind für Sie da</B>.",
      phone: "Telefonisch",
      face: "Persönlich vor Ort",
      location: "Standort",
      date: "Date",
      errorDate: "Error Date",
      time: "Termin wählen",
      allTime: "Alle Termine",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail-Adresse",
      country: "Land des Wohnsitzes",
      canton: "Kanton",
      phoneNr: "Telefonnummer",
      emailRequired: "Bitte gültige E-Mail-Adresse erfassen",
      phoneRequired:
        "Mobiltelefon (reine Zahlenangaben, keine Sonderzeichen, z. B.: 7812234567)",
      noteLanguage:
        "Leave us a message if you’d like to request another language.",
      notes:
        "Haben Sie Fragen oder Anregungen? Hinterlassen Sie uns eine Nachricht.",
      gtcAgree:
        "Mit dem Senden meiner Anfrage stimme ich dem <Disclaimer>Haftungsausschluss</Disclaimer> ausdrücklich zu.",
      gtcAgreeDescCh:
        "Durch das Einreichen der Anfrage für die Kontaktaufnahme - ohne weitere Verpflichtung - durch einen Relationship Manager der Cornèr Bank AG, akzeptiere ich hiermit folgendes: <B><S>Cornèr Bank AG ist eine in der Schweiz und keinem anderen Land lizenzierte Bank;</S><S>Das Kontaktformular wird nicht qualifiziert als aktive Kundenwerbung im Banking und/oder Finanzservice und/oder Finanzprodukte gegen Personen mit Wohnsitz im Ausland;</S><S>Ich akzeptiere die Kontaktaufnahme der Cornèr Bank AG per Telefon oder E-Mail in Bezug um mehr Informationen über die Handelsplattform zu erhalten, deren Funktionen, Produkte und den Service in Verbindung zur Plattform.</S></B>",
      gtcAgreeDescCom:
        "Durch das Einreichen der Anfrage für die Kontaktaufnahme - ohne weitere Verpflichtung - durch einen Relationship Manager der Cornèr Bank AG, akzeptiere ich hiermit folgendes: <B><S>Cornèr Bank AG ist eine in der Schweiz und keinem anderen Land lizenzierte Bank;</S><S>Das Kontaktformular wird nicht qualifiziert als aktive Kundenwerbung im Banking und/oder Finanzservice und/oder Finanzprodukte gegen Personen mit Wohnsitz im Ausland;</S><S>Ich akzeptiere die Kontaktaufnahme der Cornèr Bank AG per Telefon oder E-Mail in Bezug um mehr Informationen über die Handelsplattform zu erhalten, deren Funktionen, Produkte und den Service in Verbindung zur Plattform.</S></B>",
      submit: "Termin vereinbaren",
      preferences: "Wie möchten Sie mit uns sprechen?",
      contact: "Ihre Kontaktinformationen",
      language: "Sprache",
      message: "Nachricht",
      messageSubmit:
        "Please fill in all fields to proceedBitte füllen Sie alle Felder aus, um fortzufahren",
    },
    signals: {
      pageTitle: "Marktresearch und Handelssignale",
      firstName: "Vorname",
      surName: "Nachname",
      email: "E-Mail-Adresse",
      country: "Land des Wohnsitzes",
      canton: "Kanton",
      phoneNr: "Telefonnummer",
      emailRequired: "Bitte gültige E-Mail-Adresse erfassen",
      phoneRequired:
        "Mobiltelefon (reine Zahlenangaben, keine Sonderzeichen, z. B.: 7812234567)",
      language: "Bevorzugte Sprache",
      gtcAgree:
        "Mit meinem Newsletter-Abonnement akzeptiere ich den <Disclaimer>Haftungsausschluss</Disclaimer>.",
      gtcAgreeDescCh:
        "Mit der Registrierung für unseren Marketresearch- und Handelssignale-Service geben Sie Ihre ausdrückliche Zustimmung, um von Cornèrtrader E-Mail zu erhalten. Die E-Mails enthalten keine Steuer-, Finanz- oder Investmentberatungen. Diese sind dazu vorgesehen nur allgemeine Informationen zu liefern und dürfen nicht für Beratungs, Kundenwerbung, oder jeglichen Anreiz für Investitionen zweckentfremdet werden. Darum kann Cornèrtrader für durch Ihre Investitionsentscheidungen verursachten Verluste nicht haftbar gemacht werden, welche anhand von Informationen aus diesen E-Mails entstanden. Klicken Sie <Click>hier</Click> für den kompletten Haftungsausschluss.",
      gtcAgreeDescCom:
        "Mit der Registrierung für unseren Marketresearch- und Handelssignale-Service geben Sie Ihre ausdrückliche Zustimmung, um von Cornèrtrader E-Mail zu erhalten. Die E-Mails enthalten keine Steuer-, Finanz- oder Investmentberatungen. Diese sind dazu vorgesehen nur allgemeine Informationen zu liefern und dürfen nicht für Beratungs, Kundenwerbung, oder jeglichen Anreiz für Investitionen zweckentfremdet werden. Darum kann Cornèrtrader für durch Ihre Investitionsentscheidungen verursachten Verluste nicht haftbar gemacht werden, welche anhand von Informationen aus diesen E-Mails entstanden. Klicken Sie <Click>hier</Click> für den kompletten Haftungsausschluss.",
      submit: "Jetzt abonnieren",
    },
  },
  success: {
    seminar: {
      pageTitle: "Schon die gratis Demo ausprobiert?",
      pageSubtitle:
        "Sie sind angemeldet! <br>Alle Details erhalten Sie per E-Mail.",
      tryButton: "Demo testen",
    },
    demo: {
      pageTitleFormSubmitted: "Danke für Ihre Anmeldung!",
      pageTitleCountryNotAllowed: "Es tut uns leid",
      pageTitleCountryAlmostAllowed: "Es tut uns leid",
      pageSubtitleFormSubmitted:
        "Sie erhalten täglich Updates über Marktresearch und Handelssignale.",
      pageSubtitleCountryNotAllowed:
        "Aktuell können wir diesen Service im Land Ihres Wohnsitzes leider nicht anbieten.",
      pageSubtitleCountryAlmostAllowed:
        "Aktuell können wir diesen Dienst in dieser Form im Land Ihres Wohnsitzes nicht anbieten. <br><br>Wir kontaktieren Sie so bald wie möglich.",
      pageTitleCredential: "Danke für Ihre Anmeldung!",
      pageSubtitleCredential:
        "Wir haben die Anmeldedaten für die kostenlose Demo an <Value>{{contactMail}}</Value>",
      credential: "Verwenden Sie diese Anmeldedaten",
      userId: "Ihre Benutzer-ID: <Value>{{userId}}</Value>",
      password: "Ihr Passwort: <Value>{{password}}</Value>",
      closeButton: "Schliessen",
    },
    contactB2B: {
      pageTitle: "Vielen Dank für Ihre Anfrage!",
      pageSubtitle:
        "Ihr Relationship Manager wird sich mit Ihnen in Verbindung setzen. Bis bald!",
      checkPlatform: "Zu unseren Plattformen",
      platforms: "plattformen",
    },
    contactB2C: {
      pageTitle: "Vielen Dank für Ihre Anfrage!",
      pageSubtitle:
        "Ihr Relationship Manager wird sich mit Ihnen in Verbindung setzen. Bis bald!",
      checkPlatform: "Zu unseren Plattformen",
      platforms: "plattformen",
    },
    meeting: {
      pageTitle: "Vielen Dank für Ihre Anfrage!",
      pageSubtitle:
        "Ihr Relationship Manager wird sich mit Ihnen in Verbindung setzen. Bis bald!",
      checkPlatform: "Zu unseren Plattformen",
      platforms: "plattformen",
    },
    signals: {
      pageTitleFormSubmitted: "Vielen Dank für Ihr Abonnement",
      pageTitleCountryNotAllowed: "Es tut uns leid",
      pageTitleCountryAlmostAllowed: "Es tut uns leid",
      pageSubtitleFormSubmitted:
        "Sie erhalten täglich Updates über Marktresearch und Handelssignale.",
      pageSubtitleCountryNotAllowed:
        "Aktuell können wir diesen Service im Land Ihres Wohnsitzes leider nicht anbieten.",
      pageSubtitleCountryAlmostAllowed:
        "Aktuell können wir diesen Dienst in dieser Form im Land Ihres Wohnsitzes nicht anbieten. <br/><br/>Wir kontaktieren Sie so bald wie möglich.",
      closeButton: "Schliessen",
      ocmsButton: "Abonniert",
    },
  },
  errors: {
    input: {
      invalidPattern: "Ungültiges Format",
      minLength: "Mindestens {{min}} Zeichen erforderlich",
      maxLength: "Maximal {{max}} Zeichen erlaubt",
      minPhoneLength:
        "Die Telefonnummer muss aus mindestens {{min}} Ziffern bestehen.",
      required: "Pflichtfeld",
      mismatch: "{{fieldName}} stimmt nicht überein",
    },
    submit: "Leider hat es ein technisches Problem gegeben.",
    contactFormSuggestion:
      "Wir helfen Ihnen gerne weiter: Bitte kontaktieren Sie uns via <contactLink><strong>Online-Formular</strong></contactLink>",
  },
  district: {
    AG: "Aargau",
    AI: "Appenzell Innerrhoden",
    AR: "Appenzell Ausserrhoden",
    BS: "Basel-Stadt",
    BL: "Basel-Landschaft",
    BE: "Bern",
    FR: "Fribourg",
    GE: "Geneva",
    GL: "Glarus",
    GR: "Graub\u00fcnden",
    JU: "Jura",
    LU: "Luzern",
    NE: "Neuch\u00e2tel",
    NW: "Nidwalden",
    OW: "Obwalden",
    SH: "Schaffhausen",
    SZ: "Schwyz",
    SO: "Solothurn",
    SG: "St. Gallen",
    TG: "Thurgau",
    TI: "Ticino",
    UR: "Uri",
    VS: "Valais",
    VD: "Vaud",
    ZG: "Zug",
    ZH: "Z\u00fcrich",
  },
  location: {
    online: "Alle Standorte",
    zurich: "Zürich",
    geneva: "Genf",
    lugano: "Lugano",
  },
  language: {
    en: "Englisch",
    de: "Deutsch",
    it: "Italienisch",
    fr: "Französisch",
  },
  topic: {
    a: "Aufträge oder Positionen",
    b: "Technisches oder Back-Office",
    c: "Kontoeröffnung",
    d: "Bedingungen und Gebühren",
    e: "Ausbildung",
    f: "Andere",
  },
  captchaDisclaimer:
    "Diese Website ist durch reCAPTCHA geschützt, es gelten<br/>die Google <0>Datenschutz-</0> und <1>Nutzungsbedingungen</1>.",
};
