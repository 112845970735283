import React, { useEffect } from "react";
import { Button, FieldGroup, FieldRow, FieldSet } from "react-corner-ds";
import { Trans, useTranslation } from "react-i18next";
import style from "./CtWidgetMeetingSuccess.module.css";

interface ICtWidgetMeetingSuccess {
  selectedLang: string;
  hideTitle: boolean;
}

const CtWidgetMeetingSuccess: React.FC<ICtWidgetMeetingSuccess> = ({
  selectedLang,
  hideTitle,
}) => {
  const { t } = useTranslation();

  const scrollDataWidget = document.querySelector(
    '[data-widget="ct-widget-meeting"]'
  )?.scrollTop;

  window.scrollTo({ top: scrollDataWidget, behavior: "smooth" });

  useEffect(() => {
    window.buildSuccessFormPageName("meeting");
  }, []);

  return (
    <div className={style.action}>
      <div className={style.meetingSuccess}>
        {!hideTitle ? (
          <>
            <h1 className={style.meetingTitle}>
              <Trans
                i18nKey={t`screens.meeting.formTitle`}
                components={{
                  B: <span className={style.hlight} />,
                }}
              />
            </h1>
            <h2 className={style.meetingSubTitle}>
              <Trans
                i18nKey={t`screens.meeting.formSubTitle`}
                components={{
                  B: <strong />,
                }}
              />
            </h2>
          </>
        ) : (
          <></>
        )}

        <div className={style.meetingSuccess}>
          <FieldSet>
            <FieldRow>
              <FieldGroup customCssClass={style.meetingSuccessTitle}>
                <h3>
                  <i className="cticonapp-ok-2"></i>
                  <span>{t`success.meeting.pageTitle`}</span>
                </h3>
              </FieldGroup>
            </FieldRow>
            <FieldRow>
              <FieldGroup customCssClass={style.meetingSuccessSubTitle}>
                <span>{t`success.meeting.pageSubtitle`}</span>
              </FieldGroup>
            </FieldRow>
            <FieldRow>
              <FieldGroup customCssClass={style.meetingSuccessButton}>
                <Button
                  text={t`success.meeting.checkPlatform`}
                  onClick={() => {
                    window.location.replace(
                      window.location.origin +
                        `/${selectedLang}/${t`success.meeting.platforms`}`
                    );
                  }}
                />
              </FieldGroup>
            </FieldRow>
          </FieldSet>
        </div>
      </div>
    </div>
  );
};

export default CtWidgetMeetingSuccess;
