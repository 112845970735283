import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CtWidgetDemo from "./component/ctWidgetDemo/CtWidgetDemo";
import CtWidgetMeeting from "./component/ctWidgetMeeting/CtWidgetMeeting";
import CtWidgetSeminar from "./component/ctWidgetSeminar/CtWidgetSeminar";
import CtWidgetSignals from "./component/ctWidgetSignals/CtWidgetSignals";
import { DataAttribute } from "./models/utils.model";
import CtWidgetContactB2B from "./component/ctWidgetContactB2B/CtWidgetContactB2B";
import CtWidgetContactB2C from "./component/ctWidgetContactB2C/CtWidgetContactB2C";
import { useApi } from "./hooks/useApi/useApi";
import CtWidgetContactB2BSuccess from "./component/successComponents/ctWidgetContactB2B/CtWidgetContactB2BSuccess";
import CtWidgetContactB2CSuccess from "./component/successComponents/ctWidgetContactB2C/CtWidgetContactB2CSuccess";
import CtWidgetDemoSuccess from "./component/successComponents/ctWidgetDemo/CtWidgetDemoSuccess";
import CtWidgetMeetingSuccess from "./component/successComponents/ctWidgetMeeting/CtWidgetMeetingSuccess";
import CtWidgetSeminarSuccess from "./component/successComponents/ctWidgetSeminar/CtWidgetSeminarSuccess";
import CtWidgetSignalsSuccess from "./component/successComponents/ctWidgetSignals/CtWidgetSignalsSuccess";
import { useFlow } from "./hooks/useFlow/useFlow";

declare global {
  interface Window {
    buildSuccessFormPageName(form: string): any;
  }
}

function App(dataOcms: any) {
  const { i18n } = useTranslation();

  const { startAxiosResponseInterceptor } = useApi();
  const { loadConfiguration } = useFlow();

  const configurationAndStartup = useCallback(async () => {
    // Run axios response interceptor
    startAxiosResponseInterceptor();

    // Load configuration
    await loadConfiguration();

    // Get attribute
    const langParam = document.documentElement.getAttribute("lang") || "en";

    //Check if a lang is passed, and set it if valid
    if (langParam && ["it", "en", "fr", "de"].includes(langParam)) {
      i18n.changeLanguage(langParam);
    }
  }, [i18n, startAxiosResponseInterceptor, loadConfiguration]);

  useEffect(() => {
    configurationAndStartup();
  }, []);

  const dataAttribute: DataAttribute = dataOcms.dataOcms;

  const renderWidget = useMemo(() => {
    switch (dataAttribute.dataWidget) {
      case "ct-widget-demo":
        return <CtWidgetDemo dataAttribute={dataAttribute} />;

      case "ct-widget-seminar":
        return <CtWidgetSeminar dataAttribute={dataAttribute} />;

      case "ct-widget-contactB2B":
        return <CtWidgetContactB2B dataAttribute={dataAttribute} />;

      case "ct-widget-contactB2C":
        return <CtWidgetContactB2C dataAttribute={dataAttribute} />;

      case "ct-widget-meeting":
        return <CtWidgetMeeting dataAttribute={dataAttribute} />;

      case "ct-widget-signals":
        return <CtWidgetSignals dataAttribute={dataAttribute} />;

      //TODO Eliminare
      case "ct-widget-demo-success":
        return (
          <div>
            <CtWidgetDemoSuccess
              stepForm="FormSubmitted"
              contactMail="mailtest@test.it"
              webAccount={{
                ExtUserID: "Test_UserID",
                PasswordClear: "Test_Psw",
              }}
            />
            <CtWidgetDemoSuccess stepForm="CountryNotAllowed" />
            <CtWidgetDemoSuccess stepForm="CountryAlmostAllowed" />
          </div>
        );

      case "ct-widget-seminar-success":
        return (
          <CtWidgetSeminarSuccess halfContainer={dataAttribute.halfContainer} />
        );

      case "ct-widget-contactB2B-success":
        return (
          <CtWidgetContactB2BSuccess
            selectedLang="en"
            hideTitle={dataAttribute.dataWidgetHideTitle || false}
          />
        );

      case "ct-widget-contactB2C-success":
        return (
          <CtWidgetContactB2CSuccess
            selectedLang="en"
            hideTitle={dataAttribute.dataWidgetHideTitle || false}
          />
        );

      case "ct-widget-meeting-success":
        return (
          <CtWidgetMeetingSuccess
            selectedLang="en"
            hideTitle={dataAttribute.dataWidgetHideTitle || false}
          />
        );

      case "ct-widget-signals-success":
        return (
          <div>
            <CtWidgetSignalsSuccess stepForm="FormSubmitted" />;
            <CtWidgetSignalsSuccess stepForm="CountryNotAllowed" />;
            <CtWidgetSignalsSuccess stepForm="CountryAlmostAllowed" />;
          </div>
        );

      default:
        return <div>Form not found</div>;
    }
  }, [dataAttribute]);

  return <div className="App">{renderWidget}</div>;
}

export default App;
