.contactB2BForm {
  --primary-color: #154dd1;
  --primary-border-color: #154dd1;
  --primary-text-color: #fff;
  --primary-hover-color: #193f99;
  --primary-hover-border-color: #193f99;
  --primary-hover-text-color: #fff;

  --secondary-color: #39d09e;
  --secondary-border-color: #39d09e;
  --secondary-text-color: #fff;
  --secondary-hover-color: #009966;
  --secondary-hover-border-color: #009966;
  --secondary-hover-text-color: #fff;

  font-size: 16px;
  padding: 0 32px 40px 32px;
  background-color: var(--primary-text-color);
  border-radius: 8px;
  opacity: 1;
  transition: all 0.2s linear;
}

.action {
  flex: 1 1 50%;
  position: relative;

  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  font-family: 'Lexend', sans-serif;
  color: var(--form-text-color);
}

.contactB2BTitle {
  font-size: 40px;
  font-weight: 500;
  word-wrap: break-word;
  line-height: 1.1;
  margin-top: 32px;
  text-align: center;
}

.contactB2BSubTitle {
  color: var(--form-text-info-color);
  font-size: 20px;
  font-weight: 200;
  line-height: 1.27;
  margin: 16px auto 24px;
  text-align: center;
}

div.customPhoneInput>div>i {
  color: var(--form-arrow-color) !important;
  font-size: 16px !important;
}

.contactB2BForm textarea {
  resize: vertical;
}

.cta {
  text-align: center;
  margin-top: 12px;
}

.disclaimer {
  font-size: 14px;
  line-height: 1.33;
}

.disclaimerRow {
  flex-direction: row;
}

.disclaimer i {
  margin-left: 0.5rem;
  padding-top: 1rem;
  cursor: pointer;
}

.expContent {
  font-size: 12px;
  line-height: 1.33;
  padding-top: 10px;
}

.expContent ul {
  margin-top: 8px;
  list-style: outside;
}

.expContent li {
  margin-left: 16px;
}

.contactB2BForm button {
  min-width: 150px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  padding: 6px 16px;
  font-family: 'Lexend', sans-serif;
}

.errorSubmit {
  color: var(--form-text-error-color);
  display: block;
  margin-top: 20px;
}

div.customFieldRow {
  margin: 0 -6px;
  display: flex;
  flex-direction: column;
}

.disclaimerInfo {
  line-height: 1.56;
  padding: 24px 24px 16px;
  margin-top: 12px;
  background-color: var(--form-background-info-color);
  border-radius: 4px;
}

.disclaimerInfo>ul {
  margin: 16px 0 16px 1px;
  list-style: none;
}

.disclaimerInfo li:not(:last-child) {
  margin-bottom: 16px;
}

.disclaimerInfo li {
  position: relative;
  padding-left: 18px;
}

.disclaimerInfo li::before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: var(--form-text-title-color);
  border-radius: 3px;
}

@media screen and (min-width: 769px) {
  .contactB2BTitle {
    justify-content: center;
    font-size: 52px;
  }
}

@media screen and (min-width: 993px) {
  .contactB2BTitle {
    font-size: 64px;
  }

  .contactB2BSubTitle {
    font-size: 24px;
  }

  .disclaimerInfo {
    font-size: 18px;
  }
}

@media screen and (min-width: 1280px) {
  .action {
    flex: 1 0 600px;
  }

  div.customFieldRow {
    flex-direction: row;
    flex-wrap: wrap;
  }
}