.signalsForm {
  --primary-color: #154dd1;
  --primary-border-color: #154dd1;
  --primary-text-color: #fff;
  --primary-hover-color: #193f99;
  --primary-hover-border-color: #193f99;
  --primary-hover-text-color: #fff;

  --secondary-color: #39d09e;
  --secondary-border-color: #39d09e;
  --secondary-text-color: #fff;
  --secondary-hover-color: #009966;
  --secondary-hover-border-color: #009966;
  --secondary-hover-text-color: #fff;

  font-size: 16px;
  padding: 0 32px 40px 32px;
  background-color: var(--primary-text-color);
  border-radius: 8px;
  opacity: 1;
  transition: all 0.2s linear;
}

.action {
  flex: 1 1 50%;
  position: relative;

  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  font-family: 'Lexend', sans-serif;
  color: var(--form-text-color);
}

.customLanguage {
  font-size: 18px;
  font-weight: 500;
  margin: 12px 0 -12px 0 !important;
  line-height: 1.31;
  color: var(--form-text-color);
}

.signalsTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  margin: 32px 0 24px 0;
  color: var(--form-text-color);
}

div.customPhoneInput>div>i {
  color: var(--form-arrow-color) !important;
  font-size: 16px !important;
}

.disclaimerButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--secondary-color-palette);
}

.disclaimer {
  font-size: 14px;
  line-height: 1.33;
}

.disclaimerRow {
  flex-direction: row;
}

.disclaimer i {
  margin-left: 0.5rem;
  padding-top: 1rem;
  cursor: pointer;
}

.disclaimerCheckBox {
  max-width: fit-content;
  margin-right: 0rem;
}

.expContent {
  font-size: 12px;
  line-height: 1.33;
  padding-top: 10px;
}

.expContent ul {
  margin-top: 8px;
  list-style-type: disc;
}

.expContent li {
  margin-left: 16px;
}

.expContent a {
  color: var(--secondary-color-palette);
}

.cta {
  margin-top: 12px;
  text-align: center;
}

.signalsForm .cta button {
  min-width: 150px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  padding: 6px 16px;
  font-family: 'Lexend', sans-serif;
}

.errorSubmit {
  color: var(--form-text-error-color);
  display: block;
  margin-top: 20px;
}

div.customFieldRow {
  margin: 0 -6px;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 769px) {
  .signalsTitle {
    font-size: 32px;
    text-align: center;
  }

  .language {
    flex-direction: row;
    flex-wrap: wrap;
  }

  div.language label {
    flex: 0 1 50%;
    min-height: 56px !important;
    margin-top: -1px;
  }

  div.language label:first-child {
    border-bottom-left-radius: 0 !important;
  }

  div.language label:nth-child(2) {
    border-top-right-radius: 4px !important;
  }

  div.language label:nth-child(3) {
    border-bottom-left-radius: 4px !important;
  }

  div.language label:last-child {
    border-top-right-radius: 0 !important;
  }
}

@media screen and (min-width: 993px) {
  .action {
    display: block;
  }

  .signalsTitle {
    font-size: 40px;
  }
}

@media screen and (min-width: 1280px) {
  .action {
    flex: 1 0 600px;
  }

  div.customFieldRow {
    flex-direction: row;
    flex-wrap: wrap;
  }
}