export const TRANSLATIONS_IT = {
  screens: {
    seminar: {
      formTitle: "Partecipa gratuitamente",
      firstName: "Nome",
      lastName: "Cognome",
      email: "Indirizzo e-mail",
      country: "Paese di residenza",
      canton: "Cantone",
      phoneNr: "Numero di telefono",
      emailRequired: "Si prega di inserire un indirizzo e-mail valido",
      phoneRequired: "Solo in formato numerico, ad es. 7812234567",
      gtcAgree:
        "Inviando la registrazione a un evento accetto il <Disclaimer>disclaimer</Disclaimer>.",
      gtcAgreeDescCh:
        "Inviando una richiesta di registrazione a un evento accetto quanto segue: <B><S>Cornèr Banca SA è una banca con licenza svizzera che non è autorizzata dalle autorità di regolamentazione di altri Paesi.</S><S>Il modulo d’iscrizione non costituisce una sollecitazione attiva di servizi bancari e/o finanziari e/o prodotti finanziari nei confronti di residenti in Paesi esteri.</S><S>Accetto di essere contattato/a da Cornèr Banca SA telefonicamente o via e-mail al fine di ricevere ulteriori informazioni in merito alle piattaforme di trading, alle loro funzionalità e ai prodotti e servizi connessi all’operatività delle piattaforme.</S><S>Per lʼiscrizione ai webinar, il disclaimer completo è disponibile <Here>qui</Here>.</S></B>",
      gtcAgreeDescCom:
        "Inviando una richiesta di registrazione a un evento accetto quanto segue: <B><S>Cornèr Banca SA è una banca con licenza svizzera che non è autorizzata dalle autorità di regolamentazione di altri Paesi.</S><S>Il modulo d’iscrizione non costituisce una sollecitazione attiva di servizi bancari e/o finanziari e/o prodotti finanziari nei confronti di residenti in Paesi esteri.</S><S>Accetto di essere contattato/a da Cornèr Banca SA telefonicamente o via e-mail al fine di ricevere ulteriori informazioni in merito alle piattaforme di trading, alle loro funzionalità e ai prodotti e servizi connessi all’operatività delle piattaforme.</S><S>Per lʼiscrizione ai webinar, il disclaimer completo è disponibile <Here>qui</Here>.</S></B>",
      submit: "Iscriviti ora",
      seats: {
        No: "Niente posti",
        Few: "Pochi posti rimasti!",
        Available: "Posti disponibili",
      },
    },
    demo: {
      pageTitle: "Iscriviti alla demo gratuita",
      firstName: "Nome",
      surName: "Cognome",
      email: "Indirizzo e-mail",
      country: "Paese di residenza",
      canton: "Cantone",
      phoneNr: "Numero di telefono",
      emailRequired: "Si prega di inserire un indirizzo e-mail valido",
      phoneRequired: "Solo in formato numerico, ad es. 7812234567",
      gtcAgree:
        "Iscrivendomi ad un conto demo gratuito, accetto il <Disclaimer>disclaimer</Disclaimer>.",
      gtcAgreeDescCh:
        "Inviando la richiesta del conto demo, accetto di essere informato e confermo quanto segue: <B><S>Cornèr Banca SA è una banca con licenza svizzera che non è autorizzata dalle autorità di regolamentazione di altri paesi.</S><S>Il modulo di richiesta del conto demo non costituisce una sollecitazione attiva di servizi bancari e/o finanziari e/o prodotti finanziari nei confronti di residenti in Paesi esteri.</S><S>Confermo di accedere a questo conto demo di mia spontanea iniziativa.</S><S>Accetto di essere contattato/a da Cornèr Banca SA telefonicamente o via e-mail al fine di ricevere ulteriori informazioni in merito alle piattaforme di trading, alle loro funzionalità e ai prodotti e servizi connessi all’operatività delle piattaforme.</S></B>",
      gtcAgreeDescCom:
        "Inviando la richiesta del conto demo, accetto di essere informato e confermo quanto segue: <B><S>Cornèr Banca SA è una banca con licenza svizzera che non è autorizzata dalle autorità di regolamentazione di altri paesi.</S><S>Il modulo di richiesta del conto demo non costituisce una sollecitazione attiva di servizi bancari e/o finanziari e/o prodotti finanziari nei confronti di residenti in Paesi esteri.</S><S>Confermo di accedere a questo conto demo di mia spontanea iniziativa.</S><S>Accetto di essere contattato/a da Cornèr Banca SA telefonicamente o via e-mail al fine di ricevere ulteriori informazioni in merito alle piattaforme di trading, alle loro funzionalità e ai prodotti e servizi connessi all’operatività delle piattaforme.</S></B>",
      submit: "Iscriviti ora",
    },
    contactB2B: {
      formTitle: "Contattaci",
      formSubTitle:
        "I nostri Relationship Managers sono lieti di rispondere alle tue domande.",
      firstName: "Nome",
      lastName: "Cognome",
      company: "Azienda",
      position: "Ruolo/Posizione",
      email: "Indirizzo e-mail",
      country: "Paese di residenza",
      canton: "Cantone",
      phoneNr: "Numero di telefono",
      emailRequired: "Si prega di inserire un indirizzo e-mail valido",
      phoneRequired: "Solo in formato numerico, ad es. 7812234567",
      notes: "Messaggio",
      disclaimerCh:
        "Inviando la mia richiesta di contatto, senza ulteriori obblighi, a un rappresentante di Cornèr Banca SA accetto quanto segue: <B><S>Cornèr Banca SA è una banca con licenza svizzera che non è autorizzata dalle autorità di regolamentazione di altri Paesi;</S><S>Il modulo d’iscrizione non costituisce una sollecitazione attiva di servizi bancari e/o finanziari e/o prodotti finanziari nei confronti di residenti in Paesi esteri;</S><S>Accetto di essere contattato/a da Cornèr Banca SA telefonicamente o via e-mail al fine di ricevere ulteriori informazioni sulle piattaforme di investimento, sulle loro funzionalità, sui prodotti e sui servizi connessi all’operatività delle piattaforme.</S></B>",
      disclaimerCom:
        "Inviando la mia richiesta di contatto, senza ulteriori obblighi, a un rappresentante di Cornèr Banca SA accetto quanto segue: <B><S>Cornèr Banca SA è una banca con licenza svizzera che non è autorizzata dalle autorità di regolamentazione di altri Paesi;</S><S>Il modulo d’iscrizione non costituisce una sollecitazione attiva di servizi bancari e/o finanziari e/o prodotti finanziari nei confronti di residenti in Paesi esteri;</S><S>Accetto di essere contattato/a da Cornèr Banca SA telefonicamente o via e-mail al fine di ricevere ulteriori informazioni sulle piattaforme di investimento, sulle loro funzionalità, sui prodotti e sui servizi connessi all’operatività delle piattaforme.</S></B>",
      submit: "Invia",
    },
    contactB2C: {
      formTitle: "Contattaci",
      formSubTitle:
        "I nostri Relationship Managers sono lieti di rispondere alle tue domande.",
      alreadyAccount: "Ho già un conto",
      newAccount: "Non ho ancora un conto",
      firstName: "Nome",
      lastName: "Cognome",
      email: "Indirizzo e-mail",
      country: "Paese di residenza",
      canton: "Cantone",
      phoneNr: "Numero di telefono",
      emailRequired: "Si prega di inserire un indirizzo e-mail valido",
      phoneRequired: "Solo in formato numerico, ad es. 7812234567",
      notes: "Messaggio",
      topic: "Scegli un tema",
      disclaimerCh:
        "Inviando la mia richiesta di contatto a un rappresentante di Cornèr Banca SA e senza ulteriori obblighi, accetto quanto segue: <B><S>Cornèr Banca SA è una banca con licenza svizzera che non è autorizzata dalle autorità di regolamentazione di altri Paesi.</S><S>Il modulo d’iscrizione non costituisce una sollecitazione attiva di servizi bancari e/o finanziari e/o prodotti finanziari nei confronti di residenti in Paesi esteri.</S><S>Accetto di essere contattato/a da Cornèr Banca SA telefonicamente o via e-mail al fine di ricevere ulteriori informazioni sulle piattaforme di investimento, sulle loro funzionalità, sui prodotti e sui servizi connessi all’operatività delle piattaforme.</S></B>",
      disclaimerCom:
        "Inviando la mia richiesta di contatto a un rappresentante di Cornèr Banca SA e senza ulteriori obblighi, accetto quanto segue: <B><S>Cornèr Banca SA è una banca con licenza svizzera che non è autorizzata dalle autorità di regolamentazione di altri Paesi.</S><S>Il modulo d’iscrizione non costituisce una sollecitazione attiva di servizi bancari e/o finanziari e/o prodotti finanziari nei confronti di residenti in Paesi esteri.</S><S>Accetto di essere contattato/a da Cornèr Banca SA telefonicamente o via e-mail al fine di ricevere ulteriori informazioni sulle piattaforme di investimento, sulle loro funzionalità, sui prodotti e sui servizi connessi all’operatività delle piattaforme.</S></B>",
      submit: "Invia",
      message: "Messaggio",
    },
    meeting: {
      formTitle: "Prenota un incontro <B>one-to-one</B>",
      formSubTitle:
        "I nostri relationship manager saranno lieti di <B>assisterti</B> di persona e online.",
      phone: "Telefonicamente",
      face: "Di persona",
      location: "In sede",
      date: "Data",
      errorDate: "Error Date",
      time: "Scegli una fascia oraria",
      allTime: "Tutte le fasce orarie",
      firstName: "Nome",
      lastName: "Cognome",
      email: "Indirizzo e-mail",
      country: "Paese di residenza",
      canton: "Cantone",
      phoneNr: "Numero di telefono",
      emailRequired: "Si prega di inserire un indirizzo e-mail valido",
      phoneRequired: "Solo in formato numerico, ad es. 7812234567",
      noteLanguage:
        "Lasciaci un messaggio se desideri richiedere un’altra lingua.",
      notes: "Hai domande o suggerimenti? Lasciaci un messaggio.",
      gtcAgree:
        "Richiedendo di essere contattato/a accetto espressamente il <Disclaimer>disclaimer</Disclaimer>.",
      gtcAgreeDescCh:
        "Inviando la mia richiesta di contatto, senza ulteriori obblighi, a uno/una rappresentante di Cornèr Banca SA accetto quanto segue: <B><S>Cornèr Banca SA è una banca con licenza svizzera che non è autorizzata dalle autorità di regolamentazione di altri Paesi.</S><S>Il modulo di contatto non costituisce una sollecitazione attiva di servizi bancari e/o finanziari e/o prodotti finanziari nei confronti di residenti in Paesi esteri.</S><S>Accetto di essere contattato/a da Cornèr Banca SA telefonicamente o via e-mail al fine di ricevere ulteriori informazioni in merito alle piattaforme di trading, alle loro funzionalità e ai prodotti e i servizi connessi all’operatività delle piattaforme.</S></B>",
      gtcAgreeDescCom:
        "Inviando la mia richiesta di contatto, senza ulteriori obblighi, a uno/una rappresentante di Cornèr Banca SA accetto quanto segue: <B><S>Cornèr Banca SA è una banca con licenza svizzera che non è autorizzata dalle autorità di regolamentazione di altri Paesi.</S><S>Il modulo di contatto non costituisce una sollecitazione attiva di servizi bancari e/o finanziari e/o prodotti finanziari nei confronti di residenti in Paesi esteri.</S><S>Accetto di essere contattato/a da Cornèr Banca SA telefonicamente o via e-mail al fine di ricevere ulteriori informazioni in merito alle piattaforme di trading, alle loro funzionalità e ai prodotti e i servizi connessi all’operatività delle piattaforme.</S></B>",
      submit: "Prenota un incontro",
      preferences: "Definisci le tue preferenze",
      contact: "I tuoi dati di contatto",
      language: "Lingua",
      message: "Messaggio",
      messageSubmit: "Per favore, compila tutti i campi per poter procedere",
    },
    signals: {
      pageTitle: "Notizie di mercato quotidiane",
      firstName: "Nome",
      surName: "Cognome",
      email: "Indirizzo e-mail",
      country: "Paese di residenza",
      canton: "Cantone",
      phoneNr: "Numero di telefono",
      emailRequired: "Si prega di inserire un indirizzo e-mail valido",
      phoneRequired: "Solo in formato numerico, ad es. 7812234567",
      language: "Lingua preferita",
      gtcAgree:
        "Iscrivendomi alla newsletter accetto il <Disclaimer>disclaimer</Disclaimer>.",
      gtcAgreeDescCh:
        "Iscrivendoti alle nostre ricerche di mercato e al nostro servizio di segnali di investimento, accetti espressamente di ricevere e-mail da parte di Cornèrtrader. Tali e-mail non contengono alcuna consulenza fiscale, finanziaria o d’investimento. Sono intese unicamente a fornire informazioni generali e non devono in alcun modo essere considerate come una forma di consulenza, di sollecitazione o incentivo a realizzare investimenti di alcun tipo. Di conseguenza, Cornèrtrader non potrà essere ritenuta responsabile di eventuali perdite che potresti subire in seguito a scelte d’investimento effettuate in relazione alle informazioni fornite in tali e-mail. Clicca <Click>qui</Click> per leggere il disclaimer completo.",
      gtcAgreeDescCom:
        "Iscrivendoti alle nostre ricerche di mercato e al nostro servizio di segnali di investimento, accetti espressamente di ricevere e-mail da parte di Cornèrtrader. Tali e-mail non contengono alcuna consulenza fiscale, finanziaria o d’investimento. Sono intese unicamente a fornire informazioni generali e non devono in alcun modo essere considerate come una forma di consulenza, di sollecitazione o incentivo a realizzare investimenti di alcun tipo. Di conseguenza, Cornèrtrader non potrà essere ritenuta responsabile di eventuali perdite che potresti subire in seguito a scelte d’investimento effettuate in relazione alle informazioni fornite in tali e-mail. Clicca <Click>qui</Click> per leggere il disclaimer completo.",
      submit: "Iscriviti ora",
    },
  },
  success: {
    seminar: {
      pageTitle: "Scopri la demo gratuita della nostra piattaforma",
      pageSubtitle:
        "La tua iscrizione è andata a buon fine! <br>Riceverai tutti i dettagli via e-mail.",
      tryButton: "Prova la demo",
    },
    demo: {
      pageTitleFormSubmitted: "Grazie di esserti iscritto!",
      pageTitleCountryNotAllowed: "Ci dispiace",
      pageTitleCountryAlmostAllowed: "Ci dispiace",
      pageSubtitleFormSubmitted:
        "Riceverai informazioni quotidiane su ricerche di mercato e segnali di investimento.",
      pageSubtitleCountryNotAllowed:
        "Al momento non possiamo fornire questo servizio nella tua nazione di residenza.",
      pageSubtitleCountryAlmostAllowed:
        "Al momento non possiamo fornire il servizio, in questa modalità, nella tua nazione di residenza. <br><br>Ti contatteremo al più presto.",
      pageTitleCredential: "Grazie di esserti iscritto!",
      pageSubtitleCredential:
        "Abbiamo inviato le credenziali di accesso alla demo gratuita a <Value>{{contactMail}}</Value>",
      credential: "Usa queste credenziali",
      userId: "La tua User ID: <Value>{{userId}}</Value>",
      password: "La tua Password: <Value>{{password}}</Value>",
      closeButton: "Chiudere",
    },
    contactB2B: {
      pageTitle: "Sarai presto contattato",
      pageSubtitle:
        "Grazie per la tua richiesta. Il tuo consulente ti contatterà a breve.",
      checkPlatform: "Visita le nostre piattaforme",
      platforms: "piattaforme",
    },
    contactB2C: {
      pageTitle: "Sarai presto contattato",
      pageSubtitle:
        "Grazie per la tua richiesta. Il tuo consulente ti contatterà a breve.",
      checkPlatform: "Visita le nostre piattaforme",
      platforms: "piattaforme",
    },
    meeting: {
      pageTitle: "Sarai presto contattato",
      pageSubtitle:
        "Grazie per la tua richiesta. Il tuo consulente ti contatterà a breve.",
      checkPlatform: "Visita le nostre piattaforme",
      platforms: "piattaforme",
    },
    signals: {
      pageTitleFormSubmitted: "Grazie di esserti iscritto",
      pageTitleCountryNotAllowed: "Ci dispiace",
      pageTitleCountryAlmostAllowed: "Ci dispiace",
      pageSubtitleFormSubmitted:
        "Riceverai informazioni quotidiane su ricerche di mercato e segnali di investimento.",
      pageSubtitleCountryNotAllowed:
        "Al momento non possiamo fornire questo servizio nella tua nazione di residenza.",
      pageSubtitleCountryAlmostAllowed:
        "Al momento non possiamo fornire il servizio, in questa modalità, nella tua nazione di residenza. <br/><br/>Ti contatteremo al più presto.",
      closeButton: "Chiudere",
      ocmsButton: "La tua iscrizione è andata a buon fine.",
    },
  },
  errors: {
    input: {
      invalidPattern: "Formato non valido",
      minLength: "Sono necessari almeno {{min}} caratteri",
      maxLength: "È consentito un massimo di {{max}} caratteri",
      minPhoneLength:
        "Il numero di telefono deve essere composto da almeno {{min}} cifre.",
      required: "Campo obbligatorio",
      mismatch: "{{fieldName}} non corrisponde",
    },
    submit: "Purtroppo si è verificato un problema tecnico.",
    contactFormSuggestion:
      "Siamo lieti di aiutarti – contattaci tramite il <contactLink><strong>formulario online</strong></contactLink>",
  },
  district: {
    AG: "Aargau",
    AI: "Appenzell Innerrhoden",
    AR: "Appenzell Ausserrhoden",
    BS: "Basel-Stadt",
    BL: "Basel-Landschaft",
    BE: "Bern",
    FR: "Fribourg",
    GE: "Geneva",
    GL: "Glarus",
    GR: "Graub\u00fcnden",
    JU: "Jura",
    LU: "Luzern",
    NE: "Neuch\u00e2tel",
    NW: "Nidwalden",
    OW: "Obwalden",
    SH: "Schaffhausen",
    SZ: "Schwyz",
    SO: "Solothurn",
    SG: "St. Gallen",
    TG: "Thurgau",
    TI: "Ticino",
    UR: "Uri",
    VS: "Valais",
    VD: "Vaud",
    ZG: "Zug",
    ZH: "Z\u00fcrich",
  },
  location: {
    online: "Tutte le sedi",
    zurich: "Zurigo",
    geneva: "Ginevra",
    lugano: "Lugano",
  },
  language: {
    en: "Inglese",
    de: "Tedesco",
    it: "Italiano",
    fr: "Francese",
  },
  topic: {
    a: "Richieste su ordini o posizioni",
    b: "Problemi tecnici o back-office",
    c: "Apertura conto",
    d: "Informazioni su condizioni e commissioni",
    e: "Formazione",
    f: "Altro",
  },
  captchaDisclaimer:
    "Questo sito è protetto da reCAPTCHA e si applicano le <br/> <0>norme sulla privacy</0> e i <1>termini di servizio</1> di Google.",
};
