import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  FieldGroup,
  FieldRow,
  FieldSet,
  Input,
  Radio,
  SelectBox,
  TextArea,
} from "react-corner-ds";
import { Controller, FieldError, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { definePatternByName } from "../../utils/constants";
import { ContactB2CFormInfo } from "../../models/request/data/data.model";
import { CountryItem, DataAttribute } from "../../models/utils.model";
import { cantonItems, topicItems } from "../../assets/locales/utils";
import { countriesDe } from "../../assets/countries/de";
import { countriesEn } from "../../assets/countries/en";
import { countriesFr } from "../../assets/countries/fr";
import { countriesIt } from "../../assets/countries/it";
import { countriesCom } from "../../assets/countries/com";
import { Request } from "../../models/request/request.model";
import { useFlow } from "../../hooks/useFlow/useFlow";
import style from "./CtWidgetContactB2C.module.css";
import CtWidgetContactB2CSuccess from "../successComponents/ctWidgetContactB2C/CtWidgetContactB2CSuccess";
import GooglCaptchaDisclaimer from "../googleCaptchaDisclaimer/googleCaptchaDisclaimer";
import { EmailValidator } from "commons-validator-js";

interface ICtWidgetContactB2C {
  dataAttribute: DataAttribute;
}

const CtWidgetContactB2C: React.FC<ICtWidgetContactB2C> = ({
  dataAttribute,
}) => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [isChCountry, setIsChCountry] = useState<boolean>(false);
  const [stepForm, setStepForm] = useState<string>("contactB2CForm");
  const [isError, setIsError] = useState<boolean>(false);
  const [isAlreadyAccount, setIsAlreadyAccount] = useState<boolean>(true);
  const [isChSite, setIsChSite] = useState<boolean>(false);

  const { callSubscribe } = useFlow();

  const selectedLang = useMemo(
    () => i18n.resolvedLanguage,
    [i18n.resolvedLanguage]
  );

  // set trk Adobe Analytics
  const buttonSubmit = document.getElementsByClassName("trkContactB2C");
  buttonSubmit[0]?.setAttribute("data-trk-btn-click", "submit-contactb2c");

  const countries = useMemo(() => {
    switch (selectedLang) {
      case "de":
        return countriesDe;

      case "fr":
        return countriesFr;

      case "it":
        return countriesIt;

      default:
        return isChSite ? countriesEn : countriesCom;
    }
  }, [isChSite, selectedLang]);

  const validity = useCallback(
    (error: FieldError | undefined, isTouched: boolean) =>
      !error && isTouched ? true : undefined,
    []
  );

  const countryItems = useMemo(() => {
    return countries.map((item: CountryItem) => ({
      label: [item.name],
      value: item.alpha_2,
      icon: item.alpha_2,
    }));
  }, [countries]);

  const prefixItems = useMemo(() => {
    return countries.map((item: CountryItem) => ({
      label: [item.name, "+" + item.phone_code],
      value: "+" + item.phone_code,
      icon: item.alpha_2,
    }));
  }, [countries]);

  const { handleSubmit, formState, control, setValue } =
    useForm<ContactB2CFormInfo>({
      mode: "all",
      reValidateMode: "onChange",
      defaultValues: {
        AlreadyCustomer: true,
        FirstName: "",
        LastName: "",
        Email: "",
        Phones: [
          {
            CountryCode: "",
            LocalNumber: "",
            Type: "Mobile",
          },
        ],
        Addresses: [
          {
            Type: "Home",
            Country: "",
            Canton: "",
          },
        ],
        Notes: "",
      },
    });

  useEffect(() => {
    const hostName = window.location.host;
    if (hostName.includes("cornertrader.ch")) {
      setIsChSite(true);
      setValue("Phones.0.CountryCode", "+41");
    } else {
      setIsChSite(false);
      setValue("Phones.0.CountryCode", "+1242");
    }
  }, [setValue]);

  const submitHandler = async (data: ContactB2CFormInfo) => {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth =
      currentDate.getMonth() + 1 < 10
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1;
    let currentDay =
      currentDate.getDate() < 10
        ? "0" + currentDate.getDate()
        : currentDate.getDate();

    let body: Request = {
      Data: {
        CID: dataAttribute.dataWidgetCid,
        Promotion: dataAttribute.dataWidgetPromo,
        Notes: data.Notes,
        Topic: data.Topic,
        Trader: {
          Account: {
            TCAccepted: true,
            TCDate: `${currentYear}${currentMonth}${currentDay}`,
            TCCode: "1",
          },
          Customer: {
            Language: i18n.resolvedLanguage || "en",
            Email: data.Email,
            FirstName: data.FirstName,
            LastName: data.LastName,
            AlreadyCustomer: isAlreadyAccount,
            Phones: [
              {
                CountryCode: data.Phones[0].CountryCode,
                LocalNumber: data.Phones[0].LocalNumber,
                Type: "Mobile",
              },
            ],
            Addresses: [
              {
                Canton: data.Addresses[0].Canton,
                Country: data.Addresses[0].Country,
                Type: "Home",
              },
            ],
          },
        },
      },
      Extra: {
        caseType: "ContactB2C",
      },
    };

    setLoading(true);
    let resp = await callSubscribe(body);

    if (resp?.data.status.success === true) {
      setStepForm(resp.data.status.step || "contactB2CForm");
    } else {
      setIsError(true);
    }
    setLoading(false);
  };

  const alreadyCustomerRadio = useMemo(
    () => (
      <Controller
        control={control}
        name={"AlreadyCustomer"}
        defaultValue={isAlreadyAccount}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({ field: { onChange, name, value } }) => (
          <Radio
            name={name}
            options={[
              {
                text: t`screens.contactB2C.alreadyAccount`,
                _value: "Already",
              },
              {
                text: t`screens.contactB2C.newAccount`,
                _value: "New",
              },
            ]}
            onChange={(ev) => {
              onChange(ev);
              setIsAlreadyAccount(ev === "Already" ? true : false);
            }}
            value={value ? "Already" : "New"}
          />
        )}
      />
    ),
    [control, isAlreadyAccount, t]
  );

  const firstNameInput = useMemo(
    () => (
      <Controller
        control={control}
        name="FirstName"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("FirstName"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            name={name}
            label={t`screens.contactB2C.firstName` || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={60}
            value={value}
          />
        )}
      />
    ),
    [control, t, validity]
  );

  const lastNameInput = useMemo(
    () => (
      <Controller
        control={control}
        name="LastName"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("LastName"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            name={name}
            label={t`screens.contactB2C.lastName` || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={60}
            value={value}
          />
        )}
      />
    ),
    [control, t, validity]
  );

  const validator = useMemo(() => new EmailValidator(), []);

  const emailInput = useMemo(
    () => (
      <Controller
        control={control}
        name="Email"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          validate: (value) => validator.isValid(value),
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            name={name}
            label={t`screens.contactB2C.email` || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            type="email"
            onBlur={onBlur}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={
              error?.message ||
              (!validator.isValid(value) &&
                t`screens.contactB2C.emailRequired`) ||
              ""
            }
            maxLength={60}
            value={value}
          />
        )}
      />
    ),
    [control, t, validator, validity]
  );

  const prefixSelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Phones.0.CountryCode"
        defaultValue={"+41"}
        render={({ field: { onChange, name, value } }) => (
          <SelectBox
            cssClass="prefix"
            value={value}
            previewIndex="1"
            items={prefixItems}
            name={name}
            onChange={(item) => {
              onChange(item.value);
            }}
          />
        )}
      />
    ),
    [control, prefixItems]
  );

  const phoneInput = useMemo(
    () => (
      <Controller
        control={control}
        name="Phones.0.LocalNumber"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`screens.contactB2C.phoneRequired`,
          },
          pattern: {
            value: definePatternByName("Phone"),
            message: t`errors.input.invalidPattern`,
          },
          minLength: {
            value: 7,
            message: t(`errors.input.minPhoneLength`, {
              min: "7",
            }),
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <Input
            type="tel"
            name={name}
            customCssClass={style.customPhoneInput}
            label={t`screens.contactB2C.phoneNr` || ""}
            placeholder="00 000 00 00"
            cssClass="prefixed"
            onBlur={onBlur}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={11}
            value={value}
          >
            {prefixSelectBox}
          </Input>
        )}
      />
    ),
    [control, prefixSelectBox, t, validity]
  );

  const countrySelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Addresses.0.Country"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({
          field: { onChange, name, value, onBlur },
          fieldState: { error },
        }) => (
          <SelectBox
            label={t`screens.contactB2C.country` || ""}
            items={countryItems}
            name={name}
            value={value}
            onChange={(item) => {
              onChange(item.value);
              setIsChCountry(item.value === "CH");
            }}
            onBlur={onBlur}
            invalid={error?.message ? true : false}
            errorMsg={error?.message}
          />
        )}
      />
    ),
    [control, countryItems, t]
  );

  const cantonSelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Addresses.0.Canton"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({
          field: { onChange, name, value, onBlur },
          fieldState: { error },
        }) => (
          <SelectBox
            label={t`screens.contactB2C.canton` || ""}
            items={cantonItems(t)}
            name={name}
            value={value}
            onChange={(item) => {
              onChange(item.value);
            }}
            onBlur={onBlur}
            invalid={error?.message ? true : false}
            errorMsg={error?.message}
          />
        )}
      />
    ),
    [control, t]
  );

  const topicSelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Topic"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({
          field: { onChange, name, value, onBlur },
          fieldState: { error },
        }) => (
          <SelectBox
            label={t`screens.contactB2C.topic` || ""}
            items={topicItems(t)}
            name={name}
            value={value}
            onChange={(item) => {
              onChange(item.value);
            }}
            onBlur={onBlur}
            invalid={error?.message ? true : false}
            errorMsg={error?.message}
          />
        )}
      />
    ),
    [control, t]
  );

  const messageTextArea = useMemo(
    () => (
      <Controller
        control={control}
        name="Notes"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("Message"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <TextArea
            name={name}
            label={t`screens.contactB2C.notes` || ""}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onBlur={onBlur}
            valid={error ? false : validity(error, isTouched)}
            errorMsg={error?.message}
            maxLength={160}
            value={value}
          />
        )}
      />
    ),
    [control, t, validity]
  );

  const disclaimerInfo = useMemo(
    () => (
      <div className={style.disclaimerInfo}>
        <Trans
          i18nKey={
            isChSite
              ? t`screens.contactB2C.disclaimerCh`
              : t`screens.contactB2C.disclaimerCom`
          }
          components={{
            B: <ul />,
            S: <li />,
          }}
        />
      </div>
    ),
    [isChSite, t]
  );

  return stepForm === "FormSubmitted" ? (
    <CtWidgetContactB2CSuccess
      selectedLang={selectedLang}
      hideTitle={dataAttribute.dataWidgetHideTitle || false}
    />
  ) : (
    <div className={style.action}>
      <div className={style.contactB2CForm}>
        {!dataAttribute.dataWidgetHideTitle ? (
          <>
            <h1
              className={style.contactB2CTitle}
            >{t`screens.contactB2C.formTitle`}</h1>
            <h2
              className={style.contactB2CSubTitle}
            >{t`screens.contactB2C.formSubTitle`}</h2>
          </>
        ) : (
          <></>
        )}

        <form autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
          <FieldSet>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{alreadyCustomerRadio}</FieldGroup>
            </FieldRow>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{firstNameInput}</FieldGroup>
              <FieldGroup>{lastNameInput}</FieldGroup>
            </FieldRow>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{emailInput}</FieldGroup>
              <FieldGroup>{phoneInput}</FieldGroup>
            </FieldRow>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{countrySelectBox}</FieldGroup>
              {isChCountry ? (
                <FieldGroup>{cantonSelectBox}</FieldGroup>
              ) : (
                <FieldGroup cssClass="void"> </FieldGroup>
              )}
            </FieldRow>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>
                <h3>{t`screens.contactB2C.message`}</h3>
              </FieldGroup>
            </FieldRow>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{topicSelectBox}</FieldGroup>
              <FieldGroup cssClass="void"> </FieldGroup>
            </FieldRow>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{messageTextArea}</FieldGroup>
            </FieldRow>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{disclaimerInfo}</FieldGroup>
            </FieldRow>
          </FieldSet>
          <div className={style.cta}>
            <Button
              text={t`screens.contactB2C.submit`}
              disabled={!formState.isValid || loading}
              type="submit"
              icon={
                loading
                  ? { icon: "loader", position: "right", spin: true }
                  : undefined
              }
              // set trk Adobe Analytics
              customCssClass="trkContactB2C"
            />
            {isError ? (
              <span className={style.errorSubmit}>{t`errors.submit`}</span>
            ) : (
              <></>
            )}
          </div>
          <GooglCaptchaDisclaimer></GooglCaptchaDisclaimer>
        </form>
      </div>
    </div>
  );
};

export default CtWidgetContactB2C;
