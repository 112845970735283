import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  CheckBox,
  FieldGroup,
  FieldRow,
  FieldSet,
  Input,
  Radio,
  SelectBox,
  TextArea,
} from "react-corner-ds";
import { Controller, FieldError, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { definePatternByName } from "../../utils/constants";
import { MeetingFormInfo } from "../../models/request/data/data.model";
import { CountryItem, DataAttribute } from "../../models/utils.model";
import {
  cantonItems,
  languageItems,
  locationItems,
  timeSlotItems,
} from "../../assets/locales/utils";
import { countriesDe } from "../../assets/countries/de";
import { countriesEn } from "../../assets/countries/en";
import { countriesFr } from "../../assets/countries/fr";
import { countriesIt } from "../../assets/countries/it";
import { countriesCom } from "../../assets/countries/com";
import { Request } from "../../models/request/request.model";
import { useFlow } from "../../hooks/useFlow/useFlow";
import CtWidgetMeetingSuccess from "../successComponents/ctWidgetMeeting/CtWidgetMeetingSuccess";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import style from "./CtWidgetMeeting.module.css";
import moment from "moment";
import GooglCaptchaDisclaimer from "../googleCaptchaDisclaimer/googleCaptchaDisclaimer";
import { EmailValidator } from "commons-validator-js";

interface ICtWidgetMeeting {
  dataAttribute: DataAttribute;
}

const CtWidgetMeeting: React.FC<ICtWidgetMeeting> = ({ dataAttribute }) => {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [isChCountry, setIsChCountry] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [stepForm, setStepForm] = useState<string>("meetingForm");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("en");
  const [selectedMethod, setSelectedMethod] = useState<string>("Phone");
  const [isOpenCalendar, setIsOpenCalendar] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isErrorDate, setIsErrorDate] = useState<boolean | undefined>(
    undefined
  );
  const [isChSite, setIsChSite] = useState<boolean>(false);

  const { callSubscribe } = useFlow();

  const selectedLang = useMemo(
    () => i18n.resolvedLanguage,
    [i18n.resolvedLanguage]
  );

  // set trk Adobe Analytics
  const buttonSubmit = document.getElementsByClassName("trkMeeting");
  buttonSubmit[0]?.setAttribute("data-trk-btn-click", "submit-meeting");

  const countries = useMemo(() => {
    switch (selectedLang) {
      case "de":
        return countriesDe;

      case "fr":
        return countriesFr;

      case "it":
        return countriesIt;

      default:
        return isChSite ? countriesEn : countriesCom;
    }
  }, [isChSite, selectedLang]);

  const validity = useCallback(
    (error: FieldError | undefined, isTouched: boolean) =>
      !error && isTouched ? true : undefined,
    []
  );

  const countryItems = useMemo(() => {
    return countries.map((item: CountryItem) => ({
      label: [item.name],
      value: item.alpha_2,
      icon: item.alpha_2,
    }));
  }, [countries]);

  const prefixItems = useMemo(() => {
    return countries.map((item: CountryItem) => ({
      label: [item.name, "+" + item.phone_code],
      value: "+" + item.phone_code,
      icon: item.alpha_2,
    }));
  }, [countries]);

  const { handleSubmit, formState, control, setValue } =
    useForm<MeetingFormInfo>({
      mode: "all",
      reValidateMode: "onChange",
      defaultValues: {
        Meeting: {
          TimeSlot: "",
          ContactMethod: "Phone",
          Location: "",
        },
        FirstName: "",
        LastName: "",
        Email: "",
        Phones: [
          {
            CountryCode: "",
            LocalNumber: "",
            Type: "Mobile",
          },
        ],
        Addresses: [
          {
            Type: "Home",
            Country: "",
            Canton: "",
          },
        ],
        Notes: "",
      },
    });

  useEffect(() => {
    const hostName = window.location.host;
    if (hostName.includes("cornertrader.ch")) {
      setIsChSite(true);
      setValue("Phones.0.CountryCode", "+41");
    } else {
      setIsChSite(false);
      setValue("Phones.0.CountryCode", "+1242");
    }
  }, [setValue]);

  const submitHandler = async (data: MeetingFormInfo) => {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth =
      currentDate.getMonth() + 1 < 10
        ? "0" + (currentDate.getMonth() + 1)
        : currentDate.getMonth() + 1;
    let currentDay =
      currentDate.getDate() < 10
        ? "0" + currentDate.getDate()
        : currentDate.getDate();

    let body: Request = {
      Data: {
        CID: dataAttribute.dataWidgetCid,
        Promotion: dataAttribute.dataWidgetPromo,
        Notes: data.Notes,
        Meeting: {
          Date: moment(data.Meeting.Date).format("YYYYMMDD"),
          TimeSlot: data.Meeting.TimeSlot,
          ContactMethod: selectedMethod,
          Location: data.Meeting.Location,
        },
        Trader: {
          Account: {
            TCAccepted: data.TCAccepted,
            TCDate: `${currentYear}${currentMonth}${currentDay}`,
            TCCode: "1",
          },
          Customer: {
            Language: selectedLanguage || "en",
            Email: data.Email,
            FirstName: data.FirstName,
            LastName: data.LastName,
            Phones: [
              {
                CountryCode: data.Phones[0].CountryCode,
                LocalNumber: data.Phones[0].LocalNumber,
                Type: "Mobile",
              },
            ],
            Addresses: [
              {
                Canton: data.Addresses[0].Canton,
                Country: data.Addresses[0].Country,
                Type: "Home",
              },
            ],
          },
        },
      },
      Extra: {
        caseType: "Meeting",
      },
    };

    setLoading(true);
    let resp = await callSubscribe(body);

    if (resp?.data.status.success === true) {
      setStepForm(resp.data.status.step || "meetingForm");
    } else {
      setIsError(true);
    }
    setLoading(false);
  };

  // disable past dates
  const today = moment();

  const disablePastDt = useCallback(
    (current: any) => {
      return current.isAfter(today);
    },
    [today]
  );

  useEffect(() => {
    !formState.isValid && setIsError(false);
  }, [formState.isValid]);

  const contactMethodRadio = useMemo(
    () => (
      <Controller
        control={control}
        name={"Meeting.ContactMethod"}
        defaultValue={selectedMethod}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({ field: { onChange, name, value } }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <Radio
              name={name}
              options={[
                {
                  text: t`screens.meeting.phone`,
                  _value: "Phone",
                },
                {
                  text: t`screens.meeting.face`,
                  _value: "Face",
                },
              ]}
              onChange={(ev) => {
                onChange(ev);
                setSelectedMethod(ev);
              }}
              value={value}
            />
          </div>
        )}
      />
    ),
    [control, selectedMethod, t]
  );

  const locationSelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Meeting.Location"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({
          field: { onChange, name, value, onBlur },
          fieldState: { error },
        }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <SelectBox
              label={t`screens.meeting.location` || ""}
              items={locationItems(t)}
              name={name}
              value={value}
              onChange={(item) => {
                onChange(item.value);
              }}
              onBlur={onBlur}
              invalid={error?.message ? true : false}
              errorMsg={error?.message}
            />
          </div>
        )}
      />
    ),
    [control, t]
  );

  const dateInput = useMemo(
    () => (
      <Controller
        control={control}
        name="Meeting.Date"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("DateOfBirth"),
            message: t`errors.input.invalidPattern`,
          },
          validate: (value) =>
            moment(value).isValid() &&
            (moment(value) > moment() ? true : false),
        }}
        render={({
          field: { name, onChange, value, onBlur },
          fieldState: { error },
        }) => (
          <div
            onClick={() => {
              if (!isOpenCalendar) {
                setIsOpenCalendar(true);
                (!value ||
                  !moment(value).isValid() ||
                  moment(value) < moment()) &&
                  setIsErrorDate(true);
              }
            }}
            className={style.customInputCalendar}
          >
            <Input
              name={name}
              label={t`screens.meeting.date` || ""}
              customCssClass={style.customInputCalendar}
              valid={
                (isErrorDate !== undefined && isErrorDate) ||
                !moment(value).isValid() ||
                moment(value) < moment()
                  ? false
                  : true
              }
              onBlur={onBlur}
              errorMsg={error?.message || t`screens.meeting.errorDate` || ""}
              maxLength={0}
              children={
                <>
                  <Datetime
                    value={value}
                    inputProps={{
                      placeholder: "dd/mm/yyyy",
                      maxLength: 10,
                      minLength: 10,
                      className: style.customInput,
                      disabled: false,
                    }}
                    onChange={(e) => {
                      onChange(e);
                      setIsOpenCalendar(false);
                      setIsErrorDate(false);
                    }}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    closeOnClickOutside={true}
                    closeOnSelect={true}
                    className={
                      isOpenCalendar
                        ? `${style.customCalendar} rdtOpen`
                        : style.customCalendar
                    }
                    locale={selectedLang}
                    isValidDate={disablePastDt}
                  />
                  <i
                    className="cticonapp-calendar"
                    onClick={() => setIsOpenCalendar(!isOpenCalendar)}
                  />
                </>
              }
            />
          </div>
        )}
      />
    ),
    [control, disablePastDt, isErrorDate, isOpenCalendar, selectedLang, t]
  );

  const timeSelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Meeting.TimeSlot"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({
          field: { onChange, name, value, onBlur },
          fieldState: { error },
        }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <SelectBox
              label={t`screens.meeting.time` || ""}
              items={timeSlotItems(t)}
              name={name}
              value={value}
              onChange={(item) => {
                onChange(item.value);
              }}
              onBlur={onBlur}
              invalid={error?.message ? true : false}
              errorMsg={error?.message}
            />
          </div>
        )}
      />
    ),
    [control, t]
  );

  const firstNameInput = useMemo(
    () => (
      <Controller
        control={control}
        name="FirstName"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("FirstName"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <Input
              name={name}
              label={t`screens.meeting.firstName` || ""}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onBlur={onBlur}
              valid={error ? false : validity(error, isTouched)}
              errorMsg={error?.message}
              maxLength={60}
              value={value}
            />
          </div>
        )}
      />
    ),
    [control, t, validity]
  );

  const lastNameInput = useMemo(
    () => (
      <Controller
        control={control}
        name="LastName"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("LastName"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <Input
              name={name}
              label={t`screens.meeting.lastName` || ""}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onBlur={onBlur}
              valid={error ? false : validity(error, isTouched)}
              errorMsg={error?.message}
              maxLength={60}
              value={value}
            />
          </div>
        )}
      />
    ),
    [control, t, validity]
  );

  const validator = useMemo(() => new EmailValidator(), []);

  const emailInput = useMemo(
    () => (
      <Controller
        control={control}
        name="Email"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          validate: (value) => validator.isValid(value),
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <Input
              name={name}
              label={t`screens.meeting.email` || ""}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              type="email"
              onBlur={onBlur}
              valid={error ? false : validity(error, isTouched)}
              errorMsg={
                error?.message ||
                (!validator.isValid(value) &&
                  t`screens.meeting.emailRequired`) ||
                ""
              }
              maxLength={60}
              value={value}
            />
          </div>
        )}
      />
    ),
    [control, t, validator, validity]
  );

  const prefixSelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Phones.0.CountryCode"
        defaultValue={"+41"}
        render={({ field: { onChange, name, value } }) => (
          <SelectBox
            cssClass="prefix"
            value={value}
            previewIndex="1"
            items={prefixItems}
            name={name}
            onChange={(item) => {
              onChange(item.value);
            }}
          />
        )}
      />
    ),
    [control, prefixItems]
  );

  const phoneInput = useMemo(
    () => (
      <Controller
        control={control}
        name="Phones.0.LocalNumber"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`screens.meeting.phoneRequired`,
          },
          pattern: {
            value: definePatternByName("Phone"),
            message: t`errors.input.invalidPattern`,
          },
          minLength: {
            value: 7,
            message: t(`errors.input.minPhoneLength`, {
              min: "7",
            }),
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <Input
              type="tel"
              name={name}
              customCssClass={style.customPhoneInput}
              label={t`screens.meeting.phoneNr` || ""}
              placeholder="00 000 00 00"
              cssClass="prefixed"
              onBlur={onBlur}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              valid={error ? false : validity(error, isTouched)}
              errorMsg={error?.message}
              maxLength={11}
              value={value}
            >
              {prefixSelectBox}
            </Input>
          </div>
        )}
      />
    ),
    [control, prefixSelectBox, t, validity]
  );

  const countrySelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Addresses.0.Country"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({
          field: { onChange, name, value, onBlur },
          fieldState: { error },
        }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <SelectBox
              label={t`screens.meeting.country` || ""}
              items={countryItems}
              name={name}
              value={value}
              onChange={(item) => {
                onChange(item.value);
                setIsChCountry(item.value === "CH");
              }}
              onBlur={onBlur}
              invalid={error?.message ? true : false}
              errorMsg={error?.message}
            />
          </div>
        )}
      />
    ),
    [control, countryItems, t]
  );

  const cantonSelectBox = useMemo(
    () => (
      <Controller
        control={control}
        name="Addresses.0.Canton"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({
          field: { onChange, name, value, onBlur },
          fieldState: { error },
        }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <SelectBox
              label={t`screens.meeting.canton` || ""}
              items={cantonItems(t)}
              name={name}
              value={value}
              onChange={(item) => {
                onChange(item.value);
              }}
              onBlur={onBlur}
              invalid={error?.message ? true : false}
              errorMsg={error?.message}
            />
          </div>
        )}
      />
    ),
    [control, t]
  );

  const languageRadio = useMemo(
    () => (
      <Controller
        control={control}
        name={"Language"}
        defaultValue={selectedLang}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({ field: { onChange, name, value } }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <Radio
              name={`Meeting-${name}`}
              options={languageItems(t)}
              customCssClass={style.language}
              onChange={(ev) => {
                onChange(ev);
                setSelectedLanguage(ev);
              }}
              value={value}
            />
          </div>
        )}
      />
    ),
    [control, selectedLang, t]
  );

  const messageTextArea = useMemo(
    () => (
      <Controller
        control={control}
        name="Notes"
        defaultValue={""}
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
          pattern: {
            value: definePatternByName("Message"),
            message: t`errors.input.invalidPattern`,
          },
        }}
        render={({
          field: { onChange, onBlur, name, value },
          fieldState: { isTouched, error },
        }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <TextArea
              name={name}
              placeholder={t`screens.meeting.notes` || ""}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              onBlur={onBlur}
              valid={error ? false : validity(error, isTouched)}
              errorMsg={error?.message}
              maxLength={160}
              value={value}
            />
          </div>
        )}
      />
    ),
    [control, t, validity]
  );

  const toggleContent = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const disclaimerInput = useMemo(
    () => (
      <Controller
        control={control}
        name="TCAccepted"
        rules={{
          required: {
            value: true,
            message: t`errors.input.required`,
          },
        }}
        render={({ field: { onChange, name } }) => (
          <div onClick={() => setIsOpenCalendar(false)}>
            <FieldRow customCssClass={style.disclaimerRow}>
              <FieldGroup customCssClass={style.disclaimerCheckBox}>
                <CheckBox
                  name={name}
                  onChange={(ev) => {
                    onChange(ev.target.checked);
                  }}
                />
              </FieldGroup>
              <FieldGroup customCssClass={style.disclaimer}>
                <Trans
                  i18nKey={t`screens.meeting.gtcAgree`}
                  components={{
                    Disclaimer: (
                      <button
                        type="button"
                        className={style.disclaimerButton}
                        onClick={toggleContent}
                      />
                    ),
                  }}
                />
                <i
                  className={
                    isExpanded
                      ? "cticonapp-collapse-arrow"
                      : "cticonapp-expand-arrow"
                  }
                  onClick={toggleContent}
                />
                {isExpanded && (
                  <div className={style.expContent}>
                    <Trans
                      i18nKey={
                        isChSite
                          ? t`screens.meeting.gtcAgreeDescCh`
                          : t`screens.meeting.gtcAgreeDescCom`
                      }
                      components={{
                        B: <ul />,
                        S: <li />,
                      }}
                    />
                  </div>
                )}
              </FieldGroup>
            </FieldRow>
          </div>
        )}
      />
    ),
    [control, isChSite, isExpanded, t, toggleContent]
  );

  return stepForm === "FormSubmitted" ? (
    <CtWidgetMeetingSuccess
      selectedLang={selectedLang}
      hideTitle={dataAttribute.dataWidgetHideTitle || false}
    />
  ) : (
    <div className={style.action}>
      <div className={style.meetingForm}>
        {!dataAttribute.dataWidgetHideTitle ? (
          <>
            <h1 className={style.meetingTitle}>
              <Trans
                i18nKey={t`screens.meeting.formTitle`}
                components={{
                  B: <span className={style.hlight} />,
                }}
              />
            </h1>
            <h2 className={style.meetingSubTitle}>
              <Trans
                i18nKey={t`screens.meeting.formSubTitle`}
                components={{
                  B: <strong />,
                }}
              />
            </h2>
          </>
        ) : (
          <></>
        )}

        <form autoComplete="off" onSubmit={handleSubmit(submitHandler)}>
          <FieldSet>
            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>
                <h3>{t`screens.meeting.preferences`}</h3>
              </FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{contactMethodRadio}</FieldGroup>
            </FieldRow>

            {selectedMethod === "Face" ? (
              <FieldRow
                customCssClass={
                  dataAttribute.halfContainer ? style.customFieldRow : ""
                }
              >
                <FieldGroup>{locationSelectBox}</FieldGroup>
                <FieldGroup cssClass="void"> </FieldGroup>
              </FieldRow>
            ) : (
              <></>
            )}

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{dateInput}</FieldGroup>
              <FieldGroup>{timeSelectBox}</FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>
                <h3>{t`screens.meeting.contact`}</h3>
              </FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{firstNameInput}</FieldGroup>
              <FieldGroup>{lastNameInput}</FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{emailInput}</FieldGroup>
              <FieldGroup>{phoneInput}</FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{countrySelectBox}</FieldGroup>
              {isChCountry ? (
                <FieldGroup>{cantonSelectBox}</FieldGroup>
              ) : (
                <FieldGroup cssClass="void"> </FieldGroup>
              )}
            </FieldRow>

            {isChSite && (
              <>
                <FieldRow
                  customCssClass={
                    dataAttribute.halfContainer ? style.customFieldRow : ""
                  }
                >
                  <FieldGroup>
                    <h3>{t`screens.meeting.language`}</h3>
                  </FieldGroup>
                </FieldRow>

                <FieldRow
                  customCssClass={
                    dataAttribute.halfContainer ? style.customFieldRow : ""
                  }
                >
                  <FieldGroup>
                    {isChSite && languageRadio}
                    {/* <div
                className={style.noteLanguage}
              >{t`screens.meeting.noteLanguage`}</div> */}
                  </FieldGroup>
                </FieldRow>
              </>
            )}

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>
                <h3>{t`screens.meeting.message`}</h3>
              </FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{messageTextArea}</FieldGroup>
            </FieldRow>

            <FieldRow
              customCssClass={
                dataAttribute.halfContainer ? style.customFieldRow : ""
              }
            >
              <FieldGroup>{disclaimerInput}</FieldGroup>
            </FieldRow>
          </FieldSet>
          <div className={style.cta}>
            <Button
              text={t`screens.meeting.submit`}
              disabled={!formState.isValid || loading}
              type="submit"
              icon={
                loading
                  ? { icon: "loader", position: "right", spin: true }
                  : undefined
              }
              // set trk Adobe Analytics
              customCssClass="trkMeeting"
            />
            {!formState.isValid ? (
              <span className={style.messageSubmit}>
                {t`screens.meeting.messageSubmit`}
              </span>
            ) : isError ? (
              <span className={style.errorSubmit}>{t`errors.submit`}</span>
            ) : (
              <></>
            )}
          </div>
          <GooglCaptchaDisclaimer></GooglCaptchaDisclaimer>
        </form>
      </div>
    </div>
  );
};

export default CtWidgetMeeting;
