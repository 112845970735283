import { useCallback } from "react";
import { useAppSelector } from "../../redux/hooks";

export const useLogger = () => {
  const logEnabled = useAppSelector((state) => state.appConf.logEnabled);
  const currentStep = "Form";

  const log = useCallback(
    (
      type: "error" | "warn" | "log",
      fileName: string,
      msg: string,
      data?: any
    ) => {
      if (logEnabled === "false") return;

      let log = currentStep
        ? `%c[${currentStep} - ${fileName}]%c --- ${msg}`
        : `%c[${fileName}]%c --- ${msg}`;

      let style1 = "color: blue";
      let style2 =
        type === "error"
          ? "color: red"
          : type === "warn"
          ? "color: orange"
          : "color: black";

      data
        ? console.log(log, style1, style2, data)
        : console.log(log, style1, style2);
    },
    [logEnabled]
  );

  const logError = useCallback(
    (fileName: string, msg: string, data?: any) => {
      if (logEnabled === "false") return;

      let log = currentStep
        ? `[${currentStep} - ${fileName}] --- ${msg}`
        : `[${fileName}] --- ${msg}`;

      data ? console.error(log, data) : console.error(log);
    },
    [logEnabled]
  );

  const logWarn = useCallback(
    (fileName: string, msg: string, data?: any) => {
      if (logEnabled === "false") return;

      let log = currentStep
        ? `[${currentStep} - ${fileName}] --- ${msg}`
        : `[${fileName}] --- ${msg}`;

      data ? console.warn(log, data) : console.warn(log);
    },
    [logEnabled]
  );

  return { log, logError, logWarn };
};
