export const cantonItems = (t: any) => [
  {
    label: t`district.AG`,
    value: "AG",
  },
  {
    label: t`district.AI`,
    value: "AI",
  },
  {
    label: t`district.AR`,
    value: "AR",
  },
  {
    label: t`district.BS`,
    value: "BS",
  },
  {
    label: t`district.BL`,
    value: "BL",
  },
  {
    label: t`district.BE`,
    value: "BE",
  },
  {
    label: t`district.FR`,
    value: "FR",
  },
  {
    label: t`district.GE`,
    value: "GE",
  },
  {
    label: t`district.GL`,
    value: "GL",
  },
  {
    label: t`district.GR`,
    value: "GR",
  },
  {
    label: t`district.JU`,
    value: "JU",
  },
  {
    label: t`district.LU`,
    value: "LU",
  },
  {
    label: t`district.NE`,
    value: "NE",
  },
  {
    label: t`district.NW`,
    value: "NW",
  },
  {
    label: t`district.OW`,
    value: "OW",
  },
  {
    label: t`district.SH`,
    value: "SH",
  },
  {
    label: t`district.SZ`,
    value: "SZ",
  },
  {
    label: t`district.SO`,
    value: "SO",
  },
  {
    label: t`district.SG`,
    value: "SG",
  },
  {
    label: t`district.TG`,
    value: "TG",
  },
  {
    label: t`district.TI`,
    value: "TI",
  },
  {
    label: t`district.UR`,
    value: "UR",
  },
  {
    label: t`district.VS`,
    value: "VS",
  },
  {
    label: t`district.VD`,
    value: "VD",
  },
  {
    label: t`district.ZG`,
    value: "ZG",
  },
  {
    label: t`district.ZH`,
    value: "ZH",
  },
];

export const locationItems = (t: any) => [
  {
    label: t`location.lugano`,
    value: "Lugano",
  },
  {
    label: t`location.zurich`,
    value: "Zurich",
  },
  {
    label: t`location.geneva`,
    value: "Geneva",
  },
  {
    label: t`location.online`,
    value: "Online",
  },
];

export const languageItems = (t: any) => [
  {
    text: t`language.en`,
    _value: "en",
  },
  {
    text: t`language.de`,
    _value: "de",
  },
  {
    text: t`language.it`,
    _value: "it",
  },
  {
    text: t`language.fr`,
    _value: "fr",
  },
];

export const topicItems = (t: any) => [
  {
    label: t`topic.a`,
    value: "a",
  },
  {
    label: t`topic.b`,
    value: "b",
  },
  {
    label: t`topic.c`,
    value: "c",
  },
  {
    label: t`topic.d`,
    value: "d",
  },
  {
    label: t`topic.e`,
    value: "e",
  },
  {
    label: t`topic.f`,
    value: "f",
  },
];

export const timeSlotItems = (t: any) => [
  {
    label: "09:00 - 10:30",
    value: "09:00 - 10:30",
  },
  {
    label: "10:30 - 11:00",
    value: "10:30 - 11:00",
  },
  {
    label: "12:00 - 12:30",
    value: "12:00 - 12:30",
  },
  {
    label: "13:00 - 13:30",
    value: "13:00 - 13:30",
  },
  {
    label: "15:00 - 15:30",
    value: "15:00 - 15:30",
  },
  {
    label: t`screens.meeting.allTime`,
    value: "Any time slot",
  },
];
