import React from "react";
import ReactDOM from "react-dom/client";
import "./i18n";
import App from "./App";
import "./index.css";
import { DataAttribute } from "./models/utils.model";
import { Provider } from "react-redux";
import { store } from "./redux/store";

const roots = document.getElementsByClassName("reactroot");

Array.from(roots).forEach((item) => {
  const dataOcms: DataAttribute = {
    // Global
    dataWidget: item.getAttribute("data-widget") || "",
    dataWidgetCid: item.getAttribute("data-widget-cid") || "",
    dataWidgetPromo: item.getAttribute("data-widget-promo") || "",
    dataWidgetHideTitle:
      item.getAttribute("data-widget-hide-title") === "true" ? true : false,

    // Demo
    dataWidgetCampaign: item.getAttribute("data-widget-campaign") || "",

    // Seminar
    dataWidgetSeminarTitle:
      item.getAttribute("data-widget-seminar-title") || "",
    dataWidgetSeminarCode: item.getAttribute("data-widget-seminar-code") || "",
    dataWidgetSeminarLocation:
      item.getAttribute("data-widget-seminar-location") || "",
    dataWidgetSeminarStartDate:
      item.getAttribute("data-widget-seminar-start-date") || "",
    dataWidgetSeminarEndDate:
      item.getAttribute("data-widget-seminar-end-date") || "",
    dataWidgetSeminarSeats:
      item.getAttribute("data-widget-seminar-seats") || "",
    dataWidgetSeminarWebinarLink:
      item.getAttribute("data-widget-seminar-webinar-link") || "",

    halfContainer: item.clientWidth < 800 ? true : false,
  };

  ReactDOM.createRoot(item).render(
    <Provider store={store}>
      <React.StrictMode>
        <App dataOcms={dataOcms} />
      </React.StrictMode>
    </Provider>
  );
});
