import React, { useEffect } from "react";
import { Button, FieldGroup, FieldRow, FieldSet } from "react-corner-ds";
import { useTranslation } from "react-i18next";
import style from "./CtWidgetContactB2CSuccess.module.css";

interface ICtWidgetContactB2CSuccess {
  selectedLang: string;
  hideTitle: boolean;
}

const CtWidgetContactB2CSuccess: React.FC<ICtWidgetContactB2CSuccess> = ({
  selectedLang,
  hideTitle,
}) => {
  const { t } = useTranslation();

  const scrollDataWidget = document.querySelector(
    '[data-widget="ct-widget-contactB2C"]'
  )?.scrollTop;

  window.scrollTo({ top: scrollDataWidget, behavior: "smooth" });

  useEffect(() => {
    window.buildSuccessFormPageName("contactb2c");
  }, []);

  return (
    <div className={style.action}>
      <div className={style.contactB2CSuccess}>
        {!hideTitle ? (
          <>
            <h1
              className={style.contactB2CTitle}
            >{t`screens.contactB2C.formTitle`}</h1>
            <h2
              className={style.contactB2CSubTitle}
            >{t`screens.contactB2C.formSubTitle`}</h2>
          </>
        ) : (
          <></>
        )}

        <div className={style.contactB2CSuccess}>
          <FieldSet>
            <FieldRow>
              <FieldGroup customCssClass={style.contactB2CSuccessTitle}>
                <h3>
                  <i className="cticonapp-ok-2"></i>
                  <span>{t`success.contactB2C.pageTitle`}</span>
                </h3>
              </FieldGroup>
            </FieldRow>
            <FieldRow>
              <FieldGroup customCssClass={style.contactB2CSuccessSubTitle}>
                <span>{t`success.contactB2C.pageSubtitle`}</span>
              </FieldGroup>
            </FieldRow>
            <FieldRow>
              <FieldGroup customCssClass={style.contactB2CSuccessButton}>
                <Button
                  text={t`success.contactB2C.checkPlatform`}
                  onClick={() => {
                    window.location.replace(
                      window.location.origin +
                        `/${selectedLang}/${t`success.contactB2C.platforms`}`
                    );
                  }}
                />
              </FieldGroup>
            </FieldRow>
          </FieldSet>
        </div>
      </div>
    </div>
  );
};

export default CtWidgetContactB2CSuccess;
