import { CountryItem } from "../../models/utils.model";

export const countriesFr: CountryItem[] = [
  {
    name: "Suisse",
    alpha_2: "CH",
    alpha_3: "CHE",
    phone_code: "41",
  },
  {
    name: "Allemagne",
    alpha_2: "DE",
    alpha_3: "DEU",
    phone_code: "49",
  },
  {
    name: "France",
    alpha_2: "FR",
    alpha_3: "FRA",
    phone_code: "33",
  },
  {
    name: "Italie",
    alpha_2: "IT",
    alpha_3: "ITA",
    phone_code: "39",
  },
  {
    name: "Afghanistan",
    alpha_2: "AF",
    alpha_3: "AFG",
    phone_code: "93",
  },
  {
    name: "Les Îles D'Åland",
    alpha_2: "AX",
    alpha_3: "ALA",
    phone_code: "358",
  },
  {
    name: "L'albanie",
    alpha_2: "AL",
    alpha_3: "ALB",
    phone_code: "355",
  },
  {
    name: "L'algérie",
    alpha_2: "DZ",
    alpha_3: "DZA",
    phone_code: "213",
  },
  {
    name: "Samoa Américaines",
    alpha_2: "AS",
    alpha_3: "ASM",
    phone_code: "1684",
  },
  {
    name: "Andorre",
    alpha_2: "AD",
    alpha_3: "AND",
    phone_code: "376",
  },
  {
    name: "L'Angola",
    alpha_2: "AO",
    alpha_3: "AGO",
    phone_code: "244",
  },
  {
    name: "Anguilla",
    alpha_2: "AI",
    alpha_3: "AIA",
    phone_code: "1264",
  },
  {
    name: "Antigua-et-Barbuda",
    alpha_2: "AG",
    alpha_3: "ATG",
    phone_code: "1268",
  },
  {
    name: "L'argentine",
    alpha_2: "AR",
    alpha_3: "ARG",
    phone_code: "54",
  },
  {
    name: "L'arménie",
    alpha_2: "AM",
    alpha_3: "ARM",
    phone_code: "374",
  },
  {
    name: "Aruba",
    alpha_2: "AW",
    alpha_3: "ABW",
    phone_code: "297",
  },
  {
    name: "L'australie",
    alpha_2: "AU",
    alpha_3: "AUS",
    phone_code: "61",
  },
  {
    name: "Autriche",
    alpha_2: "AT",
    alpha_3: "AUT",
    phone_code: "43",
  },
  {
    name: "L'azerbaïdjan",
    alpha_2: "AZ",
    alpha_3: "AZE",
    phone_code: "994",
  },
  {
    name: "Les Bahamas",
    alpha_2: "BS",
    alpha_3: "BHS",
    phone_code: "1242",
  },
  {
    name: "Bahreïn",
    alpha_2: "BH",
    alpha_3: "BHR",
    phone_code: "973",
  },
  {
    name: "Bangladesh",
    alpha_2: "BD",
    alpha_3: "BGD",
    phone_code: "880",
  },
  {
    name: "La barbade",
    alpha_2: "BB",
    alpha_3: "BRB",
    phone_code: "1246",
  },
  {
    name: "La biélorussie",
    alpha_2: "BY",
    alpha_3: "BLR",
    phone_code: "375",
  },
  {
    name: "Belgique",
    alpha_2: "BE",
    alpha_3: "BEL",
    phone_code: "32",
  },
  {
    name: "Belize",
    alpha_2: "BZ",
    alpha_3: "BLZ",
    phone_code: "501",
  },
  {
    name: "Bénin",
    alpha_2: "BJ",
    alpha_3: "BEN",
    phone_code: "229",
  },
  {
    name: "Bermudes",
    alpha_2: "BM",
    alpha_3: "BMU",
    phone_code: "1441",
  },
  {
    name: "Le bhoutan",
    alpha_2: "BT",
    alpha_3: "BTN",
    phone_code: "975",
  },
  {
    name: "La bolivie",
    alpha_2: "BO",
    alpha_3: "BOL",
    phone_code: "591",
  },
  {
    name: "Bonaire",
    alpha_2: "BQ",
    alpha_3: "BES",
    phone_code: "5997",
  },
  {
    name: "Botswana",
    alpha_2: "BW",
    alpha_3: "BWA",
    phone_code: "267",
  },
  {
    name: "Brésil",
    alpha_2: "BR",
    alpha_3: "BRA",
    phone_code: "55",
  },
  {
    name: "Territoire Britannique De L'Océan Indien",
    alpha_2: "IO",
    alpha_3: "IOT",
    phone_code: "246",
  },
  {
    name: "Îles Vierges Britanniques",
    alpha_2: "VG",
    alpha_3: "VGB",
    phone_code: "1284",
  },
  {
    name: "Brunei",
    alpha_2: "BN",
    alpha_3: "BRN",
    phone_code: "673",
  },
  {
    name: "La bulgarie",
    alpha_2: "BG",
    alpha_3: "BGR",
    phone_code: "359",
  },
  {
    name: "Burkina Faso",
    alpha_2: "BF",
    alpha_3: "BFA",
    phone_code: "226",
  },
  {
    name: "Burundi",
    alpha_2: "BI",
    alpha_3: "BDI",
    phone_code: "257",
  },
  {
    name: "Cambodge",
    alpha_2: "KH",
    alpha_3: "KHM",
    phone_code: "855",
  },
  {
    name: "Cameroun",
    alpha_2: "CM",
    alpha_3: "CMR",
    phone_code: "237",
  },
  {
    name: "Canada",
    alpha_2: "CA",
    alpha_3: "CAN",
    phone_code: "1",
  },
  {
    name: "Le Cap-Vert",
    alpha_2: "CV",
    alpha_3: "CPV",
    phone_code: "238",
  },
  {
    name: "Îles Caïmans",
    alpha_2: "KY",
    alpha_3: "CYM",
    phone_code: "1345",
  },
  {
    name: "République Centrafricaine",
    alpha_2: "CF",
    alpha_3: "CAF",
    phone_code: "236",
  },
  {
    name: "Tchad",
    alpha_2: "TD",
    alpha_3: "TCD",
    phone_code: "235",
  },
  {
    name: "Chili",
    alpha_2: "CL",
    alpha_3: "CHL",
    phone_code: "56",
  },
  {
    name: "La chine",
    alpha_2: "CN",
    alpha_3: "CHN",
    phone_code: "86",
  },
  {
    name: "L'Île Christmas",
    alpha_2: "CX",
    alpha_3: "CXR",
    phone_code: "61",
  },
  {
    name: "Îles Cocos (Keeling ",
    alpha_2: "CC",
    alpha_3: "CCK",
    phone_code: "61",
  },
  {
    name: "La colombie",
    alpha_2: "CO",
    alpha_3: "COL",
    phone_code: "57",
  },
  {
    name: "Comores",
    alpha_2: "KM",
    alpha_3: "COM",
    phone_code: "269",
  },
  {
    name: "République du Congo",
    alpha_2: "CG",
    alpha_3: "COG",
    phone_code: "242",
  },
  {
    name: "République démocratique du Congo",
    alpha_2: "CD",
    alpha_3: "COD",
    phone_code: "243",
  },
  {
    name: "Îles Cook",
    alpha_2: "CK",
    alpha_3: "COK",
    phone_code: "682",
  },
  {
    name: "Costa Rica",
    alpha_2: "CR",
    alpha_3: "CRI",
    phone_code: "506",
  },
  {
    name: "La croatie",
    alpha_2: "HR",
    alpha_3: "HRV",
    phone_code: "385",
  },
  {
    name: "Cuba",
    alpha_2: "CU",
    alpha_3: "CUB",
    phone_code: "53",
  },
  {
    name: "Curaçao",
    alpha_2: "CW",
    alpha_3: "CUW",
    phone_code: "5999",
  },
  {
    name: "Chypre",
    alpha_2: "CY",
    alpha_3: "CYP",
    phone_code: "357",
  },
  {
    name: "République Tchèque",
    alpha_2: "CZ",
    alpha_3: "CZE",
    phone_code: "420",
  },
  {
    name: "Danemark",
    alpha_2: "DK",
    alpha_3: "DNK",
    phone_code: "45",
  },
  {
    name: "Djibouti",
    alpha_2: "DJ",
    alpha_3: "DJI",
    phone_code: "253",
  },
  {
    name: "Dominique",
    alpha_2: "DM",
    alpha_3: "DMA",
    phone_code: "1767",
  },
  {
    name: "République Dominicaine",
    alpha_2: "DO",
    alpha_3: "DOM",
    phone_code: "1809",
  },
  {
    name: "L'équateur",
    alpha_2: "EC",
    alpha_3: "ECU",
    phone_code: "593",
  },
  {
    name: "L'egypte",
    alpha_2: "EG",
    alpha_3: "EGY",
    phone_code: "20",
  },
  {
    name: "El Salvador",
    alpha_2: "SV",
    alpha_3: "SLV",
    phone_code: "503",
  },
  {
    name: "Guinée Équatoriale",
    alpha_2: "GQ",
    alpha_3: "GNQ",
    phone_code: "240",
  },
  {
    name: "L'érythrée",
    alpha_2: "ER",
    alpha_3: "ERI",
    phone_code: "291",
  },
  {
    name: "L'estonie",
    alpha_2: "EE",
    alpha_3: "EST",
    phone_code: "372",
  },
  {
    name: "L'ethiopie",
    alpha_2: "ET",
    alpha_3: "ETH",
    phone_code: "251",
  },
  {
    name: "Îles Falkland",
    alpha_2: "FK",
    alpha_3: "FLK",
    phone_code: "500",
  },
  {
    name: "Îles Féroé",
    alpha_2: "FO",
    alpha_3: "FRO",
    phone_code: "298",
  },
  {
    name: "Fidji",
    alpha_2: "FJ",
    alpha_3: "FJI",
    phone_code: "679",
  },
  {
    name: "La finlande",
    alpha_2: "FI",
    alpha_3: "FIN",
    phone_code: "358",
  },
  {
    name: "Guyane Française",
    alpha_2: "GF",
    alpha_3: "GUF",
    phone_code: "594",
  },
  {
    name: "La Polynésie Française",
    alpha_2: "PF",
    alpha_3: "PYF",
    phone_code: "689",
  },
  {
    name: "Terres australes et Antarctiques françaises",
    alpha_2: "TF",
    alpha_3: "ATF",
    phone_code: "262",
  },
  {
    name: "Gabon",
    alpha_2: "GA",
    alpha_3: "GAB",
    phone_code: "241",
  },
  {
    name: "La Gambie",
    alpha_2: "GM",
    alpha_3: "GMB",
    phone_code: "220",
  },
  {
    name: "La géorgie",
    alpha_2: "GE",
    alpha_3: "GEO",
    phone_code: "995",
  },
  {
    name: "Le Ghana",
    alpha_2: "GH",
    alpha_3: "GHA",
    phone_code: "233",
  },
  {
    name: "Gibraltar",
    alpha_2: "GI",
    alpha_3: "GIB",
    phone_code: "350",
  },
  {
    name: "La grèce",
    alpha_2: "GR",
    alpha_3: "GRC",
    phone_code: "30",
  },
  {
    name: "Le groenland",
    alpha_2: "GL",
    alpha_3: "GRL",
    phone_code: "299",
  },
  {
    name: "La grenade",
    alpha_2: "GD",
    alpha_3: "GRD",
    phone_code: "1473",
  },
  {
    name: "Guadeloupe",
    alpha_2: "GP",
    alpha_3: "GLP",
    phone_code: "590",
  },
  {
    name: "Guam",
    alpha_2: "GU",
    alpha_3: "GUM",
    phone_code: "1671",
  },
  {
    name: "Guatemala",
    alpha_2: "GT",
    alpha_3: "GTM",
    phone_code: "502",
  },
  {
    name: "Guernesey",
    alpha_2: "GG",
    alpha_3: "GGY",
    phone_code: "44",
  },
  {
    name: "La guinée",
    alpha_2: "GN",
    alpha_3: "GIN",
    phone_code: "224",
  },
  {
    name: "La Guinée-Bissau",
    alpha_2: "GW",
    alpha_3: "GNB",
    phone_code: "245",
  },
  {
    name: "La guyane",
    alpha_2: "GY",
    alpha_3: "GUY",
    phone_code: "592",
  },
  {
    name: "Haïti",
    alpha_2: "HT",
    alpha_3: "HTI",
    phone_code: "509",
  },
  {
    name: "Honduras",
    alpha_2: "HN",
    alpha_3: "HND",
    phone_code: "504",
  },
  {
    name: "Hong Kong",
    alpha_2: "HK",
    alpha_3: "HKG",
    phone_code: "852",
  },
  {
    name: "Hongrie",
    alpha_2: "HU",
    alpha_3: "HUN",
    phone_code: "36",
  },
  {
    name: "L'islande",
    alpha_2: "IS",
    alpha_3: "ISL",
    phone_code: "354",
  },
  {
    name: "L'inde",
    alpha_2: "IN",
    alpha_3: "IND",
    phone_code: "91",
  },
  {
    name: "L'indonésie",
    alpha_2: "ID",
    alpha_3: "IDN",
    phone_code: "62",
  },
  {
    name: "Côte D'Ivoire",
    alpha_2: "CI",
    alpha_3: "CIV",
    phone_code: "225",
  },
  {
    name: "L'Iran",
    alpha_2: "IR",
    alpha_3: "IRN",
    phone_code: "98",
  },
  {
    name: "L'irak",
    alpha_2: "IQ",
    alpha_3: "IRQ",
    phone_code: "964",
  },
  {
    name: "République d'Irlande",
    alpha_2: "IE",
    alpha_3: "IRL",
    phone_code: "353",
  },
  {
    name: "L'île de Man",
    alpha_2: "IM",
    alpha_3: "IMN",
    phone_code: "44",
  },
  {
    name: "Israël",
    alpha_2: "IL",
    alpha_3: "ISR",
    phone_code: "972",
  },
  {
    name: "La jamaïque",
    alpha_2: "JM",
    alpha_3: "JAM",
    phone_code: "1876",
  },
  {
    name: "Japon",
    alpha_2: "JP",
    alpha_3: "JPN",
    phone_code: "81",
  },
  {
    name: "La jordanie",
    alpha_2: "JO",
    alpha_3: "JOR",
    phone_code: "962",
  },
  {
    name: "Kazakhstan",
    alpha_2: "KZ",
    alpha_3: "KAZ",
    phone_code: "76",
  },
  {
    name: "Kenya",
    alpha_2: "KE",
    alpha_3: "KEN",
    phone_code: "254",
  },
  {
    name: "Kiribati",
    alpha_2: "KI",
    alpha_3: "KIR",
    phone_code: "686",
  },
  {
    name: "Koweït",
    alpha_2: "KW",
    alpha_3: "KWT",
    phone_code: "965",
  },
  {
    name: "Kirghizistan",
    alpha_2: "KG",
    alpha_3: "KGZ",
    phone_code: "996",
  },
  {
    name: "Laos",
    alpha_2: "LA",
    alpha_3: "LAO",
    phone_code: "856",
  },
  {
    name: "La lettonie",
    alpha_2: "LV",
    alpha_3: "LVA",
    phone_code: "371",
  },
  {
    name: "Liban",
    alpha_2: "LB",
    alpha_3: "LBN",
    phone_code: "961",
  },
  {
    name: "Lesotho",
    alpha_2: "LS",
    alpha_3: "LSO",
    phone_code: "266",
  },
  {
    name: "Libéria",
    alpha_2: "LR",
    alpha_3: "LBR",
    phone_code: "231",
  },
  {
    name: "La libye",
    alpha_2: "LY",
    alpha_3: "LBY",
    phone_code: "218",
  },
  {
    name: "Le Liechtenstein",
    alpha_2: "LI",
    alpha_3: "LIE",
    phone_code: "423",
  },
  {
    name: "La lituanie",
    alpha_2: "LT",
    alpha_3: "LTU",
    phone_code: "370",
  },
  {
    name: "Luxembourg",
    alpha_2: "LU",
    alpha_3: "LUX",
    phone_code: "352",
  },
  {
    name: "République de Macédoine",
    alpha_2: "MK",
    alpha_3: "MKD",
    phone_code: "389",
  },
  {
    name: "Madagascar",
    alpha_2: "MG",
    alpha_3: "MDG",
    phone_code: "261",
  },
  {
    name: "Malawi",
    alpha_2: "MW",
    alpha_3: "MWI",
    phone_code: "265",
  },
  {
    name: "La malaisie",
    alpha_2: "MY",
    alpha_3: "MYS",
    phone_code: "60",
  },
  {
    name: "Maldives",
    alpha_2: "MV",
    alpha_3: "MDV",
    phone_code: "960",
  },
  {
    name: "Le Mali",
    alpha_2: "ML",
    alpha_3: "MLI",
    phone_code: "223",
  },
  {
    name: "Malte",
    alpha_2: "MT",
    alpha_3: "MLT",
    phone_code: "356",
  },
  {
    name: "Îles Marshall",
    alpha_2: "MH",
    alpha_3: "MHL",
    phone_code: "692",
  },
  {
    name: "Martinique",
    alpha_2: "MQ",
    alpha_3: "MTQ",
    phone_code: "596",
  },
  {
    name: "La mauritanie",
    alpha_2: "MR",
    alpha_3: "MRT",
    phone_code: "222",
  },
  {
    name: "L'île maurice",
    alpha_2: "MU",
    alpha_3: "MUS",
    phone_code: "230",
  },
  {
    name: "Mayotte",
    alpha_2: "YT",
    alpha_3: "MYT",
    phone_code: "262",
  },
  {
    name: "Mexique",
    alpha_2: "MX",
    alpha_3: "MEX",
    phone_code: "52",
  },
  {
    name: "États fédérés de Micronésie",
    alpha_2: "FM",
    alpha_3: "FSM",
    phone_code: "691",
  },
  {
    name: "La moldavie",
    alpha_2: "MD",
    alpha_3: "MDA",
    phone_code: "373",
  },
  {
    name: "Monaco",
    alpha_2: "MC",
    alpha_3: "MCO",
    phone_code: "377",
  },
  {
    name: "La mongolie",
    alpha_2: "MN",
    alpha_3: "MNG",
    phone_code: "976",
  },
  {
    name: "Le monténégro",
    alpha_2: "ME",
    alpha_3: "MNE",
    phone_code: "382",
  },
  {
    name: "Montserrat",
    alpha_2: "MS",
    alpha_3: "MSR",
    phone_code: "1664",
  },
  {
    name: "Maroc",
    alpha_2: "MA",
    alpha_3: "MAR",
    phone_code: "212",
  },
  {
    name: "Mozambique",
    alpha_2: "MZ",
    alpha_3: "MOZ",
    phone_code: "258",
  },
  {
    name: "Myanmar",
    alpha_2: "MM",
    alpha_3: "MMR",
    phone_code: "95",
  },
  {
    name: "La namibie",
    alpha_2: "NA",
    alpha_3: "NAM",
    phone_code: "264",
  },
  {
    name: "Nauru",
    alpha_2: "NR",
    alpha_3: "NRU",
    phone_code: "674",
  },
  {
    name: "Népal",
    alpha_2: "NP",
    alpha_3: "NPL",
    phone_code: "977",
  },
  {
    name: "Pays-bas",
    alpha_2: "NL",
    alpha_3: "NLD",
    phone_code: "31",
  },
  {
    name: "La Nouvelle-Calédonie",
    alpha_2: "NC",
    alpha_3: "NCL",
    phone_code: "687",
  },
  {
    name: "La Nouvelle-Zélande",
    alpha_2: "NZ",
    alpha_3: "NZL",
    phone_code: "64",
  },
  {
    name: "Nicaragua",
    alpha_2: "NI",
    alpha_3: "NIC",
    phone_code: "505",
  },
  {
    name: "Niger",
    alpha_2: "NE",
    alpha_3: "NER",
    phone_code: "227",
  },
  {
    name: "Nigeria",
    alpha_2: "NG",
    alpha_3: "NGA",
    phone_code: "234",
  },
  {
    name: "Niue",
    alpha_2: "NU",
    alpha_3: "NIU",
    phone_code: "683",
  },
  {
    name: "L'Île De Norfolk",
    alpha_2: "NF",
    alpha_3: "NFK",
    phone_code: "672",
  },
  {
    name: "La Corée Du Nord",
    alpha_2: "KP",
    alpha_3: "PRK",
    phone_code: "850",
  },
  {
    name: "Îles Mariannes Du Nord",
    alpha_2: "MP",
    alpha_3: "MNP",
    phone_code: "1670",
  },
  {
    name: "La norvège",
    alpha_2: "NO",
    alpha_3: "NOR",
    phone_code: "47",
  },
  {
    name: "Oman",
    alpha_2: "OM",
    alpha_3: "OMN",
    phone_code: "968",
  },
  {
    name: "Pakistan",
    alpha_2: "PK",
    alpha_3: "PAK",
    phone_code: "92",
  },
  {
    name: "Palau",
    alpha_2: "PW",
    alpha_3: "PLW",
    phone_code: "680",
  },
  {
    name: "La Palestine",
    alpha_2: "PS",
    alpha_3: "PSE",
    phone_code: "970",
  },
  {
    name: "Panama",
    alpha_2: "PA",
    alpha_3: "PAN",
    phone_code: "507",
  },
  {
    name: "Papouasie-Nouvelle-Guinée",
    alpha_2: "PG",
    alpha_3: "PNG",
    phone_code: "675",
  },
  {
    name: "Paraguay",
    alpha_2: "PY",
    alpha_3: "PRY",
    phone_code: "595",
  },
  {
    name: "Pérou",
    alpha_2: "PE",
    alpha_3: "PER",
    phone_code: "51",
  },
  {
    name: "Philippines",
    alpha_2: "PH",
    alpha_3: "PHL",
    phone_code: "63",
  },
  {
    name: "Îles Pitcairn",
    alpha_2: "PN",
    alpha_3: "PCN",
    phone_code: "64",
  },
  {
    name: "Pologne",
    alpha_2: "PL",
    alpha_3: "POL",
    phone_code: "48",
  },
  {
    name: "Portugal",
    alpha_2: "PT",
    alpha_3: "PRT",
    phone_code: "351",
  },
  {
    name: "Puerto Rico",
    alpha_2: "PR",
    alpha_3: "PRI",
    phone_code: "1787",
  },
  {
    name: "Qatar",
    alpha_2: "QA",
    alpha_3: "QAT",
    phone_code: "974",
  },
  {
    name: "République du Kosovo",
    alpha_2: "XK",
    alpha_3: "KOS",
    phone_code: "383",
  },
  {
    name: "La Réunion",
    alpha_2: "RE",
    alpha_3: "REU",
    phone_code: "262",
  },
  {
    name: "La roumanie",
    alpha_2: "RO",
    alpha_3: "ROU",
    phone_code: "40",
  },
  {
    name: "La russie",
    alpha_2: "RU",
    alpha_3: "RUS",
    phone_code: "7",
  },
  {
    name: "Le Rwanda",
    alpha_2: "RW",
    alpha_3: "RWA",
    phone_code: "250",
  },
  {
    name: "Saint-Barthélemy",
    alpha_2: "BL",
    alpha_3: "BLM",
    phone_code: "590",
  },
  {
    name: "Saint-Kitts-et-Nevis",
    alpha_2: "KN",
    alpha_3: "KNA",
    phone_code: "1869",
  },
  {
    name: "Sainte-Lucie",
    alpha_2: "LC",
    alpha_3: "LCA",
    phone_code: "1758",
  },
  {
    name: "Saint Martin",
    alpha_2: "MF",
    alpha_3: "MAF",
    phone_code: "590",
  },
  {
    name: "Saint-Pierre-et-Miquelon",
    alpha_2: "PM",
    alpha_3: "SPM",
    phone_code: "508",
  },
  {
    name: "Saint-Vincent-et-les-Grenadines",
    alpha_2: "VC",
    alpha_3: "VCT",
    phone_code: "1784",
  },
  {
    name: "Samoa",
    alpha_2: "WS",
    alpha_3: "WSM",
    phone_code: "685",
  },
  {
    name: "San Marino",
    alpha_2: "SM",
    alpha_3: "SMR",
    phone_code: "378",
  },
  {
    name: "São Tomé et Príncipe",
    alpha_2: "ST",
    alpha_3: "STP",
    phone_code: "239",
  },
  {
    name: "L'Arabie Saoudite",
    alpha_2: "SA",
    alpha_3: "SAU",
    phone_code: "966",
  },
  {
    name: "Sénégal",
    alpha_2: "SN",
    alpha_3: "SEN",
    phone_code: "221",
  },
  {
    name: "La serbie",
    alpha_2: "RS",
    alpha_3: "SRB",
    phone_code: "381",
  },
  {
    name: "Seychelles",
    alpha_2: "SC",
    alpha_3: "SYC",
    phone_code: "248",
  },
  {
    name: "La Sierra Leone",
    alpha_2: "SL",
    alpha_3: "SLE",
    phone_code: "232",
  },
  {
    name: "Singapour",
    alpha_2: "SG",
    alpha_3: "SGP",
    phone_code: "65",
  },
  {
    name: "Sint Maarten",
    alpha_2: "SX",
    alpha_3: "SXM",
    phone_code: "1721",
  },
  {
    name: "La slovaquie",
    alpha_2: "SK",
    alpha_3: "SVK",
    phone_code: "421",
  },
  {
    name: "La slovénie",
    alpha_2: "SI",
    alpha_3: "SVN",
    phone_code: "386",
  },
  {
    name: "Îles Salomon",
    alpha_2: "SB",
    alpha_3: "SLB",
    phone_code: "677",
  },
  {
    name: "La somalie",
    alpha_2: "SO",
    alpha_3: "SOM",
    phone_code: "252",
  },
  {
    name: "L'Afrique Du Sud",
    alpha_2: "ZA",
    alpha_3: "ZAF",
    phone_code: "27",
  },
  {
    name: "La Géorgie Du Sud",
    alpha_2: "GS",
    alpha_3: "SGS",
    phone_code: "500",
  },
  {
    name: "La Corée Du Sud",
    alpha_2: "KR",
    alpha_3: "KOR",
    phone_code: "82",
  },
  {
    name: "Le Soudan Du Sud",
    alpha_2: "SS",
    alpha_3: "SSD",
    phone_code: "211",
  },
  {
    name: "Espagne",
    alpha_2: "ES",
    alpha_3: "ESP",
    phone_code: "34",
  },
  {
    name: "Sri Lanka",
    alpha_2: "LK",
    alpha_3: "LKA",
    phone_code: "94",
  },
  {
    name: "Soudan",
    alpha_2: "SD",
    alpha_3: "SDN",
    phone_code: "249",
  },
  {
    name: "Suriname",
    alpha_2: "SR",
    alpha_3: "SUR",
    phone_code: "597",
  },
  {
    name: "Svalbard et Jan Mayen",
    alpha_2: "SJ",
    alpha_3: "SJM",
    phone_code: "4779",
  },
  {
    name: "Swaziland",
    alpha_2: "SZ",
    alpha_3: "SWZ",
    phone_code: "268",
  },
  {
    name: "Suède",
    alpha_2: "SE",
    alpha_3: "SWE",
    phone_code: "46",
  },
  {
    name: "La syrie",
    alpha_2: "SY",
    alpha_3: "SYR",
    phone_code: "963",
  },
  {
    name: "Taiwan",
    alpha_2: "TW",
    alpha_3: "TWN",
    phone_code: "886",
  },
  {
    name: "Tadjikistan",
    alpha_2: "TJ",
    alpha_3: "TJK",
    phone_code: "992",
  },
  {
    name: "La tanzanie",
    alpha_2: "TZ",
    alpha_3: "TZA",
    phone_code: "255",
  },
  {
    name: "Thaïlande",
    alpha_2: "TH",
    alpha_3: "THA",
    phone_code: "66",
  },
  {
    name: "Le Timor Oriental",
    alpha_2: "TL",
    alpha_3: "TLS",
    phone_code: "670",
  },
  {
    name: "Togo",
    alpha_2: "TG",
    alpha_3: "TGO",
    phone_code: "228",
  },
  {
    name: "Tokelau",
    alpha_2: "TK",
    alpha_3: "TKL",
    phone_code: "690",
  },
  {
    name: "Tonga",
    alpha_2: "TO",
    alpha_3: "TON",
    phone_code: "676",
  },
  {
    name: "Trinité-et-Tobago",
    alpha_2: "TT",
    alpha_3: "TTO",
    phone_code: "1868",
  },
  {
    name: "Tunisie",
    alpha_2: "TN",
    alpha_3: "TUN",
    phone_code: "216",
  },
  {
    name: "La turquie",
    alpha_2: "TR",
    alpha_3: "TUR",
    phone_code: "90",
  },
  {
    name: "Le turkménistan",
    alpha_2: "TM",
    alpha_3: "TKM",
    phone_code: "993",
  },
  {
    name: "Îles turques et Caïques",
    alpha_2: "TC",
    alpha_3: "TCA",
    phone_code: "1649",
  },
  {
    name: "Tuvalu",
    alpha_2: "TV",
    alpha_3: "TUV",
    phone_code: "688",
  },
  {
    name: "L'ouganda",
    alpha_2: "UG",
    alpha_3: "UGA",
    phone_code: "256",
  },
  {
    name: "L'Ukraine",
    alpha_2: "UA",
    alpha_3: "UKR",
    phone_code: "380",
  },
  {
    name: "Émirats Arabes Unis",
    alpha_2: "AE",
    alpha_3: "ARE",
    phone_code: "971",
  },
  {
    name: "Royaume-Uni",
    alpha_2: "GB",
    alpha_3: "GBR",
    phone_code: "44",
  },
  {
    name: "États-Unis",
    alpha_2: "US",
    alpha_3: "USA",
    phone_code: "1",
  },
  {
    name: "L'Uruguay",
    alpha_2: "UY",
    alpha_3: "URY",
    phone_code: "598",
  },
  {
    name: "Ouzbékistan",
    alpha_2: "UZ",
    alpha_3: "UZB",
    phone_code: "998",
  },
  {
    name: "Vanuatu",
    alpha_2: "VU",
    alpha_3: "VUT",
    phone_code: "678",
  },
  {
    name: "Venezuela",
    alpha_2: "VE",
    alpha_3: "VEN",
    phone_code: "58",
  },
  {
    name: "Vietnam",
    alpha_2: "VN",
    alpha_3: "VNM",
    phone_code: "84",
  },
  {
    name: "Wallis-et-Futuna",
    alpha_2: "WF",
    alpha_3: "WLF",
    phone_code: "681",
  },
  {
    name: "Sahara Occidental",
    alpha_2: "EH",
    alpha_3: "ESH",
    phone_code: "212",
  },
  {
    name: "Yémen",
    alpha_2: "YE",
    alpha_3: "YEM",
    phone_code: "967",
  },
  {
    name: "La zambie",
    alpha_2: "ZM",
    alpha_3: "ZMB",
    phone_code: "260",
  },
  {
    name: "Zimbabwe",
    alpha_2: "ZW",
    alpha_3: "ZWE",
    phone_code: "263",
  },
];
