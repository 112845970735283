.demoSuccess {
  font-size: 18px;
  font-weight: 200;
  text-align: center;
  line-height: 1.56;
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  font-family: 'Lexend', sans-serif;
}

.demoSuccessTitle h2 {
  font-size: 40px;
  color: var(--form-text-success-color);
}

.demoSuccessTitle h2 i {
  font-size: 80px;
  display: block;
  margin-bottom: 24px;
}

.demoSuccessSubTitle {
  text-align: center;
  color: var(--form-text-color);
}

.demoSuccessValue {
  color: var(--form-text-success-color);
}

.customButton button {
  font-family: 'Lexend', sans-serif;
}

.customButton button.closeButton,
.customButton button.closeButton:hover {
  background-color: transparent !important;
  border: none !important;
  cursor: pointer;
  font-size: 16px;
  color: var(--form-text-success-color) !important;
  font-weight: 600;
  line-height: 1.33;
  opacity: 1 !important;
}

.customButton button.closeButton:hover {
  text-decoration: underline !important;
}