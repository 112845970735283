import { useCallback } from "react";
import { Status } from "../../models/response/status/status.model";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  AppConfState,
  setAppConf,
} from "../../redux/states/appConf/appConfSlice";
import { ERRORS } from "../../utils/constants";
import { useApi } from "../useApi/useApi";
import { useGrecaptcha } from "../useGrecaptcha/useGrecaptcha";
import { useLogger } from "../useLogger/useLogger";

export const useFlow = () => {
  const { stepLoader, configuration } = useApi();
  const { log, logError } = useLogger();
  const { loadGrecaptchaScript, getGrecaptchaToken } = useGrecaptcha();
  const reduxDispatch = useAppDispatch();
  const appConf = useAppSelector((state) => state.appConf);

  const setCaptchaToken = useCallback(async (): Promise<string | null> => {
    if (appConf.captchaEnabled) {
      log(
        "log",
        "useFlow.tsx",
        "callSteploader - setCaptchaToken => This step is protected by Invisible Captcha"
      );
      return getGrecaptchaToken();
    }
    log(
      "log",
      "useFlow.tsx",
      "callSteploader - setCaptchaToken => Captcha disabled or step not protected  "
    );
    return null;
  }, [appConf, log, getGrecaptchaToken]);

  const handleError = useCallback(
    (errStatus: Status, animation: boolean = true) => {
      const errors = ERRORS.filter((err) =>
        errStatus.errorCodes?.includes(err.code)
      );
      const errorFrom = errStatus.errorType > 0 ? "PEGA ERROR" : "BE ERROR";
      if (errors.length > 0) {
        errors.forEach((err) => {
          log(
            "error",
            "useFlow.tsx",
            `handleError =>`,
            `${errorFrom} - ${err.msg} : ${err.code}`
          );
        });
      } else {
        log(
          "error",
          "useFlow.tsx",
          `handleError =>`,
          `${errorFrom} - Unhandled error codes : ${errStatus.errorCodes}`
        );
      }
    },
    []
  );

  const callSubscribe = useCallback(
    async (body: any, shouldNavigate = true, step?: string) => {
      let token = await setCaptchaToken();
      if (token) body.Extra.captchaToken = token;
      log("log", "useFlow.tsx", "callSteploader - webForm req =>", body);
      let resp = await stepLoader(body);
      if (resp) {
        let respStatus = resp.data.status;

        log(
          respStatus.success ? "log" : "error",
          "useFlow.tsx",
          "callSteploader - webForm resp =>",
          resp.data
        );
        if (respStatus.success) {
          if (
            process.env.REACT_APP_ENV === "dev" &&
            resp.data.content?.devCaseID
          ) {
            sessionStorage.setItem("devCaseID", resp.data.content.devCaseID);
          }
        } else {
          handleError(respStatus);
        }
      } else {
        log("log", "useFlow.tsx", "callSteploader - webForm resp =>", resp);
      }
      return resp;
    },
    [stepLoader, handleError, setCaptchaToken]
  );

  const loadConfiguration = useCallback(async () => {
    log("log", "useFlow.tsx", "loadConfiguration - configuration call");
    let resp = await configuration();
    if (resp) {
      let conf = resp.data.status;
      log(
        conf.success ? "log" : "error",
        "useFlow.tsx",
        "loadConfiguration - configuration resp =>",
        resp
      );
      if (conf.success) {
        let data: AppConfState = resp.data.data;
        //Load grecaptcha v3 if captcha are enabled
        if (data.captchaEnabled) {
          log(
            "log",
            "useFlow.tsx",
            "Google captcha enabled. Loading script..."
          );
          loadGrecaptchaScript(data.captchaSiteKey);
        } else {
          log("log", "useFlow.tsx", "Google captcha disabled.");
        }
        reduxDispatch(setAppConf(data));
      } else {
        handleError(conf);
      }
    } else {
      logError(
        "useFlow.tsx",
        "loadConfiguration - configuration resp =>",
        resp
      );
    }
  }, [
    configuration,
    loadGrecaptchaScript,
    log,
    reduxDispatch,
    handleError,
    logError,
  ]);

  return { callSubscribe, loadConfiguration };
};
