.meetingForm {
  --primary-color: #154dd1;
  --primary-border-color: #154dd1;
  --primary-text-color: #fff;
  --primary-hover-color: #193f99;
  --primary-hover-border-color: #193f99;
  --primary-hover-text-color: #fff;

  --secondary-color: #39d09e;
  --secondary-border-color: #39d09e;
  --secondary-text-color: #fff;
  --secondary-hover-color: #009966;
  --secondary-hover-border-color: #009966;
  --secondary-hover-text-color: #fff;

  font-size: 16px;
  padding: 0 32px 40px 32px;
  background-color: var(--primary-text-color);
  border-radius: 8px;
  opacity: 1;
  transition: all 0.2s linear;
}

.action {
  flex: 1 1 50%;
  position: relative;

  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  font-family: 'Lexend', sans-serif;
  color: var(--form-text-color);
}

.meetingForm h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 12px 0 -12px 0;
  line-height: 1.31;
  color: var(--form-text-color);
}

.meetingTitle {
  font-size: 40px;
  font-weight: 500;
  word-wrap: break-word;
  line-height: 1.1;
  margin-top: 32px;
  text-align: center;
}

.meetingTitle .hlight {
  color: var(--form-text-title-color);
}

.meetingSubTitle {
  color: var(--form-text-info-color);
  font-size: 20px;
  font-weight: 200;
  line-height: 1.27;
  margin: 16px auto 24px;
  text-align: center;
}

.customInputCalendar {
  cursor: pointer;
}

.customInputCalendar i {
  align-self: center !important;
  color: var(--ternary-color-80) !important;
  margin-left: 50px !important;
  position: absolute;
  right: 12px;
}

.customInputCalendar label {
  font-size: 12px !important;
  line-height: 1.5 !important;
  top: 0 !important;
  left: 16px !important;
  cursor: pointer;
}

.customInputCalendar input {
  margin-top: 17px;
  border: none;
  font-size: inherit;
  max-width: 220px;
}

.customInput {
  outline: 0px;
}

.customInput:disabled {
  background-color: var(--primary-text-color);
  cursor: pointer;
}

.customCalendar>div {
  box-shadow: 0 0 80px 0 rgba(0, 0, 0, 0.2);
}

div.customPhoneInput>div>i {
  color: var(--form-arrow-color) !important;
  font-size: 16px !important;
}

/* .noteLanguage {
  font-size: 14px;
  line-height: 1.14;
  margin: 16px 0 8px;
} */

.meetingForm textarea {
  resize: vertical;
}

.disclaimerButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: var(--secondary-color-palette);
}

.disclaimer {
  font-size: 14px;
  line-height: 1.33;
}

.disclaimerRow {
  flex-direction: row;
}

.disclaimer i {
  margin-left: 0.5rem;
  padding-top: 1rem;
  cursor: pointer;
}

.disclaimerCheckBox {
  max-width: fit-content;
  margin-right: 0;
}

.expContent {
  font-size: 12px;
  line-height: 1.33;
  padding-top: 10px;
}

.expContent ul {
  margin-top: 8px;
  list-style-type: disc;
}

.expContent li {
  margin-left: 16px;
}

.expContent a {
  color: var(--secondary-color-palette);
}

.cta {
  text-align: center;
  margin-top: 12px;
}

.meetingForm .cta button {
  min-width: 150px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  padding: 6px 16px;
  font-family: 'Lexend', sans-serif;
}

.errorSubmit {
  color: var(--form-text-error-color);
  display: block;
  margin-top: 20px;
}

.messageSubmit {
  display: block;
  margin-top: 20px;
}

div.customFieldRow {
  margin: 0 -6px;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 561px) {
  .customInputCalendar i {
    margin-left: 155px !important;
  }
}

@media screen and (min-width: 769px) {
  .meetingTitle {
    justify-content: center;
    font-size: 52px;
  }

  .customInputCalendar i {
    margin-left: 0px !important;
  }

  .language {
    flex-direction: row;
    flex-wrap: wrap;
  }

  div.language label {
    flex: 0 1 50%;
    min-height: 56px !important;
    margin-top: -1px;
  }

  div.language label:first-child {
    border-bottom-left-radius: 0 !important;
  }

  div.language label:nth-child(2) {
    border-top-right-radius: 4px !important;
  }

  div.language label:nth-child(3) {
    border-bottom-left-radius: 4px !important;
  }

  div.language label:last-child {
    border-top-right-radius: 0 !important;
  }
}

@media screen and (min-width: 993px) {
  .meetingTitle {
    font-size: 64px;
  }

  .meetingSubTitle {
    font-size: 24px;
  }
}

@media screen and (min-width: 1280px) {
  .action {
    flex: 1 0 600px;
  }

  div.customFieldRow {
    flex-direction: row;
    flex-wrap: wrap;
  }
}