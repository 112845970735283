import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";

const GooglCaptchaDisclaimer: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const appConf = useAppSelector((state) => state.appConf);

  if (appConf.captchaEnabled)
    return (
      <div>
        <p className="captcha-info">
          <Trans
            i18nKey={t`captchaDisclaimer`}
            components={[
              <a
                href={`https://policies.google.com/privacy?hl=${i18n.resolvedLanguage}`}
                target="_blank"
                rel="noreferrer"
                key={"privacy-link"}
              >
                {}
              </a>,
              <a
                href={`https://policies.google.com/terms?hl=${i18n.resolvedLanguage}`}
                target="_blank"
                rel="noreferrer"
                key={"terms-link"}
              >
                {}
              </a>,
            ]}
          />
        </p>
      </div>
    );
  else return null;
};

export default GooglCaptchaDisclaimer;
