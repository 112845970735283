export const TRANSLATIONS_FR = {
  screens: {
    seminar: {
      formTitle: "Participez gratuitement",
      firstName: "Prénom",
      lastName: "Nom",
      email: "Adresse e-mail",
      country: "Pays de résidence",
      canton: "Canton",
      phoneNr: "Numéro de téléphone",
      emailRequired: "Veuillez saisir une adresse e-mail valide",
      phoneRequired:
        "Format composé uniquement de chiffres, par ex.: 7812234567",
      gtcAgree:
        "En soumettant une demande d’inscription à un événement, j’accepte la <Disclaimer>clause de non-responsabilité</Disclaimer>.",
      gtcAgreeDescCh:
        "En soumettant une demande d’inscription à un événement, j’accepte la clause de non-responsabilité. <B><S>Cornèr Banque SA est une banque entièrement agréée en Suisse, qui n’est pas autorisée par les régulateurs d’autres pays;</S><S>Le formulaire d’inscription ne constitue pas une sollicitation active appelant des résidents de pays étrangers à recourir à des services bancaires et/ou financiers;</S><S>J’accepte que Cornèr Banque SA me contacte par téléphone ou par e-mail afin que je reçoive des informations supplémentaires concernant les plateformes de trading, leurs fonctionnalités et les produits et services liés au bon fonctionnement de ces plateformes;</S><S>Pour les inscriptions aux webinaires, veuillez consulter l’intégralité de la clause de non-responsabilité <Here>ici</Here>.</S></B>",
      gtcAgreeDescCom:
        "En soumettant une demande d’inscription à un événement, j’accepte la clause de non-responsabilité. <B><S>Cornèr Banque SA est une banque entièrement agréée en Suisse, qui n’est pas autorisée par les régulateurs d’autres pays;</S><S>Le formulaire d’inscription ne constitue pas une sollicitation active appelant des résidents de pays étrangers à recourir à des services bancaires et/ou financiers;</S><S>J’accepte que Cornèr Banque SA me contacte par téléphone ou par e-mail afin que je reçoive des informations supplémentaires concernant les plateformes de trading, leurs fonctionnalités et les produits et services liés au bon fonctionnement de ces plateformes;</S><S>Pour les inscriptions aux webinaires, veuillez consulter l’intégralité de la clause de non-responsabilité <Here>ici</Here>.</S></B>",
      submit: "Inscrivez-vous dès maintenant",
      seats: {
        No: "Pas de sièges",
        Few: "Il reste peu de places!",
        Available: "Places libres",
      },
    },
    demo: {
      pageTitle: "Inscrivez-vous à une démo gratuite",
      firstName: "Prénom",
      surName: "Nom",
      email: "Adresse e-mail",
      country: "Pays de résidence",
      canton: "Canton",
      phoneNr: "Numéro de téléphone",
      emailRequired: "Veuillez saisir une adresse e-mail valide",
      phoneRequired:
        "Format composé uniquement de chiffres, par ex.: 7812234567",
      gtcAgree:
        "En m'inscrivant à un compte démo gratuit, j'accepte la <Disclaimer>clause de non-responsabilité</Disclaimer>.",
      gtcAgreeDescCh:
        "En soumettant ma demande pour un compte démo, je confirme être informé et accepter ce qui suit: <B><S>Cornèr Banque SA est une banque entièrement enregistrée en Suisse et réglementée uniquement dans ce pays;</S><S>Le formulaire de demande de démo ne constitue pas une sollicitation active appelant des résidents de pays étrangers à recourir à des services bancaires et/ou financiers;</S><S>Je confirme ainsi accéder à ce compte démo de ma propre initiative;</S><S>J’accepte que Cornèr Banque SA me contacte par téléphone ou par e-mail afin que je reçoive des informations supplémentaires concernant les plates-formes de trading, leurs fonctionnalités et les produits et services liés au bon fonctionnement de ces plateformes.</S></B>",
      gtcAgreeDescCom:
        "En soumettant ma demande pour un compte démo, je confirme être informé et accepter ce qui suit: <B><S>Cornèr Banque SA est une banque entièrement enregistrée en Suisse et réglementée uniquement dans ce pays;</S><S>Le formulaire de demande de démo ne constitue pas une sollicitation active appelant des résidents de pays étrangers à recourir à des services bancaires et/ou financiers;</S><S>Je confirme ainsi accéder à ce compte démo de ma propre initiative;</S><S>J’accepte que Cornèr Banque SA me contacte par téléphone ou par e-mail afin que je reçoive des informations supplémentaires concernant les plates-formes de trading, leurs fonctionnalités et les produits et services liés au bon fonctionnement de ces plateformes.</S></B>",
      submit: "Inscrivez-vous",
    },
    contactB2B: {
      formTitle: "Contactez-nous",
      formSubTitle:
        "Nos Relationship Managers se feront un plaisir de répondre à vos questions.",
      firstName: "Prénom",
      lastName: "Nom",
      company: "Entreprise",
      position: "Rôle/Fonction",
      email: "Adresse e-mail",
      country: "Pays de résidence",
      canton: "Canton",
      phoneNr: "Numéro de téléphone",
      emailRequired: "Veuillez saisir une adresse e-mail valide",
      phoneRequired:
        "Format composé uniquement de chiffres, par ex.: 7812234567",
      notes: "Message",
      disclaimerCh:
        "En soumettant ma demande de prise de contact – sans engagement – à un représentant de Cornèr Banque SA, j’accepte ce qui suit: <B><S>Cornèr Banque SA est une banque entièrement agréée en Suisse, qui n’est pas autorisée par les régulateurs d’autres pays;</S><S>Le formulaire de contact ne constitue pas une sollicitation active appelant des résidents de pays étrangers à recourir à des services bancaires et/ou financiers;</S><S>J’accepte que Cornèr Banque SA me contacte par téléphone ou par e-mail afin que je reçoive des informations supplémentaires concernant les plates-formes d’investissement, leurs fonctionnalités et les produits et services liés au bon fonctionnement de ces plateformes.</S></B>",
      disclaimerCom:
        "En soumettant ma demande de prise de contact – sans engagement – à un représentant de Cornèr Banque SA, j’accepte ce qui suit: <B><S>Cornèr Banque SA est une banque entièrement agréée en Suisse, qui n’est pas autorisée par les régulateurs d’autres pays;</S><S>Le formulaire de contact ne constitue pas une sollicitation active appelant des résidents de pays étrangers à recourir à des services bancaires et/ou financiers;</S><S>J’accepte que Cornèr Banque SA me contacte par téléphone ou par e-mail afin que je reçoive des informations supplémentaires concernant les plates-formes d’investissement, leurs fonctionnalités et les produits et services liés au bon fonctionnement de ces plateformes.</S></B>",
      submit: "Envoyer",
    },
    contactB2C: {
      formTitle: "Contactez-nous",
      formSubTitle:
        "Nos Relationship Managers se feront un plaisir de répondre à vos questions.",
      alreadyAccount: "Je possède déjà un compte",
      newAccount: "Je n'ai pas encore de compte",
      firstName: "Prénom",
      lastName: "Nom",
      email: "Adresse e-mail",
      country: "Pays de résidence",
      canton: "Canton",
      phoneNr: "Numéro de téléphone",
      emailRequired: "Veuillez saisir une adresse e-mail valide",
      phoneRequired:
        "Format composé uniquement de chiffres, par ex.: 7812234567",
      notes: "Message",
      topic: "Choisissez un thème",
      disclaimerCh:
        "En soumettant ma demande de prise de contact – sans engagement – à un représentant de Cornèr Banque SA, j’accepte ce qui suit: <B><S>Cornèr Banque SA est une banque entièrement enregistrée en Suisse et réglementée uniquement dans ce pays;</S><S>Le formulaire de contact ne constitue pas une sollicitation active appelant des résidents de pays étrangers à recourir à des services bancaires et/ou financiers;</S><S>J’accepte que Cornèr Banque SA me contacte par téléphone ou par e-mail afin que je reçoive des informations supplémentaires concernant les plateformes de trading, leurs fonctionnalités et les produits et services liés au bon fonctionnement de ces plateformes.</S></B>",
      disclaimerCom:
        "En soumettant ma demande de prise de contact – sans engagement – à un représentant de Cornèr Banque SA, j’accepte ce qui suit: <B><S>Cornèr Banque SA est une banque entièrement enregistrée en Suisse et réglementée uniquement dans ce pays;</S><S>Le formulaire de contact ne constitue pas une sollicitation active appelant des résidents de pays étrangers à recourir à des services bancaires et/ou financiers;</S><S>J’accepte que Cornèr Banque SA me contacte par téléphone ou par e-mail afin que je reçoive des informations supplémentaires concernant les plateformes de trading, leurs fonctionnalités et les produits et services liés au bon fonctionnement de ces plateformes.</S></B>",
      submit: "Envoyer",
      message: "Message",
    },
    meeting: {
      formTitle: "Réservez votre entretien en <B>face-à-face</B>",
      formSubTitle:
        "Nos chargés de clientèle se feront un plaisir de vous <B>aider</B> sur place comme en ligne.",
      phone: "Par téléphone",
      face: "En face-à-face",
      location: "Lieu",
      date: "Date",
      errorDate: "Error Date",
      time: "Choisissez un créneau horaire",
      allTime: "Tous les créneaux horaires",
      firstName: "Prénom",
      lastName: "Nom",
      email: "Adresse e-mail",
      country: "Pays de résidence",
      canton: "Canton",
      phoneNr: "Numéro de téléphone",
      emailRequired: "Veuillez saisir une adresse e-mail valide",
      phoneRequired:
        "Format composé uniquement de chiffres, par ex.: 7812234567",
      noteLanguage:
        "Leave us a message if you’d like to request another language.",
      notes:
        "Vous avez des questions ou des suggestions? Laissez-nous un message.",
      gtcAgree:
        "En demandant à être contacté, j’accepte explicitement la <Disclaimer>clause de non-responsabilité</Disclaimer>.",
      gtcAgreeDescCh:
        "En soumettant ma demande de prise de contact – sans engagement – par un représentant de Cornèr Banque SA, j’accepte ce qui suit: <B><S>Cornèr Banque SA est une banque entièrement agréée en Suisse qui n’est pas autorisée par les régulateurs d’autres pays;</S><S>Le formulaire de contact ne constitue pas une sollicitation active appelant des résidents de pays étrangers à recourir à des services bancaires et/ou financiers;</S><S>J’accepte que Cornèr Banque SA me contacte par téléphone ou par e-mail afin que je reçoive des informations supplémentaires concernant les plateformes de trading, leurs fonctionnalités et les produits et services liés au bon fonctionnement de ces plateformes.</S></B>",
      gtcAgreeDescCom:
        "En soumettant ma demande de prise de contact – sans engagement – par un représentant de Cornèr Banque SA, j’accepte ce qui suit: <B><S>Cornèr Banque SA est une banque entièrement agréée en Suisse qui n’est pas autorisée par les régulateurs d’autres pays;</S><S>Le formulaire de contact ne constitue pas une sollicitation active appelant des résidents de pays étrangers à recourir à des services bancaires et/ou financiers;</S><S>J’accepte que Cornèr Banque SA me contacte par téléphone ou par e-mail afin que je reçoive des informations supplémentaires concernant les plateformes de trading, leurs fonctionnalités et les produits et services liés au bon fonctionnement de ces plateformes.</S></B>",
      submit: "Réservez un entretien",
      preferences: "Paramétrer vos préférences",
      contact: "Vos coordonnées",
      language: "Langue",
      message: "Message",
      messageSubmit: "Veuillez remplir tous les champs pour continuer",
    },
    signals: {
      pageTitle: "Actualités quotidiennes du marché",
      firstName: "Prénom",
      surName: "Nom",
      email: "Adresse e-mail",
      country: "Pays de résidence",
      canton: "Canton",
      phoneNr: "Numéro de téléphone",
      emailRequired: "Veuillez saisir une adresse e-mail valide",
      phoneRequired:
        "Format composé uniquement de chiffres, par ex.: 7812234567",
      language: "Langue préférée",
      gtcAgree:
        "En m’abonnant à la newsletter, j’accepte la <Disclaimer>clause de non-responsabilité</Disclaimer>.",
      gtcAgreeDescCh:
        "En vous inscrivant à notre service d’études de marché et de signaux de trading, vous acceptez explicitement de recevoir des e-mails de la part de Cornèrtrader. Ces e-mails ne contiennent aucun conseil fiscal, financier ou d’investissement. Ils sont uniquement destinés à fournir des informations générales et ne doivent pas être considérés comme une forme de prestation de conseil, de sollicitation ou d’incitation à poursuivre des investissements de quelque nature que ce soit. En conséquence, Cornèrtrader ne pourra être tenu responsable des pertes que vous pourriez subir à la suite de décisions d’investissement prises en lien avec les informations disponibles dans ces e-mails. Cliquez <Click>ici</Click> pour consulter la clause de non-responsabilité complète.",
      gtcAgreeDescCom:
        "En vous inscrivant à notre service d’études de marché et de signaux de trading, vous acceptez explicitement de recevoir des e-mails de la part de Cornèrtrader. Ces e-mails ne contiennent aucun conseil fiscal, financier ou d’investissement. Ils sont uniquement destinés à fournir des informations générales et ne doivent pas être considérés comme une forme de prestation de conseil, de sollicitation ou d’incitation à poursuivre des investissements de quelque nature que ce soit. En conséquence, Cornèrtrader ne pourra être tenu responsable des pertes que vous pourriez subir à la suite de décisions d’investissement prises en lien avec les informations disponibles dans ces e-mails. Cliquez <Click>ici</Click> pour consulter la clause de non-responsabilité complète.",
      submit: "Abonnez-vous dès maintenant",
    },
  },
  success: {
    seminar: {
      pageTitle: "Découvrez la démo gratuite de notre plateforme",
      pageSubtitle:
        "Votre inscription a bien été prise en compte! <br>Nous allons vous envoyer toutes les informations détaillées par e-mail.",
      tryButton: "Lancez la démo",
    },
    demo: {
      pageTitleFormSubmitted: "Merci de votre inscription!",
      pageTitleCountryNotAllowed: "Désolé",
      pageTitleCountryAlmostAllowed: "Désolé",
      pageSubtitleFormSubmitted:
        "Vous recevrez des informations quotidiennes sur les études de marché et les signaux d’investissement.",
      pageSubtitleCountryNotAllowed:
        "Nous ne pouvons pas fournir ce service dans votre pays de résidence pour le moment.",
      pageSubtitleCountryAlmostAllowed:
        "Nous ne pouvons pas fournir ce service de cette manière dans votre pays de résidence. <br><br>Nous vous contacterons dès que possible.",
      pageTitleCredential: "Merci de votre inscription!",
      pageSubtitleCredential:
        "Nous avons envoyé les données d'accès à votre démo gratuite à <Value>{{contactMail}}</Value>",
      credential: "Utilisez ces informations d'identification",
      userId: "Votre ID utilisateur: <Value>{{userId}}</Value>",
      password: "Votre mot de passe: <Value>{{password}}</Value>",
      closeButton: "Fermer",
    },
    contactB2B: {
      pageTitle: "À très bientôt!",
      pageSubtitle:
        "Merci de votre demande. Votre chargé de clientèle prendra contact avec vous très prochainement.",
      checkPlatform: "Faites un tour sur nos plateformes",
      platforms: "platforms",
    },
    contactB2C: {
      pageTitle: "À très bientôt!",
      pageSubtitle:
        "Merci de votre demande. Votre chargé de clientèle prendra contact avec vous très prochainement.",
      checkPlatform: "Faites un tour sur nos plateformes",
      platforms: "platforms",
    },
    meeting: {
      pageTitle: "À très bientôt!",
      pageSubtitle:
        "Merci de votre demande. Votre chargé de clientèle prendra contact avec vous très prochainement.",
      checkPlatform: "Faites un tour sur nos plateformes",
      platforms: "platforms",
    },
    signals: {
      pageTitleFormSubmitted: "Merci de votre inscription",
      pageTitleCountryNotAllowed: "Désolé",
      pageTitleCountryAlmostAllowed: "Désolé",
      pageSubtitleFormSubmitted:
        "Vous recevrez des informations quotidiennes sur les études de marché et les signaux d’investissement.",
      pageSubtitleCountryNotAllowed:
        "Nous ne pouvons pas fournir ce service dans votre pays de résidence pour le moment.",
      pageSubtitleCountryAlmostAllowed:
        "Nous ne pouvons pas fournir ce service de cette manière dans votre pays de résidence. <br/><br/>Nous vous contacterons dès que possible.",
      closeButton: "Fermer",
      ocmsButton: "Votre inscription a bien été prise en compte.",
    },
  },
  errors: {
    input: {
      invalidPattern: "Format incorrect",
      minLength: "Au moins {{min}} caractères nécessaires",
      maxLength: "Un maximum de {{max}} caractères est autorisé",
      minPhoneLength:
        "Le numéro de téléphone doit contenir au moins {{min}} chiffres.",
      required: "Champ obligatoire",
      mismatch: "{{fieldName}} ne correspond pas",
    },
    submit: "Malheureusement, il y a eu un problème technique.",
    contactFormSuggestion:
      "Nous sommes heureux de vous aider: veuillez nous contacter via le <contactLink><strong>formulaire en ligne</strong></contactLink>",
  },
  district: {
    AG: "Aargau",
    AI: "Appenzell Innerrhoden",
    AR: "Appenzell Ausserrhoden",
    BS: "Basel-Stadt",
    BL: "Basel-Landschaft",
    BE: "Bern",
    FR: "Fribourg",
    GE: "Geneva",
    GL: "Glarus",
    GR: "Graub\u00fcnden",
    JU: "Jura",
    LU: "Luzern",
    NE: "Neuch\u00e2tel",
    NW: "Nidwalden",
    OW: "Obwalden",
    SH: "Schaffhausen",
    SZ: "Schwyz",
    SO: "Solothurn",
    SG: "St. Gallen",
    TG: "Thurgau",
    TI: "Ticino",
    UR: "Uri",
    VS: "Valais",
    VD: "Vaud",
    ZG: "Zug",
    ZH: "Z\u00fcrich",
  },
  location: {
    online: "Tous les lieux",
    zurich: "Zurich",
    geneva: "Genève",
    lugano: "Lugano",
  },
  language: {
    en: "Anglais",
    de: "Allemand",
    it: "Italien",
    fr: "Français",
  },
  topic: {
    a: "Ordres ou positions",
    b: "Techniques ou de back office",
    c: "Ouverture du compte",
    d: "Conditions et frais",
    e: "Offre pédagogique",
    f: "Autres",
  },
  captchaDisclaimer:
    "Ce site est protégé par reCAPTCHA et les <0>règles de confidentialité</0><br/> ainsi que les <1>conditions d'utilisation</1> de Google s'appliquent.",
};
