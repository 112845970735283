import React, { useEffect, useMemo, useState } from "react";
import { Button, FieldGroup, FieldRow, FieldSet } from "react-corner-ds";
import { Trans, useTranslation } from "react-i18next";
import { WebAccount } from "../../../models/response/response.model";
import style from "./CtWidgetDemoSuccess.module.css";

interface ICtWidgetDemoSuccess {
  webAccount?: WebAccount;
  contactMail?: string;
  stepForm?: string;
}

const CtWidgetDemoSuccess: React.FC<ICtWidgetDemoSuccess> = ({
  webAccount,
  contactMail,
  stepForm,
}) => {
  const { t } = useTranslation();
  const [isCredential, setIsCredential] = useState<boolean>(false);

  useEffect(() => {
    setIsCredential(
      webAccount &&
        webAccount?.ExtUserID !== "" &&
        webAccount?.PasswordClear !== ""
        ? true
        : false
    );
  }, [webAccount]);

  const scrollDataWidget = document.querySelector(
    '[data-widget="ct-widget-demo"]'
  )?.scrollTop;

  window.scrollTo({ top: scrollDataWidget, behavior: "smooth" });

  useEffect(() => {
    window.buildSuccessFormPageName("demo");
  }, []);

  const showTitle = useMemo(() => {
    switch (stepForm) {
      case "CountryNotAllowed":
        return <h2>{t`success.demo.pageTitleCountryNotAllowed`}</h2>;

      case "CountryAlmostAllowed":
        return <h2>{t`success.demo.pageTitleCountryAlmostAllowed`}</h2>;

      default:
        return isCredential ? (
          <h2>{t`success.demo.pageTitleCredential`}</h2>
        ) : (
          <h2>{t`success.demo.pageTitleFormSubmitted`}</h2>
        );
    }
  }, [isCredential, stepForm, t]);

  const showSubtitle = useMemo(() => {
    switch (stepForm) {
      case "CountryNotAllowed":
        return <h4>{t`success.demo.pageSubtitleCountryNotAllowed`}</h4>;

      case "CountryAlmostAllowed":
        return (
          <h4>
            <Trans i18nKey={t`success.demo.pageSubtitleCountryAlmostAllowed`} />
          </h4>
        );

      default:
        return isCredential ? (
          <h4>
            <Trans
              i18nKey={t`success.demo.pageSubtitleCredential`}
              components={{
                Value: (
                  <span className={style.demoSuccessValue}>{contactMail}</span>
                ),
              }}
              values={{ contactMail: contactMail }}
            />
          </h4>
        ) : (
          <h4>{t`success.demo.pageSubtitleFormSubmitted`}</h4>
        );
    }
  }, [contactMail, isCredential, stepForm, t]);

  const widgetWithCredential = useMemo(() => {
    return (
      <div className={style.demoSuccess}>
        <FieldSet>
          <FieldRow>
            <FieldGroup customCssClass={style.demoSuccessTitle}>
              {stepForm === "FormSubmitted" ? (
                <h2>
                  <i className="cticonapp-ok-2"></i>
                </h2>
              ) : (
                <></>
              )}
              {showTitle}
            </FieldGroup>
          </FieldRow>
          <FieldRow>
            <FieldGroup customCssClass={style.demoSuccessSubTitle}>
              {showSubtitle}
            </FieldGroup>
          </FieldRow>
          <FieldRow>
            <FieldGroup>
              <h4>{t`success.demo.credential`}</h4>
            </FieldGroup>
          </FieldRow>

          <FieldRow>
            <FieldGroup>
              <h4>
                <Trans
                  i18nKey={t`success.demo.userId`}
                  components={{
                    Value: (
                      <span className={style.demoSuccessValue}>
                        {webAccount?.ExtUserID}
                      </span>
                    ),
                  }}
                  values={{ userId: webAccount?.ExtUserID }}
                />
              </h4>
            </FieldGroup>
          </FieldRow>

          <FieldRow>
            <FieldGroup>
              <h4>
                <Trans
                  i18nKey={t`success.demo.password`}
                  components={{
                    Value: (
                      <span className={style.demoSuccessValue}>
                        {webAccount?.PasswordClear}
                      </span>
                    ),
                  }}
                  values={{ password: webAccount?.PasswordClear }}
                />
              </h4>
            </FieldGroup>
          </FieldRow>
          <FieldRow>
            <FieldGroup customCssClass={style.customButton}>
              <Button
                type="button"
                customCssClass={`${style.closeButton} close-modal`}
                text={t`success.demo.closeButton`}
              />
            </FieldGroup>
          </FieldRow>
        </FieldSet>
      </div>
    );
  }, [
    showSubtitle,
    showTitle,
    stepForm,
    t,
    webAccount?.ExtUserID,
    webAccount?.PasswordClear,
  ]);

  const widgetWithoutCredential = useMemo(() => {
    return (
      <div className={style.demoSuccess}>
        <FieldSet>
          <FieldRow>
            <FieldGroup customCssClass={style.demoSuccessTitle}>
              {stepForm === "FormSubmitted" ? (
                <h2>
                  <i className="cticonapp-ok-2"></i>
                </h2>
              ) : (
                <></>
              )}
              {showTitle}
            </FieldGroup>
          </FieldRow>
          <FieldRow>
            <FieldGroup customCssClass={style.demoSuccessSubTitle}>
              {showSubtitle}
            </FieldGroup>
          </FieldRow>
          <FieldRow>
            <FieldGroup customCssClass={style.customButton}>
              <Button
                type="button"
                customCssClass={`${style.closeButton} close-modal`}
                text={t`success.demo.closeButton`}
              />
            </FieldGroup>
          </FieldRow>
        </FieldSet>
      </div>
    );
  }, [showSubtitle, showTitle, stepForm, t]);

  return isCredential ? widgetWithCredential : widgetWithoutCredential;
};

export default CtWidgetDemoSuccess;
