// REGEX

export const NAME_REGEX = /^[a-zA-Z\u00C0-\u00FF\s+\u0027()-]*$/;

/** A regex to validate a string with only:
 * - Letters (upper and lowecase)
 * - Digits
 * - Special character. Accepted are: - _ ! . , ?
 * - Max 30 characters
 */
export const CT_USERNAME_REGEX = /^[a-zA-Z0-9-_!.,?]{2,30}$/;

export const definePatternByName = (pattern: string) => {
  const MESSAGE_CHARACTERS =
    /^[a-zA-Z\u00C0-\u00FF0-9\s\+\u0027_/=+@:.?&'!;,$-.%()*]*$/; //Include only
  const DANGEROUS_CHARACTERS = /^[^<>%~'"_]*$/; //Exclude only
  const SPECIAL_CHARACTERS = /^[a-zA-Z\u00C0-\u00FF0-9\s\+\u0027()\-]*$/; //Include only
  const SPECIAL_CHARACTERS_FOR_CITY =
    /^[a-zA-Z\u00C0-\u00FF0-9\s\+\u0027()\-.]*$/; //Include only
  const SPECIAL_CHARACTERS_FOR_STREET =
    /^[a-zA-Z\u00C0-\u00FF0-9\s\+\u0027()\-/'.,?]*$/; //Include only
  const SPECIAL_CHARACTERS_FOR_NAME = /^[a-zA-Z\u00C0-\u00FF\s\+\u0027()-]*$/; //Include only
  const SPECIAL_CHARACTERS_FOR_DATE =
    /^\s*(3[01]|[12][0-9]|0?[1-9])\/(1[012]|0?[1-9])\/((?:19|20)\d{2})\s*$/; //Include only
  const DIGIT_REGEX = /^[0-9]+$/;
  const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\-_!.,?])[\w\d\-_!.,?]{6,30}$/;
  const A_LOWERCASE_REGEX = /[a-z]/;
  const AN_UPPERCASE_REGEX = /[A-Z]/;
  const A_SPECIAL_CHAR_REGEX = /[-_!.,?]/;
  const DEFAULT_REGEX = /^[^^<>%$!:;`~*_?+{}\/§|\\"\]£#[]*$/;
  const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,16}))$/;
  const PHONE_REGEX = /^[0-9]{7,11}$/;
  const A_DIGIT_REGEX = /\d/;

  switch (pattern) {
    //Street
    case "Street":
      return SPECIAL_CHARACTERS_FOR_STREET;
    //ZipCode, AnnualIncome, AnnualHouseCost
    case "ZipCode":
    case "AnnualHouseCost":
      return DIGIT_REGEX;
    // City, StreetNumber
    case "City":
    case "StreetNumber":
      return SPECIAL_CHARACTERS_FOR_CITY;
    // FirstName, LastName
    case "FirstName":
    case "LastName":
      return SPECIAL_CHARACTERS_FOR_NAME;
    // Date
    case "DateOfBirth":
      return SPECIAL_CHARACTERS_FOR_DATE;
    // Default
    case "Text":
      return DEFAULT_REGEX;
    case "Email":
      return EMAIL_REGEX;
    case "Phone":
      return PHONE_REGEX;
    case "Message":
      return MESSAGE_CHARACTERS;
    // OTP
    case "Otp":
      return A_DIGIT_REGEX;
    default:
      return DEFAULT_REGEX;
  }
};

// ERROR PAGES
export const GENERIC_ERROR_PAGE = "/GenericError";

// ERROR MESSAGES
export const ERRORS = [
  { code: -1, msg: "Generic Error", navigate: GENERIC_ERROR_PAGE },
  { code: -2, msg: "Captcha score too low", navigate: GENERIC_ERROR_PAGE },
  { code: -3, msg: "Unknown step", navigate: GENERIC_ERROR_PAGE },
  { code: -4, msg: "Session Expired", navigate: GENERIC_ERROR_PAGE },
  { code: -5, msg: "Pega case expired", navigate: GENERIC_ERROR_PAGE },
  { code: -6, msg: "Java.net exception", navigate: GENERIC_ERROR_PAGE },
  { code: -7, msg: "Field validation error", navigate: GENERIC_ERROR_PAGE },
  { code: -8, msg: "Parameter missing", navigate: GENERIC_ERROR_PAGE },
  {
    code: -9,
    msg: "Dead End - Pega case is closed",
    navigate: GENERIC_ERROR_PAGE,
  },
  {
    code: -10,
    msg: "Unable to handle pega response. Maybe an unknown field has been returned",
    navigate: GENERIC_ERROR_PAGE,
  },
  {
    code: -11,
    msg: "Error initializating jar",
    navigate: GENERIC_ERROR_PAGE,
  },
  { code: -12, msg: "Field not recognized", navigate: GENERIC_ERROR_PAGE },
  {
    code: -13,
    msg: "Something wrong with resume token",
    navigate: GENERIC_ERROR_PAGE,
  },
  {
    code: -16,
    msg: "Unable to decode encoded parameter",
    navigate: GENERIC_ERROR_PAGE,
  },
  { code: 408, msg: "OTP - Expired", navigate: null },
  { code: 417, msg: "OTP - Invalid", navigate: null },
  { code: 429, msg: "OTP - Too many attempts", navigate: GENERIC_ERROR_PAGE },
];
