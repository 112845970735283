.seminarSuccess {
  --primary-color: #154dd1;
  --primary-border-color: #154dd1;
  --primary-text-color: #fff;
  --primary-hover-color: #193f99;
  --primary-hover-border-color: #193f99;
  --primary-hover-text-color: #fff;

  --secondary-color: #39d09e;
  --secondary-border-color: #39d09e;
  --secondary-text-color: #fff;
  --secondary-hover-color: #009966;
  --secondary-hover-border-color: #009966;
  --secondary-hover-text-color: #fff;

  font-size: 16px;
  background-color: var(--primary-text-color);
  border-radius: 8px;
  opacity: 1;
  transition: all 0.2s linear;
}

.action {
  flex: 1 1 50%;
  position: relative;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  font-family: 'Lexend', sans-serif;
  color: var(--form-text-color);
}

.seminarSuccessInfo {
  padding: 40px 32px 40px 32px;
  text-align: center;
  margin: 0;
}

.seminarSuccessInfo h3 {
  color: var(--form-text-success-color);
  font-size: 18px;
  font-weight: 500;
  text-transform: none;
  margin: 0;
  line-height: 1.31;
}

.seminarSuccessInfo h3 i {
  font-size: 40px;
  display: block;
  margin-bottom: 16px;
}

.seminarSuccessTitle {
  font-size: 40px;
  margin: 40px 0 32px;
  font-weight: 500;
  line-height: 1.2;
}

.seminarSuccessImg {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

div.customFieldRow .seminarSuccessImg {
  height: 305px;
  overflow: hidden;
  margin: 0;
  flex: auto;
}

.seminarSuccessImg img {
  max-width: 100%;
  margin-top: -34px;
  margin-bottom: -64px;
}

div.customFieldRow .seminarSuccessImg img {
  margin-top: 0;
  margin-bottom: 0;
}

.seminarSuccess button {
  min-width: 150px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  padding: 6px 16px;
  font-family: 'Lexend', sans-serif;
}

div.customFieldRow {
  margin: 0 -6px;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 769px) {
  .seminarSuccessInfo {
    text-align: left;
    min-width: 480px;
  }

  .seminarSuccessTitle {
    text-align: left !important;
    margin-left: 0 !important;
  }
}

@media screen and (min-width: 1280px) {
  .action {
    flex: 1 0 600px;
  }

  .seminarSuccessInfo {
    padding: 40px 80px 40px 32px;
    text-align: left;
  }

  .seminarSuccessImg {
    padding: 0;
    align-items: center;
  }

  div.customFieldRow .seminarSuccessImg {
    height: auto;
    width: 120px;
    overflow: visible;
  }

  div.customFieldRow .seminarSuccessImg img {
    margin-top: -34px;
    margin-bottom: -64px;
  }

  div.customFieldRow {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}