import React, { useEffect, useMemo } from "react";
import { Button, FieldGroup, FieldRow, FieldSet } from "react-corner-ds";
import { useTranslation } from "react-i18next";
import style from "./CtWidgetSignalsSuccess.module.css";

interface ICtWidgetSignalsSuccess {
  stepForm?: string;
}

const CtWidgetSignalsSuccess: React.FC<ICtWidgetSignalsSuccess> = ({
  stepForm,
}) => {
  const { t } = useTranslation();

  const scrollDataWidget = document.querySelector(
    '[data-widget="ct-widget-signals"]'
  )?.scrollTop;

  window.scrollTo({ top: scrollDataWidget, behavior: "smooth" });

  useEffect(() => {
    window.buildSuccessFormPageName("tradingsignals");
  }, []);

  useEffect(() => {
    if (stepForm === "FormSubmitted") {
      //Update ocms button
      const ocmsSubscribeBtn = document
        .getElementById("signals")
        ?.getElementsByClassName("cta")[0];

      // Remove old btn
      ocmsSubscribeBtn?.getElementsByTagName("a")[0]?.remove();

      // Add new btn
      const newElementA = document.createElement("a");
      newElementA.setAttribute("href", "#");
      newElementA.setAttribute("class", "ct-btn white subscribed");
      newElementA.setAttribute("data-modal", "market_news_modal");
      newElementA.setAttribute("disabled", "disabled");

      const newElementI = document.createElement("i");
      newElementI.setAttribute("class", "cticon-ok");

      const newElementSpan = document.createElement("span");
      newElementSpan.textContent = t`success.signals.ocmsButton`;

      newElementA.appendChild(newElementI);
      newElementA.appendChild(newElementSpan);

      ocmsSubscribeBtn?.appendChild(newElementA);
    }
  }, [stepForm, t]);

  const showTitle = useMemo(() => {
    switch (stepForm) {
      case "CountryNotAllowed":
        return <h2>{t`success.signals.pageTitleCountryNotAllowed`}</h2>;

      case "CountryAlmostAllowed":
        return <h2>{t`success.signals.pageTitleCountryAlmostAllowed`}</h2>;

      default:
        return <h2>{t`success.signals.pageTitleFormSubmitted`}</h2>;
    }
  }, [stepForm, t]);

  const showSubtitle = useMemo(() => {
    switch (stepForm) {
      case "CountryNotAllowed":
        return <h4>{t`success.signals.pageSubtitleCountryNotAllowed`}</h4>;

      case "CountryAlmostAllowed":
        return <h4>{t`success.signals.pageSubtitleCountryAlmostAllowed`}</h4>;

      default:
        return <h4>{t`success.signals.pageSubtitleFormSubmitted`}</h4>;
    }
  }, [stepForm, t]);

  return (
    <div className={style.signalsSuccess}>
      <FieldSet>
        <FieldRow>
          <FieldGroup customCssClass={style.signalsSuccessTitle}>
            {stepForm === "FormSubmitted" ? (
              <h2>
                <i className="cticonapp-ok-2"></i>
              </h2>
            ) : (
              <></>
            )}
            {showTitle}
          </FieldGroup>
        </FieldRow>
        <FieldRow>
          <FieldGroup customCssClass={style.signalsSuccessSubTitle}>
            {showSubtitle}
          </FieldGroup>
        </FieldRow>
        <FieldRow>
          <FieldGroup customCssClass={style.customButton}>
            <Button
              type="button"
              customCssClass={`${style.closeButton} close-modal`}
              text={t`success.signals.closeButton`}
            />
          </FieldGroup>
        </FieldRow>
      </FieldSet>
    </div>
  );
};

export default CtWidgetSignalsSuccess;
